import { Filters } from '@sede-x/glass-design-library';
import { useEffect, useState } from 'react';
import { PDSCONSTANT } from '../../common/constants';
import { CurveConfigurationFiltersName } from '../../common/enum';
import { ICurveConfigurationFilterProps } from '../../interfaces/ICurveConfigurationFilterProps';
import {IMasterData} from '../../interfaces/IMasterData';
import { UserPermission } from '../../services/UserPermission';

//Using @sede-x/glass-design-library Filter component for filter component
export const  CurveConfigurationFilter =(props:ICurveConfigurationFilterProps) => {

    //object containing user permissons
    const permissions = UserPermission();

  // Initialise the state variable
  const [granularity, setGranularity] = useState<IMasterData[]>([]);
  const [curveType, setCurveType] = useState<IMasterData[]>([]);
  const [commodity, setCommodity] = useState<IMasterData[]>([]);
  const [currency, setCurrency] = useState<IMasterData[]>([]);
  const [dataProvider, setDataProvider] = useState<IMasterData[]>([]);
  const [deliveryPoint, setDeilveryPoint] = useState<IMasterData[]>([]);
  const [location, setLocation] = useState<IMasterData[]>([]);
  const [priceType, setPriceType] = useState<IMasterData[]>([]);
  const [profile, setProfile] = useState<IMasterData[]>([]);
  const [access] = useState<IMasterData[]>(permissions.CanConfig_PrivatePriceCurve()?[{text:"Private",value:"Private"},{text:"Public",value:"Public"}]: [{text:"Public",value:"Public"}]);
  const [source, setSource] = useState<IMasterData[]>([]);
  const [unit, setUnit] = useState<IMasterData[]>([]);
  const dropdownPlaceHolder ='Please select';
  const dropdownType = 'MULTISELECTION';    
 
  // Represents the filter default values
  const filterDefaultValues: any = {};
  
  // Api calls for Filter dropdowns
  useEffect(() => {   
      
    if(props.masterData)
    {  
      setGranularity(props.masterData[PDSCONSTANT.CURVECONFIGURATION_GRANULARITY].map((str:string, _index:number) => ({ value: str, text: str})));
      setCurveType(props.masterData[PDSCONSTANT.CURVECONFIGURATION_CURVETYPE].map((str:string, _index:number) => ({ value: str, text: str})));
      setPriceType(props.masterData[PDSCONSTANT.CURVECONFIGURATION_PRICETYPE].map((str:string, _index:number) => ({ value: str, text: str})));
      setCurrency(props.masterData[PDSCONSTANT.CURVECONFIGURATION_CURRENCY].map((str:string, _index:number) => ({ value: str, text: str})));     
      setUnit(props.masterData[PDSCONSTANT.CURVECONFIGURATION_UNIT].map((str:string, _index:number) => ({ value: str, text: str}))); 
      setLocation(props.masterData[PDSCONSTANT.CURVECONFIGURATION_LOCATION].map((str:string, _index:number) => ({ value: str, text: str})));  
      setCommodity(props.masterData[PDSCONSTANT.CURVECONFIGURATION_COMMODITY].map((str:string, _index:number) => ({ value: str, text: str})));       
      setDeilveryPoint(props.masterData[PDSCONSTANT.CURVECONFIGURATION_DELIVERYPOINT].map((str:string, _index:number) => ({ value: str, text: str})));        
      setProfile(props.masterData[PDSCONSTANT.CURVECONFIGURATION_PROFILE].map((str:string, _index:number) => ({ value: str, text: str})));        
      setDataProvider(props.masterData[PDSCONSTANT.CURVECONFIGURATION_DATAPROVIDER].map((str:string, _index:number) => ({ value: str, text: str})));        
      setSource(props.masterData[PDSCONSTANT.CURVECONFIGURATION_SOURCE].map((str:string, _index:number) => ({ value: str, text: str})));   
             
    }  
   }
  ,[props.masterData]);    
  return (  
    <Filters collapsable 
    fields={
      [        
         {
            label: 'Name',
            name: 'name',
            placeholder: '',
            type: 'SEARCH'
          },
          {
            label: 'Granularity',
            name: 'granularity',
            options: granularity,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Curve Type',
            name: 'curvetype',
            options: curveType,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Price Type',
            name: 'pricetype',
            options: priceType,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Currency',
            name: 'currency',
            options: currency,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Unit',
            name: 'unit',
            options: unit,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Location',
            name: 'location',
            options: location,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Commodity',
            name: 'commodity',
            options: commodity,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Delivery Point',
            name: 'deliverypoint',
            options: deliveryPoint,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Profile',
            name: 'profile',
            options: profile,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Access',
            name: 'access',
            options: access,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },       
          {
            label: 'Data Provider',
            name: 'dataprovider',
            options: dataProvider,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Source',
            name: 'source',
            options: source,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Source System Curve ID',
            name: 'zemasource',
            placeholder: '',
            type: 'SEARCH'
          }          

    ]}
    defaultValues={filterDefaultValues}
    onApply={function onApply(values:any){      
         props.onApply(values);
         
    }} />   
  );
}
