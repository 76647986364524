import styled, { css } from 'styled-components';
import {Button, Drawer, Grid, Table, TextInput,Text } from '@sede-x/shell-ds-react-framework';
import {CurveViewerFilter} from "./CurveViewerFilters";


// Styling table for alternate row colours
const TableStyled = styled(Table)`
tr:nth-child(odd) td {
  background-color: rgba(0, 0, 0, 0);
}
tr:nth-child(even) td {
  background-color: rgba(0, 0, 0, 0.04);
}
.shell-table-thead .shell-table-cell {
  background-color: rgba(0, 0, 0, 0.04);
}
width: 100%;
border: 1px solid rgba(0, 0, 0, 0.06);
margin-top:25px; 
`;

// Set Grid height
const GridStyled = styled(Grid)`

height:50px ! important;
`;

// Set css for SideGrid
const SideGrid = styled(Grid)`

background-color:white;
padding: 20px 20px 20px 20px;
`;

// Set css for SideGrid
const MainGrid = styled(Grid)`

height:100vh;
`;

// Set css for Button 
const ButtonStyled = styled(Button)`
float:right;
`;

// Set css for Text input
const TextInputStyled = styled(TextInput)`
width:200px;
`
 const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: calc(100% - 72px);
  flex-direction: row;
`;
 const Sidebar = styled.div`
  display: flex;
  height: 90%;
  background: ${(props) => props.theme.background.surface};
`;
 const CenteredContainer = styled.div`
  
  
`;
 const ContentContainer = styled.div`
  width:100%;
  padding: 24px;
  height: ${()=>window.innerHeight >= 1440?"95vh ! important":"92vh ! important"};
    background-color: ${({ theme }) => theme.background.base}; 

`;

const DrawerStyled = styled(Drawer)`
${({ open }) => open && `
       width:100%!important;
`}
`
// Styling for main container
const FilterContainer = styled.div`  
height: ${()=>window.innerHeight >= 1440?"95vh ! important":"92vh ! important"};
  background-color: ${({ theme }) => theme.background.base};  
`;


// styling for download button in drawer 
const DrawerButtonStyled = styled(Button)
`float:right;
 margin:10px;
 width:100px;
`;

// styling for Filter styling
const FilterStyled = styled(CurveViewerFilter)`
width: 100%;
border: 1px solid rgba(0, 0, 0, 0.06);
`;

// styling for Grid View of Curve Viewr SIde Drawer
const SideDrawerGrid = styled(Grid)`
width: 750px;
`;

// Styling for Text box 
const HeaderText = styled(Text)`
font-weight:600!important;
font-size: 14px!important;
line-height: 16px!important;
`
const FlagImgStyled = styled.img `
height:25px;
margin-right:20%;
margin-top:6%
`

export {DrawerButtonStyled,TableStyled,FilterContainer,FilterStyled, GridStyled,ButtonStyled, SideGrid,TextInputStyled,MainGrid,CenteredContainer,InnerContainer,Sidebar,ContentContainer,DrawerStyled,SideDrawerGrid,HeaderText,FlagImgStyled}



