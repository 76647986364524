import { IApplicationProperties } from '../interfaces/IApplicationProperties';
// Helper method for environment specific configuration
export const EnvironmentHelper = (): IApplicationProperties => {
    // Initializing application properties 
    const applicationProperties: IApplicationProperties = { API_URL: '', APIM_SUBSCRIPTION: '', Authority_URL: '', AUthorize_URL: '', ClientID_URL: '', JWKS_URL: '', OpendID_URL: '', Register_URL: '', Revoke_URL: '', Token_URL: '', UserInfo_URL: '' };
    // Assigning DEV application properties
    applicationProperties.API_URL = process.env.REACT_APP_API_URL;
    applicationProperties.APIM_SUBSCRIPTION = process.env.REACT_APP_HEADER_APIM_SUBSCRIPTION;
    applicationProperties.AUthorize_URL = process.env.REACT_APP_AUTHORIZE_URL;
    applicationProperties.Authority_URL = process.env.REACT_APP_AUTHORITY;
    applicationProperties.ClientID_URL = process.env.REACT_APP_CLIENTID;
    applicationProperties.Register_URL = process.env.REACT_APP_REGISTER_URL;
    applicationProperties.Revoke_URL = process.env.REACT_APP_REVOKE_URL;
    applicationProperties.Token_URL = process.env.REACT_APP_TOKEN_URL;
    applicationProperties.JWKS_URL = process.env.REACT_APP_JWKS_URL;
    applicationProperties.UserInfo_URL = process.env.REACT_APP_USERINFO_URL;
    applicationProperties.OpendID_URL = process.env.OPEN_ID_CONFIGURATION_URL;

    return applicationProperties;

}