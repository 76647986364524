import { ButtonLink, Checkbox, Grid, useSorter } from '@sede-x/shell-ds-react-framework';
import { Add, CopyFileSolid, Download, Search } from '@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components';
import { ColumnsType, RecordType } from '@sede-x/shell-ds-react-framework/build/cjs/components/Table/Table.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TABLE_CLASS } from '@sede-x/shell-ds-react-framework/build/cjs/utils/constants';
import * as Styled from './CurveConfiguration.style';
import DataTableWithScrollBar from '../../library/Table/TableWithScrollBar';
import DataTablePagination from '../../library/Pagination/Pagination';
import CurveConfigurationDrawer from './CurveConfigurationSideDrawer';
import { FilterContainer, FilterStyled } from './CurveConfiguration.style';
import { IProductCurvePayload } from '../../interfaces/IProductCurvePayload';
import { IPaginationFilter } from '../../interfaces/IPaginationFilter';
import { ISortCriteria } from '../../interfaces/ISortCriteria';
import { ProductCurveConfigurations } from '../../services/ProductCurveConfigurations';
import { IProductCurveConfigurationData } from '../../interfaces/IProductCurveConfigurationData';
import { PDSCONSTANT } from '../../common/constants';
import { SortDirection, BtnGrpEnableDisableStatus, BtnGrpPublicPrivateStatus } from '../../common/enum';
import { CurveConfigurationColumnsHelper } from './CurveConfigurationColumnsHelper';
import { MasterData } from '../../services/MasterData';
import { IProductCurveFilter } from '../../interfaces/IProductCurveFilter';
import Loader from '../../library/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IDownloadFilter } from '../../interfaces/IDownloadFilter';
import { DownloadHelper } from '../../common/DownloadHelper';
import { UserPermission } from '../../services/UserPermission';
import dayjs from 'dayjs';
import { ICurveConfigurationColumnPropsExtensions } from '../../interfaces/ICurveConfigurationColumnPropsExtension';
// React screen for Curve Viewer
export const CurveConfiguration = () => {

  //object containing user permissons
  const permissions = UserPermission();

  // React state variables  
  const defaultPageSize = PDSCONSTANT.DEFAULT_PAGE_SIZE;
  const [currentPage, setPage] = useState(1);
  const [curveConfigurationData, setCurveConfigurationData] = useState<IProductCurveConfigurationData[]>([]);
  const [curveConfiguration, setCurveConfiguration] = useState<IProductCurveConfigurationData>({ Commodity: '', CommodityDeliveryPoint: '', CommodityProfile: '', ContractOption: '', CurveName: '', CurveType: '', Granularity: '', PriceType: '', Location: '', UnitOfMeasure: '', InterfaceApplication: '', IsActive: false, IsPrivate: false, IsChecked: false, TZIdentifier: '', ExistingCurveName: '', Clone: false });
  const [isProcessing, setIsProcessing] = useState(false);
  const { GetProductCurvesConfiguration, DownloadSelectedProductCurveConfigurationData, GetProductCurveConfiguration } = ProductCurveConfigurations();
  const { GetProductCurvesConfigurationFilters } = MasterData();
  const [wildCardSearchText, setWildCardSearchText] = useState('');
  const productConfigFilter: IProductCurveFilter = { Name: null, CurveType: [], Granularity: [], PriceType: [], Profile: [], DeliveryPoint: [], Currency: [], Unit: [], Location: [], ContractOption: [], Commodity: [], DataProvider: [], Source: [], IsPrivate: false, IsActive: true, SourceCurveName: null, WildCardSearchText: null, EffectiveEndDate: null, EffectiveStartDate: null };
  const [filterCriteria, setFilterCriteria] = useState<IProductCurveFilter>({ Name: null, CurveType: [], Granularity: [], PriceType: [], Profile: [], DeliveryPoint: [], Currency: [], Unit: [], Location: [], ContractOption: [], Commodity: [], DataProvider: [], Source: [], IsPrivate: null, IsActive: true, SourceCurveName: null, WildCardSearchText: null, EffectiveEndDate: null, EffectiveStartDate: null });
  const [paginationFilter, setPaginationFilter] = useState<IPaginationFilter>({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
  const [sortCriteria, setSortCriteria] = useState<ISortCriteria[]>([]);
  const [existingCurveName, setExistingCurveName] = useState('');

  //used for maintain and send the selectAll Status to backend api
  const [headerCheckbox, setHeaderCheckbox] = useState(false);
  //use to maintain the header checkbox check/uncheck status at UI only, no role in backend selectAll Status
  const [headerCheckboxUI, setHeaderCheckboxUI] = useState(false);

  let [selectedData, setSelectedData] = useState<string[]>([]);
  let [unSelectedData, setUnSelectedData] = useState<string[]>([]);
  const [productCurveConfigurations, setproductCurveConfigurations] = useState<any>();

  const productCurvePayload: IProductCurvePayload = {
    WildCardSearchText: wildCardSearchText,
    FilterCriteria: filterCriteria,
    PaginationFilter: paginationFilter,
    SortCriteria: sortCriteria
  }

  //initialize to default
  const downloadFilter: IDownloadFilter = {
    ProductCurvesFilter: filterCriteria,
    IsAllSelected: headerCheckbox,
    SelectedCurveList: selectedData,
    UnSelectedCurveList: unSelectedData
  }

  useEffect(() => {
    // Set Page Size on the basis of  Window size 
    paginationFilter.PageSize = window.innerHeight ? parseInt((window.innerHeight / 70).toFixed()) : 10;
    // Fetch the Product Curve configuration data on load and on change of product curve configuration payload
    getProductCurveConfigurationData();
  }, [wildCardSearchText, filterCriteria, paginationFilter, sortCriteria]);

  //On load curve configuration filters on load
  useEffect(() => {
    getCurveConfigurations();
  }, []);
  // Api calls for Product Curve Configuration data
  const getProductCurveConfigurationData = () => {
    // Comment for Loader - To be built    
    setCurveConfigurationData([]);
    setIsProcessing(true);
    GetProductCurvesConfiguration(productCurvePayload).then(result => {

      //maintain the checked status of current page on pagination
      let currentPageData: any = result.data.Data;
      currentPageData.map((curve: any) => {

        //handle checkbox selection on pagination  
        if (headerCheckbox) {
          //handle unchecked list -> marked them all unchecked
          var uncheckMe = unSelectedData.some(el => el === curve.CurveName);
          curve.IsChecked = !uncheckMe && headerCheckbox;
        }
        else {
          //handle checked list - marked them all checked             
          var checkedMe = selectedData.some(el => el === curve.CurveName);
          curve.IsChecked = checkedMe;
        }

        return curve;
      });


      //exclude private curve based authorization 
      if (!permissions.CanConfig_PrivatePriceCurve()) {
        currentPageData = currentPageData.filter((curve: any) => curve.IsPrivate !== true);
      }

      //set the grid data
      setCurveConfigurationData(currentPageData);
      setIsProcessing(false);
      paginationFilter.TotalRecords = result.data.PaginationFilter.TotalRecords;
      paginationFilter.PageNumber = currentPage;
      paginationFilter.PageSize = result.data.PaginationFilte.PageSize;
    }).catch((err) => {
      setIsProcessing(false);
    });
  }
  // Method for curve configuration filters load
  const getCurveConfigurations = () => {
    GetProductCurvesConfigurationFilters().then(result => {
      setproductCurveConfigurations(result.data)
    });
  }

  // For creation of curve open Side drawer
  const createCurve = () => {
    setCurveConfiguration({ Commodity: '', CommodityDeliveryPoint: '', CommodityProfile: '', ContractOption: '', CurveName: '', CurveType: '', Granularity: '', PriceType: '', Location: '', UnitOfMeasure: '', InterfaceApplication: '', IsActive: true, IsPrivate: false, IsChecked: false, TZIdentifier: '', Clone: false });
    setOpen(true);
  }

  // Method for Download product Curve data
  const downloadSelectedCurveCOnfigurationData = () => {

    //show error message if no curve is selected to download
    if (!downloadFilter.IsAllSelected && downloadFilter.SelectedCurveList.length === 0) {
      toast.error("No curve selected to download!", { position: toast.POSITION.TOP_CENTER });
      return;
    }
    // API calls for download selected product curve data 
    DownloadSelectedProductCurveConfigurationData(downloadFilter).then(result => {
      //call to helper to download
      DownloadHelper().ExportToCSV(PDSCONSTANT.EXPORT_FILENAME_PRODUCTCURVECONFIG, result.data);
    });
  }

  // Call this method on click of Apply in filters
  const handleFilterCriteria = (filterValues: any) => {
    resetPageNumber();
    filterCriteria.Name = filterValues['name'];
    filterCriteria.CurveType = filterValues['curvetype'];
    filterCriteria.Granularity = filterValues['granularity'];
    filterCriteria.Commodity = filterValues['commodity'];
    filterCriteria.DataProvider = filterValues['dataprovider'];
    filterCriteria.PriceType = filterValues['pricetype'];
    filterCriteria.Currency = filterValues['currency'];
    filterCriteria.Unit = filterValues['unit'];
    filterCriteria.Source = filterValues['source'];
    filterCriteria.Profile = filterValues['profile'];
    filterCriteria.DeliveryPoint = filterValues['deliverypoint'];
    filterCriteria.Location = filterValues['location'];
    filterCriteria.SourceCurveName = filterValues['zemasource'];

    filterCriteria.IsPrivate = null; //set the default value
    if (filterValues['access'] !== null && filterValues['access'] !== undefined) {
      if (filterValues['access'].length === 1) {
        //only one - public or private is selected
        const selection = filterValues['access'][0];
        filterCriteria.IsPrivate = selection === 'Private' ? true : false;
      }
    }
    // Assign state variable value to new variable to update with new state    
    let curveConfigurationFilterCriteria: IProductCurveFilter = filterCriteria;

    setFilterCriteria(curveConfigurationFilterCriteria);


    //clear previous checked status on filter search
    resetCheckboxSelection();
    getProductCurveConfigurationData();
  }

  // On Page Change updated the Pagination filter
  const handlePaginationFilter = (paginationData: IPaginationFilter) => {
    setPaginationFilter(paginationData);
  }

  // For rendering check box all functionality 
  const handleCheckboxAll = () => {
    // In case of clicking on select/unselect all check box render the curve data again    
    const checkedCurveData = curveConfigurationData.map((item) => ({
      ...item,
      IsChecked: !headerCheckbox,
    }));
    // Setting the curve data again
    setCurveConfigurationData(checkedCurveData);
    // Populate the array with checked curve names
    const selectedCurveData = checkedCurveData.filter((obj) => {
      return obj.IsChecked === true;
    }).map(a => a.CurveName);

    //clear checkbox selection list as all is selected
    setSelectedData([]);
    setUnSelectedData([]);
    // Setting the header checkbox on click 
    setHeaderCheckbox(!headerCheckbox);
    setHeaderCheckboxUI(!headerCheckbox);
  };

  // handle child checkbox selection when header is checked
  const handleChildSelectionWhenHeaderIsChecked = (currentSelection: any) => {

    if (!currentSelection.IsChecked) {
      unSelectedData.push(currentSelection.CurveName);
      //also uncheck the global UI checkbox
      setHeaderCheckboxUI(false);
    }
    else {
      unSelectedData = unSelectedData.filter(function (item) {
        return item !== currentSelection.CurveName
      });
      //checked header check box checked, if no unchecked
      if (unSelectedData.length === 0) {
        setHeaderCheckboxUI(true);
      }
    }
    // Assign state variable value to new variable to update with new state    
    let unselectedCurveConfigurationData: string[] = unSelectedData;
    //clear the selected list
    setUnSelectedData(unselectedCurveConfigurationData);
    setSelectedData([]);
  };


  // For rendering checkbox in table  
  const renderCheckbox = (value: any, record: any, index: number) => {
    //Handle the changes on check or uncheck of a particular row checkbox
    const handleChange = () => {
      // Update the particular row checked/unchecked status 
      const selectCurveData = [
        ...curveConfigurationData.slice(0, index),
        {
          ...curveConfigurationData[index],
          IsChecked: !curveConfigurationData[index].IsChecked,
        },
        ...curveConfigurationData.slice(index + 1),
      ];
      // Set the updated curve status 
      setCurveConfigurationData(selectCurveData);

      var currentSelection = selectCurveData[index];

      //handle checkbox selection when header is checked/unchecked
      if (headerCheckbox) {
        //handle child checkbox selection when header checkbox is unchecked   
        //re-factored separate method to reduce congnative complexity - sonar issue fix
        handleChildSelectionWhenHeaderIsChecked(currentSelection);
      }
      else {
        //handle when header checkbox is unchecked

        if (currentSelection.IsChecked) {
          selectedData.push(currentSelection.CurveName);
        }
        else {
          selectedData = selectedData.filter(function (item) {
            return item !== currentSelection.CurveName
          });
          setHeaderCheckboxUI(false);
        }

        //if all item selected -> checked header checkbox
        if (selectedData.length === paginationFilter.TotalRecords && unSelectedData.length === 0) {
          setHeaderCheckboxUI(true);
        }
        // Assign state variable value to new variable to update with new state    
        let selectedCurveConfigurationData: string[] = selectedData;
        //clear the selected list
        setSelectedData(selectedCurveConfigurationData);
        setUnSelectedData([]);
      }

    };
    // Rendering the checkbox for row            
    return <Checkbox crossOrigin='false' label="" size='small' id={index.toString()} checked={value} onChange={handleChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />;
  };

  // reset checkbox selections
  const resetCheckboxSelection = () => {
    //clear all selection and headerCheck to false
    setSelectedData([]);
    setUnSelectedData([]);
    // Setting the header checkbox on click 
    setHeaderCheckbox(false);
  };

  // For rendering string value in table
  const renderValue = (value: any, record: any, index: number) => {
    return (<div>{value}</div>)
  };
  //Set the Page Number to 1 
  const resetPageNumber = () => {
    setPage(1);
    setPaginationFilter({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
  }

  // Filtering the disable curves
  const onDisabled = (value: any) => {
    resetPageNumber();
    filterCriteria.IsActive = !value;
    // Assign state variable value to new variable to update with new state    
    let curveConfigurationFilterCriteria: IProductCurveFilter = filterCriteria;
    setFilterCriteria(curveConfigurationFilterCriteria);
    getProductCurveConfigurationData();
  };
  // For rendering link in table
  const renderLinkValue = (value: any, record: any, index: number) => {
    return (<ButtonLink onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={() => {
      var productCurveData = curveConfigurationData[index];
      productConfigFilter.Name = productCurveData.CurveName;
      // Fetching Product Curve Config with the Source Curve name
      GetProductCurveConfiguration(productConfigFilter).then(result => {
        setExistingCurveName(productCurveData.CurveName);
        setCurveConfiguration(result.data);
        setOpen(true);
      });

    }} >{value}</ButtonLink>)
  };

  // For rendering date value in table
  const renderDateAndTimeValue = (value: any, record: any, index: number) => {
    value = dayjs(value).format(PDSCONSTANT.DATE_FORMAT) + " | " + dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
    return (<div>{value}</div>)
  };

  // For rendering link in table
  const renderStatus = (value: any, record: any, index: number) => {
    value = (value === true ? BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] : BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Disable]);
    return (<div>{value}</div>)
  };
  // For rendering date 
  const renderDate = (value: any, record: any, index: number) => {
    if (value !== "" && value !== undefined && value !== null) {

      value = dayjs(value).format(PDSCONSTANT.DATE_FORMAT);
    }
    return (<div>{value}</div>)
  };
  // For Right Overlay state variable for Drawer component open state
  const [open, setOpen] = useState(false);
  const onClose = (isLoading: boolean = false, message: string = "") => {
    if (isLoading) {
      toast.success(message, { position: toast.POSITION.TOP_CENTER });
      getProductCurveConfigurationData();
    }
    setOpen(false);
  };

  // for rendering clone button
  const renderClone = (value: any, record: any, index: number) => {
    return (permissions.CanWrite_CurveMasterData() && <ButtonLink onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={() => {
      var productCurveData = curveConfigurationData[index];
      productConfigFilter.Name = productCurveData.CurveName;
      setCurveConfiguration({
        Commodity: productCurveData.Commodity,
        CommodityDeliveryPoint: productCurveData.CommodityDeliveryPoint,
        CommodityProfile: productCurveData.CommodityProfile,
        ContractOption: productCurveData.ContractOption,
        CurveName: '',
        SourceCurveName: '',
        DataProvider: productCurveData.DataProvider,
        CurveType: productCurveData.CurveType,
        Granularity: productCurveData.Granularity,
        PriceType: productCurveData.PriceType,
        Location: productCurveData.Location,
        UnitOfMeasure: productCurveData.UnitOfMeasure,
        InterfaceApplication: productCurveData.InterfaceApplication,
        IsActive: productCurveData.IsActive,
        IsPrivate: productCurveData.IsPrivate,
        IsChecked: false,
        TZIdentifier: productCurveData.TZIdentifier,
        Currency: productCurveData.Currency,
        SecondaryCurrency: productCurveData.SecondaryCurrency,
        Clone: true
      });
      setOpen(true);

    }} >{<CopyFileSolid />}</ButtonLink>)
  }

  const renderMappingExists = (value: any, record: any, index: number) => {
    return (<div>{value === true ? "Yes" : "No"}</div>)
  }

  // Passing render methods as a props in CurveConfigurationColumnHelper
  const curveConfigurationColumnProps: ICurveConfigurationColumnPropsExtensions = { renderCheckbox: renderCheckbox, renderButtonLink: renderClone, renderLinkValue: renderLinkValue, renderValue: renderValue, handleCheckboxAll: handleCheckboxAll, headerCheckbox: headerCheckboxUI, renderStatus: renderStatus, renderDateAndTimeValue: renderDateAndTimeValue, renderDate: renderDate, renderMappingExists: renderMappingExists };
  const curveConfigurationColumns = CurveConfigurationColumnsHelper(curveConfigurationColumnProps);
  // For Sorting 
  const [transformSorterColumns, sortStates] = useSorter<RecordType>({
    prefixCls: TABLE_CLASS,
    columns: curveConfigurationColumns,
  });
  useMemo(() => {
    const SortCriteriaResult: ISortCriteria[] = [];
    for (var sortNumber in sortStates) {
      if (Object.hasOwn(sortStates, sortNumber)) {
        const SortCriterion: ISortCriteria = {
          ColumnName: '',
          Direction: ''
        };
        if (sortStates[sortNumber].sortOrder !== undefined) {
          SortCriterion.ColumnName = sortStates[sortNumber].column.dataIndex?.toString() ?? "";
          SortCriterion.Direction = sortStates[sortNumber].sortOrder === 'ascend' ? SortDirection[SortDirection.DESC] : SortDirection[SortDirection.ASC];
          SortCriteriaResult.push(SortCriterion);
        }
      }
    }
    setSortCriteria(SortCriteriaResult);
  }, [sortStates]);
  // For changing the header sort icons
  const transformColumns = useCallback(
    (innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> => transformSorterColumns(innerColumns),
    [transformSorterColumns],
  );
  // Passing the columns 
  const transformedColumns = transformColumns(curveConfigurationColumns);
  return (
    <>
      {isProcessing && <Loader />}
      <CurveConfigurationDrawer onClose={onClose} open={open} {...productCurveConfigurations} selectedCurve={curveConfiguration} existingCurveName={existingCurveName} />
      <Styled.InnerContainer>
        <Styled.Sidebar>
          <FilterContainer><FilterStyled onApply={handleFilterCriteria} masterData={productCurveConfigurations}></FilterStyled></FilterContainer>
        </Styled.Sidebar>
        <Styled.ContentContainer>
          <Styled.CenteredContainer>
            <Styled.SideGrid>
              <Styled.GridStyled columns={2}>
                <Grid.Cell>
                  <b>Curve Configuration</b>
                </Grid.Cell>
                <Grid.Cell>
                  <Styled.FlexboxStyled gap='24px;'>
                    {/* create will be allowed as per authorization */}
                    {permissions.CanWrite_CurveMasterData() && <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={createCurve} icon={<Add />}>Create Curve</Styled.ButtonStyled>}
                    <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={downloadSelectedCurveCOnfigurationData} variant='outlined' icon={<Download />}>Export Data</Styled.ButtonStyled>
                  </Styled.FlexboxStyled>
                </Grid.Cell>
              </Styled.GridStyled>
              <Grid columns={1}>
                <Grid.Cell>
                  <hr />
                </Grid.Cell>
              </Grid>
              <Styled.GridStyled columns={2} >
                <Grid.Cell >
                  <Styled.TextInputStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' size='small' icon={<Search height={24} />} placeholder="Search" onKeyUp={(e: any) => {
                    // Fire event on enter press
                    if (e.keyCode === 13) {
                      resetPageNumber();
                      setPage(1);
                      paginationFilter.PageNumber = 1;
                      // Setting the value enetered in search textbox
                      filterCriteria.WildCardSearchText = e.target.value;
                      // Assign state variable value to new variable to update with new state    
                      let curveConfigurationFilterCriteria: IProductCurveFilter = filterCriteria;
                      // Set the filtercriteria with the updated text value
                      setFilterCriteria(curveConfigurationFilterCriteria);
                      // Calling the GetProductCurveConfigurationData method for fetching
                      getProductCurveConfigurationData();
                    }
                  }} />
                </Grid.Cell>
                <Grid.Cell ><Styled.ToggleStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' label="Disabled Curves" onChange={onDisabled} labelPosition="left" size="medium" /></Grid.Cell>
              </Styled.GridStyled>
              <Grid rowGap="24px">
                <DataTableWithScrollBar data={curveConfigurationData} columns={transformedColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} />
                <DataTablePagination currentPage={paginationFilter.PageNumber} pageSize={paginationFilter.PageSize} total={paginationFilter.TotalRecords} setPage={setPage} onPageChange={handlePaginationFilter} />
              </Grid>
            </Styled.SideGrid>
          </Styled.CenteredContainer>
        </Styled.ContentContainer>
      </Styled.InnerContainer>
      <ToastContainer />
    </>);
}