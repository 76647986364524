import { Filters } from '@sede-x/glass-design-library';
import { useEffect, useState, useCallback } from 'react';
import { PDSCONSTANT } from '../../common/constants';
import { AligneExportFiltersName } from '../../common/enum';
import { ICurveConfigurationFilterProps } from '../../interfaces/ICurveConfigurationFilterProps';
import { IMasterData } from '../../interfaces/IMasterData';
import { UserPermission } from '../../services/UserPermission';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { dateFilterHelper } from '../../common/DateFilterHelper';
import { IPDSFilterProps } from '../../interfaces/IPDSFilterProps';
import { MasterData } from '../../services/MasterData';
dayjs.extend(weekday)
dayjs.extend(localeData)

//Using @sede-x/glass-design-library Filter component for filter component
export const ManualAligneExportFilters = (props: ICurveConfigurationFilterProps) => {
  // Initialise the state variable
  const { GetBooleanValueFilters } = MasterData();
  const [market, setMarket] = useState<IMasterData[]>([]);
  const [component, setComponent] = useState<IMasterData[]>([]);
  const [pset, setPSet] = useState<IMasterData[]>([]);
  const [psetSelected, setPSetSelected] = useState<string[]>([]);
  const [granularity, setGranularity] = useState<IMasterData[]>([]);
  const [curveType, setCurveType] = useState<IMasterData[]>([]);
  const [addToMarket, setAddToMarket] = useState<IMasterData[]>([]);
  const [priceCorrection, setPriceCorrection] = useState<IMasterData[]>([]);
  const [priceCorrectionSelected, setPriceCorrectionSelected] = useState<string>();
  const [exportSchedule, setExportSchedule] = useState<IMasterData[]>([]);
  const dropdownPlaceHolder = 'Please select';
  const dropdownType = 'MULTISELECTION';
  const singleDropdownType = 'SINGLE_SELECTION';

  // DateFilterHelper methods
  const { getDefaultEffectiveDate, onChangeDateRange, resetDateRange } = dateFilterHelper();

  // Setting default date
  let effectiveDate = getDefaultEffectiveDate();
  const [effectiveStartDateRange, setEffectiveStartDateRange] = useState(dayjs(effectiveDate.EffectiveStartDate));
  const [effectiveEndDateRange, setEffectiveEndDateRange] = useState(dayjs(effectiveDate.EffectiveEndDate));
  // On change of date in Effective Date range or on resetting the date on dateRange
  const onChangeDate = (dates: { $d: dayjs.Dayjs | Date | null; }[] | null) => {
    effectiveDate = onChangeDateRange(dates);
    setEffectiveStartDateRange(dayjs(effectiveDate.EffectiveStartDate));
    setEffectiveEndDateRange(dayjs(effectiveDate.EffectiveEndDate));
    filterValues['effectivedaterange'] = [dayjs(effectiveDate.EffectiveStartDate), dayjs(effectiveDate.EffectiveEndDate)];
  }

  // On change of pset dropdown value, assign it to the filterValues object
  const onPsetChange = (psetValue: string[]) => {

    setPSetSelected(psetValue);
    filterValues['pset'] = psetValue;
  }

  // If price correction is set to true, then set PSET to settlement and market.
  const OnChangeCorrection = (priceCorrection: any) => {
    if (priceCorrection == 'TRUE') {
      setPSetSelected(['SETTLEMENT', 'MARKET']);
      filterValues['priceCorrection'] = 'TRUE';
      setPriceCorrectionSelected('TRUE');
    }
    else {
      setPSetSelected([]);
      filterValues['priceCorrection'] = 'FALSE';
      setPriceCorrectionSelected('FALSE');
    }
    filterValues['pset'] = psetSelected;
  }
  // Reset Effective Date Range filter
  const resetDate = () => {
    // Setting the effective start date range and effective end date range to filter values
    effectiveDate = resetDateRange();
    setEffectiveStartDateRange(dayjs(effectiveDate.EffectiveStartDate));
    setEffectiveEndDateRange(dayjs(effectiveDate.EffectiveEndDate));
    filterValues['effectivedaterange'] = [dayjs(effectiveDate.EffectiveStartDate), dayjs(effectiveDate.EffectiveEndDate)];
  }
  // Represents the filter default values
  const filterValues: any = [];

  useEffect(() => {
    // Setting Effective Date Range default values
    filterValues['effectivedaterange'] = [dayjs(effectiveStartDateRange), dayjs(effectiveEndDateRange)];
  }, []);

  // Api calls for Filter dropdowns
  useEffect(() => {

    // Master dropdown data
    if (props.masterData) {
      // Market dropdown values        
      setMarket(props.masterData[PDSCONSTANT.ALIGNE_MARKET].map((str: string, _index: number) => ({ value: str, text: str })));
      // Component dropdown values  
      setComponent(props.masterData[PDSCONSTANT.ALIGNE_COMPONENT].map((str: string, _index: number) => ({ value: str, text: str })));
      // PSET dropdown values  
      setPSet(props.masterData[PDSCONSTANT.ALIGNE_PSET].map((str: string, _index: number) => ({ value: str, text: str })));
      // Granularity dropdown values          
      setGranularity(props.masterData[PDSCONSTANT.ALIGNE_GRANULARITY].map((str: string, _index: number) => ({ value: str, text: str })));
      // CurveType dropdown values  
      setCurveType(props.masterData[PDSCONSTANT.ALIGNE_CURVETYPE].map((str: string, _index: number) => ({ value: str, text: str })));
      // Export Type dropdown values  
      setExportSchedule(props.masterData[PDSCONSTANT.ALIGNE_EXPORTSCHEDULE].map((str: string, _index: number) => ({ value: str, text: str })));
    }


    //the add to market and Price Correction dropdown values are static and does not depend on the data from backend
    setAddToMarket(GetBooleanValueFilters());
    setPriceCorrection(GetBooleanValueFilters());
    // Find the Filter's reset button 
    const resetButton = document.querySelector(PDSCONSTANT.RESET_BTN_IDENTIFIER) as HTMLButtonElement;
    // Add click event on the reset button 
    resetButton.addEventListener('click', resetDate, false);
  }
    , [props.masterData]);

  // Callback function on click of Filter Apply button 
  const onApplyfunc = useCallback(
    (values: object) => onPDSApply(values), [effectiveStartDateRange, effectiveEndDateRange, psetSelected]
  )
  // Method to pass the filter criteria to Parentcomponent
  function onPDSApply(values: object) {
    if (Object.keys(values).length > 0) {
      // Cast the object to IPDSFilterProps
      const res = values as IPDSFilterProps;
      res.effectivedaterange[0] = effectiveStartDateRange;
      res.effectivedaterange[1] = effectiveEndDateRange;
      res.pset = psetSelected;
      res.priceCorrection = priceCorrectionSelected;
      props.onApply(res);
    }
    else {
      filterValues['effectivedaterange'] = [effectiveStartDateRange, effectiveEndDateRange];
      props.onApply(filterValues);
    }


  }
  return (
    <>
      <Filters collapsable
        fields={
          [
            {
              label: 'Name',
              name: 'name',
              placeholder: '',
              type: 'SEARCH'
            },
            {
              label: 'Granularity',
              name: 'granularity',
              options: granularity,
              placeholder: dropdownPlaceHolder,
              type: dropdownType
            },
            {
              label: 'Curve Type',
              name: 'curveType',
              options: curveType,
              placeholder: dropdownPlaceHolder,
              type: dropdownType
            },
            {
              label: 'Market',
              name: 'market',
              options: market,
              placeholder: dropdownPlaceHolder,
              type: dropdownType
            },
            {
              label: 'Component',
              name: 'component',
              options: component,
              placeholder: dropdownPlaceHolder,
              type: dropdownType
            },
            {
              label: 'PSET',
              name: 'pset',
              options: pset,
              placeholder: dropdownPlaceHolder,
              type: dropdownType,
              onChange: onPsetChange,
              value: psetSelected
            },
            {
              label: 'Settle',
              name: 'Settle',
              placeholder: '',
              type: 'SEARCH'
            },
            {
              label: 'Add To Market',
              name: 'addToMarket',
              options: addToMarket,
              placeholder: dropdownPlaceHolder,
              type: dropdownType
            },
            {
              label: 'Export Schedule',
              name: 'exportSchedule',
              options: exportSchedule,
              placeholder: dropdownPlaceHolder,
              type: dropdownType
            },
            {
              label: 'Effective Date Range',
              name: 'effectivedaterange',
              placeholder: [
                'Start',
                'End'
              ],
              type: 'DATE_RANGE',
              onChange: onChangeDate,
              value: [effectiveStartDateRange, effectiveEndDateRange]
            },
            {
              label: 'Price Correction',
              name: 'priceCorrection',
              options: priceCorrection,
              placeholder: dropdownPlaceHolder,
              type: singleDropdownType,
              onChange: OnChangeCorrection,
              value: priceCorrectionSelected
            }

          ]}
        defaultValues={filterValues}
        onApply={onApplyfunc} />
    </>
  );
}
