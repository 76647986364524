// Sort Direction enum
export enum SortDirection {
  ASC,
  DESC
}
// Curve Configuration Enum
export enum CurveConfigurationFiltersName {
  GRANULARITY,
  CURVETYPE,
  COMMODITY,
  CURRENCY,
  DATAPROVIDER,
  DELIVERYPOINT,
  LOCATION,
  PRICETYPE,
  PROFILE,
  SOURCE,
  UNIT
}
// Interface application Enum
export enum InterfaceApplicationEnum {
  ZEMA,
  PCB
}
// Respons code enum
export enum ResponseCodes {
  UnAuthorized = 401,
  Success = 200,
  BadRequest = 400,
  InternalServerError = 500,
  NotFound = 404
}


//enum for all supported users roles
export enum ROLES {
  MarketRiskOperator = "MarketRiskOperator",
  MarketRiskAdvisor = "MarketRiskAdvisor",
  ITSupport = "ITSupport",
  ReadOnly = "ReadOnly"
}

//enum for all supported users privileges
export enum Permission {

  //ONLY MarketRistOperator
  CurveMasterData_WRITE = "CurveMasterData_WRITE",
  AligneMapping_WRITE = "AligneMapping_WRITE",
  AligneExport_MANUAL = "AligneExport_MANUAL",
  PriceCurvePrivate_CONFIG = "PriceCurvePrivate_CONFIG",
  CalendarData_WRITE = "CalendarData_WRITE",

  //ALL BUT READONLY
  PriceCurvePrivate_READ = "PriceCurvePrivate_READ",
  ReferenceData_READ = "ReferenceData_READ",

  //ITSupport, MarketRistAdvisor
  ReferenceData_WRITE = "ReferenceData_WRITE",

  //ALL ALLOWED
  CurveMasterData_READ = "CurveMasterData_READ",
  AligneMapping_READ = "AligneMapping_READ",
  AligneExportReport_INTRADAY = "AligneExportReport_INTRADAY",
  AligneExportReport_EOD = "AligneExportReport_EOD",
  PriceCurve_READ = "PriceCurve_READ"
}


// Button Group button Enable/Disable text 
export enum BtnGrpEnableDisableStatus {
  Enable,
  Disable
}

// Button Group button Public/Private text
export enum BtnGrpPublicPrivateStatus {
  Public,
  Private
}

// Aligne Configuration Filter Enum
export enum AligneExportFiltersName {
  MARKET,
  COMPONENT,
  PSET,
  ZEROCAL,
  ALIGNEIMPORTTYPE,
  CONTRACTOPTIONS,
  SECONDARYMARKET,
  SECONDARYCOMPONENT,
  UNIT,
  EXPORTSCHEDULE,
  GRANULARITY,
  CURVETYPE,
  EXPORTSTATUS,
  EXPORTTYPE,
  ADDTOMARKET
}

// Interface Zero Cal
export enum ZeroCalEnum {
  TRUE,
  FALSE
}



/// <summary>
/// Represents the Aligne Export Status
/// </summary>
export enum ExportStatus {
  /// <summary>
  /// Export Process begins 
  /// </summary>
  INITIATED,
  /// <summary>
  /// Aligne Export Status Tracking ID Received 
  /// </summary>
  TRACKINGID_RECEIVED,
  /// <summary>
  /// Aligne Export Status Tracking Initiated 
  /// </summary>
  TRACKING_INITIATED,
  /// <summary>
  /// Aligne Export Success 
  /// </summary>
  ALIGNE_SUCCESS,
  /// <summary>
  /// Aligne Export Error
  /// </summary>
  ALIGNE_ERROR,
  /// <summary>
  /// Aligne Export Is In Progress 
  /// </summary>
  ALIGNE_PROCESSING,
  /// <summary>
  /// Aligne Export Status Tracking Failed
  /// </summary>
  TRACKING_FAILED,
  /// <summary>
  /// Aligne Export Failed
  /// </summary>
  EXPORT_FAILED,
  /// <summary>
  /// PDS API call to retrieve data for export successful
  /// </summary>
  PDS_API_EXECUTED,
  /// <summary>
  /// PDS API call to retrieve data for export failed
  /// </summary>
  PDS_API_FAILED,
  /// <summary>
  /// Curve data for export exists
  /// </summary>
  PDS_DATA_EXISTS,
  /// <summary>
  /// Curve data for export missing
  /// </summary>
  PDS_DATA_MISSING,
  /// <summary>
  /// Export Status for incomplete export curve data
  /// </summary>
  PDS_DATA_INCOMPLETE,
  /// <summary>
  /// SETA Payload created successfully
  /// </summary>
  PAYLOAD_CREATED,
  /// <summary>
  /// Aligne export initiated
  /// </summary>
  ALIGNE_EXPORT_INITIATED
}