import { Grid } from "@sede-x/shell-ds-react-framework";
import { useEffect, useState } from "react";
import { MasterData } from '../../../services/MasterData';
import { PDSCONSTANT } from "../../../common/constants";
import * as Styled from './CurveReference.style';
import dayjs from 'dayjs';
import { UserPermission } from "../../../services/UserPermission";
import { ICurveReferenceFilter } from "../../../interfaces/ICurveReferenceFilter";
import { ICurveReferenceAudit } from "../../../interfaces/ICurveReferenceAudit";
const dateFormat = PDSCONSTANT.DATE_FORMAT;
const timeFormat = PDSCONSTANT.TIME_FORMAT;
// For rendering date value in Curve Viewer Price Points table
// If Granularity is Higher granularity display time as well alongwith date else date part only
const renderDateValueAndTime = (value: any, record: any, index: number) => {
  // Rendering Date and Time
  value = dayjs(value).utcOffset(0).format(PDSCONSTANT.DATE_FORMAT) + " | " + dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
  return (<div>{value}</div>)
};

const renderDecimal = (value: any, record: any, index: number) => {
  if (value) {
    value = value.toString()
  }
  return (<div>{value}</div>)
}

const columns = [{
  dataIndex: 'UpdatedDate',
  title: 'Updated Date (UTC)',
  key: 'UpdatedDate',
  render: renderDateValueAndTime
},
{
  dataIndex: 'UpdatedBy',
  title: 'Updated By',
  key: 'UpdatedBy'
},
{
  dataIndex: 'Change',
  title: 'Change',
  key: 'Value'
}
];

export default function CurveReferenceAuditDrawer(drawerProps: any) {

  const permissions = UserPermission();
  let curvePriceColumns = columns;
  // on change of page number update current page     
  const areas = ['main main', 'subtext .', 'subtext .', 'subtext .', 'subtext .', 'subtext .', 'table table'];
  const maskStyle = { backgroundColor: "black" };
  const headerText = PDSCONSTANT.REFERENCE_ATTRIBUTE_AUDIT_DRAWER_HEADER_LABEL;
  const [referenceDataAttributeAuditData, setReferenceDataAttributeAuditData] = useState<ICurveReferenceAudit[]>([]);
  const { GetReferenceDataAttributeAuditHistory } = MasterData();
  const [isProcessing, setIsProcessing] = useState(false);



  const referenceDataAttributePayload: ICurveReferenceFilter = {
    Id: drawerProps.selectedItem._id,
    Name: drawerProps.selectedItem.Name,
    IsActive: null,
    WildCardSearchText: null
  }

  // Api calls for reference audit history
  const getReferenceDataAttributeAuditHistory = () => {
    setIsProcessing(true);
    setReferenceDataAttributeAuditData([]);
    if(referenceDataAttributePayload.Id!==undefined && referenceDataAttributePayload.Id!==null && referenceDataAttributePayload.Id!==""){
    GetReferenceDataAttributeAuditHistory(referenceDataAttributePayload).then(result => {
      var resultSet = result.data;

      let dataArray: ICurveReferenceAudit[] = [];
      //if there is no audit history, then simply create a manual Created entry
      if (resultSet.length == 0) {
        let data: ICurveReferenceAudit = {
          UpdatedDate: drawerProps.selectedItem.UpdatedDate,
          UpdatedBy: drawerProps.selectedItem.UpdatedBy,
          Change: "Created " + drawerProps.selectedItem.Value
        };
        dataArray.push(data);
      }
      //if there is audit history available
      else {
        //for the latest change in audit history, get the changed to value from the current table
        let data: ICurveReferenceAudit = {
          UpdatedDate: drawerProps.selectedItem.UpdatedDate,
          UpdatedBy: drawerProps.selectedItem.UpdatedBy,
          Change: "Modified from " + resultSet[0].Value + ' to ' + drawerProps.selectedItem.Value
        };
        dataArray.push(data);
        var i;
        for (i = 0; i <= resultSet.length; i++) {
          let data: ICurveReferenceAudit = {
            UpdatedDate: "",
            UpdatedBy: "",
            Change: null
          };
          var changeString = 'Modified from ';
          //ignore the first record as it is already handled above. First record is the latest change in audit
          if (i != 0 && i != resultSet.length) {
            changeString = changeString + resultSet[i].Value + ' to ' + resultSet[i - 1].Value;
            data.UpdatedDate = resultSet[i - 1].UpdatedDate;
            data.UpdatedBy = resultSet[i - 1].UpdatedBy;
            data.Change = changeString;
            dataArray.push(data);
          }
          //if it is the last record, then put it as created. (this is the first time it was updated.)
          if (i == resultSet.length) {
            changeString = "Created " + resultSet[i - 1].Value;
            data.UpdatedDate = resultSet[i - 1].UpdatedDate;
            data.UpdatedBy = resultSet[i - 1].UpdatedBy;
            data.Change = changeString;
            dataArray.push(data);
          }
        }
      }
      setReferenceDataAttributeAuditData(dataArray);
      setIsProcessing(false);

    });
  }
  }

  useEffect(() => {
    // Fetch the reference audit history on load and on change of payload
    getReferenceDataAttributeAuditHistory();
  }, [drawerProps.open]);

  const onLoading = (): string => {
    if (isProcessing) {
      return 'Loading...';
    }
    else {
      return 'No Data';
    }
  }


  const onClose = (): void => {
    drawerProps.onClose();
  };
  return (
    <>
      <Styled.DrawerStyled maskClosable={false} maskStyle={maskStyle} placement="right" open={drawerProps.open} onClose={onClose} header={headerText}>
        <Styled.SideDrawerGrid columns={2}>
          <Grid.Cell width={50}> <Styled.TableStyled columns={curvePriceColumns}
            rowKey="curveViewerId"
            key="curveViewerId"
            size='small'
            data={referenceDataAttributeAuditData}
            emptyText={onLoading}
            scroll={{
              x: 150,
              y: 550
            }}
            tableLayout="fixed"
          />
          </Grid.Cell>
        </Styled.SideDrawerGrid>

      </Styled.DrawerStyled>

    </>
  );
}