import { FormField, Grid, TextInput, ButtonGroup, ButtonGroupButton, Modal } from "@sede-x/shell-ds-react-framework";
import { useEffect, useState } from "react";
import * as Styled from './CurveReference.style';
import { MasterData } from "../../../services/MasterData";
import { BtnGrpEnableDisableStatus } from "../../../common/enum";
import { IPaginationFilter } from "../../../interfaces/IPaginationFilter";
import { ISortCriteria } from "../../../interfaces/ISortCriteria";
import { ValidationMessage, ValidationHelper } from "../../../common/validationHelper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PDSCONSTANT } from "../../../common/constants";
import { UserPermission } from '../../../services/UserPermission';
import { ICurveReferenceDataPayload } from "../../../interfaces/ICurveReferenceDataPayload";
import { ICurveReferenceFilter } from "../../../interfaces/ICurveReferenceFilter";
import { ICurveReferenceTimezoneDataRequest } from "../../../interfaces/ICurveReferenceTimezoneDataRequest";
// Areas array for Curve Configuration Side drawer
const areas = ['main .', 'subtext .', 'subtext .', 'table table'];

// Mask Style Background Color
const maskStyle = { backgroundColor: "black" };
// Footer to set the Buttons 
let footer: any;
// Represents Filter criteria
const filterCriteria: ICurveReferenceFilter = { Name: null, IsActive: true, WildCardSearchText: null };
//Represents Pagination filter
const paginationFilter: IPaginationFilter = { PageNumber: 0, PageSize: 0, TotalRecords: 0 };
// Represents Curve Reference Data request
let curveReferenceDataRequest: ICurveReferenceTimezoneDataRequest = { _id: '', Identifier: '', IsActive: true };
// Represents Sort Criteria
const sortCriteria: ISortCriteria[] = [];
// Side Drawer for curve configuration
export default function CurveReferenceTimezoneDrawer(drawerProps: any) {

  //object containing user permissons
  const permissions = UserPermission();

  // Set state Variables    
  const isEditable = drawerProps.selectedTimezone.Identifier !== '' ? true : false;
  const headerText = drawerProps.selectedTimezone.Identifier !== '' ? 'Edit ' + drawerProps.referenceAttributeName : 'Create ' + drawerProps.referenceAttributeName;


  const [statusValue, setStatusValue] = useState<string[] | string>(PDSCONSTANT.ENABLE_DEFAULT_TOGGLE_BTN);

  const [curveNameValidationErrorMessage, setcurveNameValidationErrorMessage] = useState('');

  const curveReferenceDataPayload: ICurveReferenceDataPayload = { WildCardSearchText: '', FilterCriteria: filterCriteria, PaginationFilter: paginationFilter, SortCriteria: sortCriteria }
  const [referenceDataRequest, setreferenceDataRequest] = useState<ICurveReferenceTimezoneDataRequest>(curveReferenceDataRequest);
  const { UpdateTimezoneReferenceData } = MasterData();
  const [isDisabled, setDisabled] = useState(false);
  useEffect(() => {
    //Reset Curve Reference Side Drawer
    ResetCurveRequest();
    // Fetch the Curve Reference data on load and on change of Curve Reference payload

    ResetCurveRequest()
    if (drawerProps && drawerProps.selectedTimezone) {
      setreferenceDataRequest(drawerProps.selectedTimezone)
      // Set IsActive status for Selected curve
      var isActive = drawerProps.selectedTimezone.IsActive === true ? BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] : BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Disable];
      setStatusValue(isActive);
    }
    //on load of side drawer enable the save button
    setDisabled(false);
  }, [drawerProps]);
  // For reset validation in case of on click of Create curve
  const resetValidation = () => {
    setcurveNameValidationErrorMessage("");

  }
  // Reset Curve Reference request on click of Reset button
  const ResetCurveRequest = () => {
    //Resetting Curve 
    curveReferenceDataRequest = { _id: '', Identifier: '', IsActive: true };

    // Resetting Enabled Flag
    setStatusValue(BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable]);
    resetValidation();
    setreferenceDataRequest(curveReferenceDataRequest);
  }
  // Reset Validation on Change of input and dropdown
  const resetValidationOnChange = (res: ICurveReferenceTimezoneDataRequest) => {
    // Resetting validation for curve name if there is some input
    if (res.Identifier !== '') { setcurveNameValidationErrorMessage(""); }

  }
  const setValidation = (res: ICurveReferenceTimezoneDataRequest): boolean => {
    let isValid: boolean = true;
    // Validating the curvename 
    if (IsNotValid(res.Identifier)) { setcurveNameValidationErrorMessage(getAttributeValidationMessage()); isValid = false; }
    return isValid;
  }

  const getAttributeValidationMessage = (): string => {
    let validationMessage: string = '';
    switch (drawerProps.referenceAttributeName) {
      case PDSCONSTANT.CURVE_GRANULARITY_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.GRANULARITY_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_TYPE_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.CURVE_TYPE_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_PRICE_TYPE_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.PRICE_TYPE_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_CURRENCY_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.CURRENCY_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_UNIT_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.UNIT_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_LOCATION_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.CURVE_LOCATION_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_COMMODITY_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.COMMODITY_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_COMMODITY_DELIVERY_POINT_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.COMMODITY_DELIVERYPOINT_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_PROFILE_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.PROFILE_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_DATA_PROVIDER_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.DATA_PROVIDER_REQUIRED;
        break;
      }
      case PDSCONSTANT.CURVE_SOURCE_PROVIDER_REFERENCE_ATTRIBUTE: {
        validationMessage = ValidationMessage.SOURCE_REQUIRED;
        break;
      }
      default: {
        validationMessage = ValidationMessage.DATA_PROVIDER_REQUIRED;
        break;
      }
    }
    return validationMessage;
  }

  const IsNotValid = (data: string | undefined): boolean => {
    return (data === undefined || data == null || data.length <= 0) ? true : false;
  }
  // EditCurve on click of save button
  const EditCurveReferenceTimezoneData = () => {
    try {
      // if button disabled not serve the request
      if (isDisabled) {
        return;
      }
      var res = referenceDataRequest;
      resetValidationOnChange(res);
      const isValid = setValidation(res);
      if (isValid) {
        setDisabled(true);
        EditCurveReferenceTimezoneDataRequest(res);
      }
    }
    catch (error) {
      // In case of error in Create or edit request enable the save button
      setDisabled(false);
    }
  }
  // API call for creating the curve or editing the curve 
  const EditCurveReferenceTimezoneDataRequest = (res: ICurveReferenceTimezoneDataRequest) => {

    //Trimming the End spaces and special charectors for all the input fields.
    let referenceDataRequest: ICurveReferenceTimezoneDataRequest;
    referenceDataRequest = res

    UpdateTimezoneReferenceData(referenceDataRequest).then(result => {
      ResetCurveRequest();
      drawerProps.onClose(true, PDSCONSTANT.CURVE_REFERENCE_CREATE_EDIT_SUCCESS);
      setDisabled(false);
    }).catch((err) => {
      toast.error(err.response.data.ErrorMessage, { position: toast.POSITION.TOP_CENTER });
      setDisabled(false);
    })

  }
  // On Drawer close
  const onClose = (): void => {
    drawerProps.onClose();
  };
  // On change of status 
  const handleStatusChange = (selectedValues: string[] | string): void => {
    referenceDataRequest.IsActive = (selectedValues === BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] ? true : false);
    setStatusValue(selectedValues);
  };


  // On Change of textbox input calls this method, this will remove the first special charecter 
  const onTextBoxInputChange = (fieldName: string, value: any) => {
    if (value !== '') {
      value = ValidationHelper().RecursiveRemoveFirstSpecialChar(value.trimLeft());
    }
    onInputChange(fieldName, value);
  };


  // On Change of input/dropdown call this method
  const onInputChange = (fieldName: string, value: any) => {

    referenceDataRequest[fieldName as keyof ICurveReferenceTimezoneDataRequest] = value;

    setreferenceDataRequest({ ...referenceDataRequest });
    resetValidationOnChange(referenceDataRequest);
  };
  footer = <Grid columns={2}>
    <Grid.Cell> </Grid.Cell>
    <Grid.Cell><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' disabled={isDisabled} onClick={EditCurveReferenceTimezoneData} >Save</Styled.DrawerButtonStyled><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' variant="outlined" onClick={onClose}>Cancel</Styled.DrawerButtonStyled></Grid.Cell>
  </Grid>;
  return (
    <><Styled.DrawerStyled maskClosable={false} maskStyle={maskStyle} placement="right" open={drawerProps.open} onClose={onClose} header={headerText}>

      <Styled.SideDrawerNameTextGrid columns={1}>
        <Grid.Cell><FormField id="Value" label="Identifier" mandatory state="error" message={curveNameValidationErrorMessage}>
          <TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' value={referenceDataRequest.Identifier} readOnly={true} placeholder="" onChange={(e: any) => onTextBoxInputChange("Value", e.target.value)} />
        </FormField></Grid.Cell>
        <Grid.Cell><FormField id="Status" label="Status" mandatory><Styled.ButtonGroupStyled id="status" size="medium" value={statusValue} onChange={handleStatusChange}>
          <ButtonGroupButton name="Enable" />
          <ButtonGroupButton name="Disable" />
        </Styled.ButtonGroupStyled ></FormField></Grid.Cell>
      </Styled.SideDrawerNameTextGrid>
      <hr />
      {/*render footer section -show create/save button, based on permission */}
      {permissions.CanWrite_ReferenceData() && footer}
      <ToastContainer />
    </Styled.DrawerStyled></>
  );
}