import axios, { AxiosInstance } from "axios";
import { Header } from "./HttpHeader";
import { ResponseCodes } from '../common/enum';
import JSONBigInt  from "json-bigint";


// Axios instance for http calls
export const HTTPClient=(headers : Header[]):AxiosInstance=> {
  
    var axiosInstance = axios.create();
  
    //add all headers
    headers.forEach((header :any) => {      
        axiosInstance.defaults.headers.common[header.Key] = header.Value;
    });

    // Intercepting request 
    axiosInstance.interceptors.request.use(    
        config => {
           
            return config;
        },
        error => {        
            Promise.reject(error);
        }
    );

    axiosInstance.defaults.transformResponse = [(data) => {
        if (typeof data === 'string') {
          try {
            data = JSONBigInt.parse(data);
          } catch (e) { /* Ignore */ } // Added this Ignore as it's the same in the Axios
        }
        return data;
    }];
    
    // intercepting response from api
    axiosInstance.interceptors.response.use((response) => {
        return response;
    }, function(error){
        
        if(error.response !== undefined && (error.response.status === ResponseCodes.UnAuthorized)){
           console.log(error.response);
          
        } 
        if(error.response !== undefined && (error.response.status === ResponseCodes.BadRequest)){
            console.log(error.response);
            
         } 
         if(error.response !== undefined && (error.response.status === ResponseCodes.InternalServerError)){
            console.log(error.response);
          
         } 
         if(error.response !== undefined && (error.response.status === ResponseCodes.NotFound)){
            console.log(error.response);
           
         } 
        return Promise.reject(error);    
    })

    return (axiosInstance);
}
