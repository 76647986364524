import React from "react";
import type { User } from "oidc-client";
import type { AuthService } from "../auth";
import { HttpHeader } from "../HttpHeader";
import { HTTPClient } from "../apiservice";
import { APIURL } from "../api-url";


//appuser model to hold the user,roles and permissions for logged in user
export class AppUser
{
  constructor(){ 
    this.user = null;    
  }
  user:User | null;
  Roles : string[] | undefined;
  Permissions : string[] | undefined;
}

//model to hold pds roles
export interface IRole{
  // Role Name
  roleName:string;
  // persmissions
  privileges:string[];
  //status of role
  isActive:boolean;
}



//Auth Provider props 
// children - representing the child component of AuthContext
// authService - representing the AuthService 
export interface AuthProviderProps {
  children: React.ReactNode;
  authService: AuthService;
}

//IAuthProviderState 
// isLoading - Identify whether the AuthProvider is loaded or not
// user - User containing the User object 
export interface IAuthProviderState {
  isLoading: boolean;
  appUser: AppUser | null;
}

//IAuthContext
// isAuthenticated - representing whether the user is authenticated or not 
// isLoading - Identify whether the AuthProvider is loaded or not
// user - User containing the User object 
interface IAuthContext extends AuthService {
  isAuthenticated: () => boolean;
  isLoading: boolean;
  appUser: AppUser | null;
}

// Returns context object of type IAuthContext
// createContext lets you create a context that components can provide or read
// Context lets components pass information deep down without explicitly passing props
export const AuthContext = React.createContext<IAuthContext | null>(null);

// useContext to use context of type IAuthContext
export const useAuth = () => React.useContext(AuthContext) as IAuthContext;

// AuthProvider for setting the user details and checking whether the user is authenticated or not
export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const [state, setState] = React.useState<IAuthProviderState>({
    isLoading: true,
    appUser: null,
  });

  React.useEffect(() => {
    const initialise = async () => {
      // Cleanup any stale state
      await props.authService.clearStaleState();

      // Subscribe to new users being loaded, when the user is
      // silently logged in to get new tokens this will trigger
      props.authService.subscribeToUserLoaded(async(user) => {
      
          //set the app user
          const appUser = new AppUser();
          appUser.user = user;

          //set the loggedin user roles and permission to appUser state
          
          //get/prepare http reqeust header and httpClient
          var config =  HttpHeader().GetHeader(user?.access_token,process.env.REACT_APP_SECURITY_HEADER_API_VERSION);
          var httpAxios =  HTTPClient(config); 
          //get the pds roles
           await httpAxios.get(APIURL.SECURITY_GET_ROLES).then( async(response)=>
            {          
              const pdsRoles : IRole[] = response.data;
              let permissions : string[] | any = [];  
              var userRoles : string[] = [];         
                
              const email = user?.profile?.sub;
              //get user roles
                await httpAxios.get(APIURL.SECURITY_GET_USER + email).then(response=>
                {

                  if(response.data.isActive){
                    userRoles = response.data.roles;
                  }  
                  else{
                    userRoles = []; //inactive users will not have access to application and land to access denied page
                  }           
                  
                  var privileges : string[] | any = []
                  
                  userRoles?.forEach((userRole) => {
                    //get all permissions by each user role
                    privileges = pdsRoles?.find(t => t.roleName === userRole)?.privileges 
                    //store all user permissions for each role             
                    permissions = [...permissions, ...privileges];  
            
                  }); 
              }).catch(function(error) {
              if (error.status === 404) {
                  console.log(error.response.status);
              }});

              //prepare the appUser object to be used across the app                     
              appUser.user = user;
              appUser.Roles = userRoles;
              //filter and set distinct permissions
              appUser.Permissions = permissions.filter((n:any, i:any) => permissions.indexOf(n) === i);
              
              //set the appUser
              setState({ isLoading: false, appUser });
            });
                  
      });      

      // Obtain the user, will be null if not logged in
      const user = await props.authService.getUser();      
      var appUser = new AppUser();
      appUser.user = user;        
      setState({ isLoading: false, appUser });         
      
    };
    initialise();   
    
  }, [props.authService]);

  const isAuthenticated = (): boolean => {
    return !!state.appUser?.user && !state.appUser?.user?.expired;
  };

  return (
    <AuthContext.Provider
      value={{ ...props.authService, ...state, isAuthenticated }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
