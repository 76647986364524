import { Checkbox, ColumnsType, RecordType, Tooltip, Text } from "@sede-x/shell-ds-react-framework";
import { PDSCONSTANT } from "../../common/constants";
import { IAligneExportReportColumnProps } from "../../interfaces/IAligneExportReportColumnProps";
import { HeaderText } from "../CurveViewer/CurveViewerMain.styles";
import React from "react";

// Columns for  Aligne Export Report Table
export const AligneExportReportColumnsHelper = (props: IAligneExportReportColumnProps): ColumnsType<RecordType> => {
  const aligneExportReportColumn: ColumnsType<RecordType> = [
    {
      dataIndex: 'IsChecked',
      key: 'IsChecked',
      render: props.renderCheckbox,
      title: <Checkbox crossOrigin='false' size='small' label="" checked={props.headerCheckbox} onChange={props.handleCheckboxAll} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
    },
    {
      dataIndex: 'CurveName',
      key: 'CurveName',
      title: 'Name',    

      sorter: {
        compare: (a, b): number => {
          if (a.CurveName !== b.CurveName) {
            return a.CurveName < b.CurveName ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    },
    {
      dataIndex: 'Granularity',
      key: 'Granularity',
      title: 'Granularity',

      sorter: {
        compare: (a, b): number => {
          if (a.Granularity !== b.Granularity) {
            return a.Granularity < b.Granularity ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    },
    {
      dataIndex: 'CurveType',
      key: 'CurveType',
      title: 'Curve Type',
      sorter: {
        compare: (a, b): number => {
          if (a.CurveType !== b.CurveType) {
            return a.CurveType < b.CurveType ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    },

    {
      dataIndex: 'EffectiveDate',
      key: 'EffectiveDate',
      title: 'Effective Date',
      sorter: {
        compare: (a, b): number => {
          if (a.effectivedate !== b.effectivedate) {
            return a.effectivedate < b.effectivedate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: props.renderDateValue
    },
    {
      dataIndex: 'AlignePriceDate',
      key: 'AlignePriceDate',
      title: 'Aligne Price Date',
      sorter: {
        compare: (a, b): number => {
          if (a.alignePriceDate !== b.alignePriceDate) {
            return a.alignePriceDate < b.alignePriceDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: props.renderDateValue
    },
    {
      dataIndex: 'Market',
      key: 'Market',
      title: 'Market',

      sorter: {
        compare: (a, b): number => {
          if (a.Market !== b.Market) {
            return a.Market < b.Market ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    },
    {
      dataIndex: 'Component',
      key: 'Component',
      title: 'Component',

      sorter: {
        compare: (a, b): number => {
          if (a.Component !== b.Component) {
            return a.Component < b.Component ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    },
    {
      dataIndex: 'PSET',
      key: 'PSET',
      title: 'PSET',

      sorter: {
        compare: (a, b): number => {
          if (a.PSET !== b.PSET) {
            return a.PSET < b.PSET ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    },
    {
      dataIndex: 'Settle',
      key: 'Settle',
      title: 'Settle',

      sorter: {
        compare: (a, b): number => {
          if (a.Settle !== b.Settle) {
            return a.Settle < b.Settle ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    },
    {
      dataIndex: 'CurveUpdatedDate',
      key: 'CurveUpdatedDate',
      title: <Tooltip  placeholder="" placement="topLeft" overlay={PDSCONSTANT.TOOLTIP_TEXT_PDS_LAST_UPDATED} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <span>Last Updated (PDS)</span>
      </Tooltip>,
      sorter: {
        compare: (a, b): number => {
          if (a.CurveUpdatedDate !== b.CurveUpdatedDate) {
            return a.CurveUpdatedDate < b.CurveUpdatedDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: props.renderSourceDateAndTimeValue
    },
    {
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      title: <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}  placeholder="" placement="topLeft" overlay={PDSCONSTANT.TOOLTIP_TEXT_EXPORT_INITIATED_DATE}><span>Export Initiated Date</span></Tooltip>,
      sorter: {
        compare: (a, b): number => {
          if (a.UpdatedDate !== b.UpdatedDate) {
            return a.UpdatedDate < b.UpdatedDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: props.renderSourceDateAndTimeValue
    },
    {
      dataIndex: 'UpdatedDate',
      key: 'UpdatedDate',
      title: <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" placement="topLeft" overlay={PDSCONSTANT.TOOLTIP_TEXT_EXPORT_STATUS_UPDATED_DATE}><span>Export Status Updated Date</span></Tooltip>,
      sorter: {
        compare: (a, b): number => {
          if (a.UpdatedDate !== b.UpdatedDate) {
            return a.UpdatedDate < b.UpdatedDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: props.renderSourceDateAndTimeValue
    },
    {
      dataIndex: 'TotalTimeElapsed',
      key: 'TotalTimeElapsed',
      title: <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" placement="topLeft" overlay={PDSCONSTANT.TOOLTIP_TEXT_EXPORT_TOTAL_TIME_ELAPSED}><span>Total Time Elapsed (in min)</span></Tooltip>,
      render: props.renderValue
    },
    {
      dataIndex: 'ExportSchedule',
      key: 'ExportSchedule',
      title: 'Export Type',
      sorter: {
        compare: (a, b): number => {
          if (a.ExportSchedule !== b.ExportSchedule) {
            if (a.ExportSchedule < b.ExportSchedule) {
              return -1;
            }
            else {
              return 1;
            }
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    },
    {
      dataIndex: 'CreatedBy',
      key: 'CreatedBy',
      title: 'Exported By',
      sorter: {
        compare: (a, b): number => {
          if (a.CreatedBy !== b.CreatedBy) {
            return a.CreatedBy < b.CreatedBy ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    },
    {
      dataIndex: 'Status',
      key: 'Status',
      title: 'Export Status',

      sorter: {
        compare: (a, b): number => {
          if (a.Status !== b.Status) {
            return a.Status < b.Status ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderStatusValue
    },
    {
      dataIndex: 'Notes',
      key: 'Notes',
      className: 'aligne-export-report-table__notes',
      title: 'Notes',
      sorter: {
        compare: (a, b): number => {
          if (a.Notes !== b.Notes) {
            return a.Notes < b.Notes ? -1 : 1;
          }
          return 0;
        }, 
      },      
      sortDirections: ['ascend', 'descend'],        
      render: props.renderNotes },
      {
        dataIndex: 'NotesUpdatedBy',
        key: 'NotesUpdatedBy',
  
        title: 'Notes Updated By',
        sorter: {
          compare: (a, b): number => {
            if (a.NotesUpdatedBy !== b.NotesUpdatedBy) {
              return a.NotesUpdatedBy < b.NotesUpdatedBy ? -1 : 1;
            }
            return 0;
          }, 
        },      
        sortDirections: ['ascend', 'descend'],        
        render: props.renderValue },
        {
          dataIndex: 'NotesUpdatedDate',
          key: 'NotesUpdatedDate',
          
          title: 'Notes Updated Date',
          sorter: {
            compare: (a, b): number => {
              if (a.NotesUpdatedDate !== b.NotesUpdatedDate) {
                return a.NotesUpdatedDate < b.NotesUpdatedDate ? -1 : 1;
              }
              return 0;
            }, 
          },      
          sortDirections: ['ascend', 'descend'],        
          render: props.renderSourceDateAndTimeValue },
    {
      dataIndex: 'SETATrackingID',
      key: 'SETATrackingID',
      
      title: 'SETA Tracking ID',
      sorter: {
        compare: (a, b): number => {
          if (a.SETATrackingID !== b.SETATrackingID) {
            return a.SETATrackingID < b.SETATrackingID ? -1 : 1;
          }
          return 0;
        }, 
      },      
      sortDirections: ['ascend', 'descend'],           
      render: props.renderLinkValue }

    
    

  ];
  return (aligneExportReportColumn);
}