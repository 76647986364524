import { Table } from '@sede-x/shell-ds-react-framework';
import styled, { css } from 'styled-components';

// Styling table for alternate row colours
const DataTableStyled = styled(Table)`
tr:nth-child(odd) td {
  background-color: rgba(0, 0, 0, 0);
}
tr:nth-child(even) td {
  background-color: rgba(0, 0, 0, 0.04);
}
.shell-table-thead .shell-table-cell {
  background-color: rgba(0, 0, 0, 0.04);
}
width:100%;    
`;
const Container = styled.div`
  width: 100%;
  overflow-x: scroll;  
`;

const PopupContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y:scroll;
  height:300px;  
`;

const VerticalScrollBarPopupContainer = styled.div`
  width: 100%;
  overflow-y:scroll;
  height:300px;  
`;


export {DataTableStyled, Container,PopupContainer,VerticalScrollBarPopupContainer}