import { PageLayout } from "@sede-x/glass-design-library";
import styled from "styled-components";

// Styling for Page Layout container
const MainContainer = styled.div`
height: 100% ! important;
width: 100% ! important;
[role=tab] {
  font-weight:400 ! important;

}
[aria-selected="true"]{
  font-weight:600 ! important; 
}
`;




// Styling for inner PageLayout
const PageLayoutStyled = styled(PageLayout)`
height: 100vh ! important;
width: 100vw ! important;
[data-testid=globalheader-leftArea]{
  background-color:blue !important;
}
.shell-divider:{
  width:90px ! important;
}
.shell-tabs-tab-btn:{
  font-size:0;
}

`;



export {PageLayoutStyled, MainContainer}