import { AxiosInstance } from "axios";
import { HTTPClient } from "../common/apiservice";
import { IAligneCurvePayload } from "../interfaces/IAligneCurvePayload";
import { APIURL } from "../common/api-url";
import { IAligneCurveConfigurationRequest } from "../interfaces/IAligneCurveConfigurationRequest";
import { HttpHeader } from "../common/HttpHeader";
import { useAuth } from "../common/hooks/auth-provider";
import { IAligneCurveDownloadFilter } from "../interfaces/IAligneCurveDownloadFilter";

// Service class for Aligne Curves Configuration
export const AligneCurveConfigurations = () => {

    const { appUser } = useAuth();
    //setup http header
    var config = HttpHeader().GetHeader(appUser?.user?.access_token, process.env.REACT_APP_HEADER_API_VERSION);
    // Instance of Axios to call get and post method
    const _httpclient: AxiosInstance = HTTPClient(config);
    // Method for fetching GetAligneCurvesConfiguration  data based on AligneCurveConfigurationFiletr payload
    async function GetAligneCurvesConfiguration(aligneCurveFilter: IAligneCurvePayload) {
        const result = await _httpclient.post(APIURL.ALIGNE_CURVE_CONFIGURATION_URL, aligneCurveFilter);
        return result;
    }

    // Method for fetching CreateAligneCurvesConfiguration  data based on AligneCurveConfiguration payload
    async function CreateAligneCurvesConfiguration(aligneCurveConfiguration: IAligneCurveConfigurationRequest) {
        const result = await _httpclient.post(APIURL.CREATE_EDIT_ALIGNE_CURVE_CONFIGURATION_URL, aligneCurveConfiguration);
        return result;
    }
    // Method for fetching EditAligneCurvesConfiguration  data based on AligneCurveConfiguration payload
    async function EditAligneCurvesConfiguration(aligneCurveConfiguration: IAligneCurveConfigurationRequest) {
        const result = await _httpclient.put(APIURL.CREATE_EDIT_ALIGNE_CURVE_CONFIGURATION_URL, aligneCurveConfiguration);
        return result;
    }
    // Method for downloading Aligne Curve based on downlaodFiter     
    async function DownloadSelectedAligneCurveConfigurationData(downloadFilter:IAligneCurveDownloadFilter) {

        return await _httpclient.post(APIURL.ALIGNE_CURVE_CONFIGURATION_DOWNLOAD_DATA_URL, downloadFilter);
    }


    return {
        GetAligneCurvesConfiguration,
        CreateAligneCurvesConfiguration,
        EditAligneCurvesConfiguration,
        DownloadSelectedAligneCurveConfigurationData

    };

}