import { GlobalHeader, SectionHeader } from "@sede-x/glass-design-library";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IHeaderProps } from "../interfaces/IHeaderProps";
import { SectionHeaderStyled } from "./Header.style";
let sectionHeader: any;
// Header component
export const Header = (props: IHeaderProps) => {
  // For routing to component on the basis of Tab
  const navigate = useNavigate();
  const aligneSubMenu: string[] = ['alignemapping', 'manualaligneexport', 'aligneexportreport'];
  const isFind = aligneSubMenu.includes(window.location.pathname.replace("/", ""));
  const menu = isFind ? 'alignemapping' : window.location.pathname.replace("/", "");

  // Set default tab to CurveViewer  
  const [tab, setTab] = useState(menu);

  const [subMenuTab, setSubMenuTab] = useState(window.location.pathname.replace("/", ""));

  if (tab == 'alignemapping') {
    sectionHeader = <><SectionHeaderStyled navAreaOnly title="" navTabs={{
      align: 'left',

      tabs: {
        defaultTab: window.location.pathname.replace("/", ""),
        onChangeTab: function onTabChange(e) { navigate('/' + e); setSubMenuTab(e); },
        tabs: [
          {
            key: 'alignemapping',
            label: 'Aligne Mapping'
          },
          {
            key: 'manualaligneexport',
            label: 'Manual Aligne Export'
          },
          {
            key: 'aligneexportreport',
            label: 'Aligne Export Report'
          }
        ]
      }
    }}></SectionHeaderStyled></>
  }
  else if (tab == 'calendarconfiguration') {
    sectionHeader = <><SectionHeaderStyled navAreaOnly title="" navTabs={{
      align: 'left',

      tabs: {
        defaultTab: window.location.pathname.replace("/", ""),
        onChangeTab: function onTabChange(e) { navigate('/' + e); setSubMenuTab(e); },
        tabs: [
          {
            key: 'calendarconfiguration',
            label: 'Calendar'
          },
          {
            key: 'curvegranularityattribute',
            label: 'Granularity'
          },
          {
            key: 'curvetypeattribute',
            label: 'Curve Type'
          },
          {
            key: 'curvepricetypeattribute',
            label: 'Price Type'
          },
          {
            key: 'curvecurrencyattribute',
            label: 'Currency'
          },
          {
            key: 'curveunitattribute',
            label: 'Unit'
          },
          {
            key: 'curvelocationattribute',
            label: 'Location'
          },
          {
            key: 'curvecommodityattribute',
            label: 'Commodity'
          },
          {
            key: 'curvedeliverypointattribute',
            label: 'Delivery Point'
          },
          {
            key: 'curveprofileattribute',
            label: 'Profile'
          },
          {
            key: 'curvedataproviderattribute',
            label: 'Data Providers'
          },
          {
            key: 'curvesourceproviderattribute',
            label: 'Source Providers'
          },
          {
            key: 'timezoneidentifierattribute',
            label: 'Timezone'
          }
        ]
      }
    }}></SectionHeaderStyled></>
  }
  else {
    sectionHeader = "";
  }

  // Global Header component
  return (
    <>
      <GlobalHeader
        avatar={
          {
            icon: props.shortname,
            src: '',
            action: function noRefCheck() { },
            title: props.fullname,
            description: props.emailAddress
          }
        }
        title={process.env.REACT_APP_APPLICATIONTITLE ? process.env.REACT_APP_APPLICATIONTITLE : ''}
        subtitle={process.env.REACT_APP_APPLICATIONSUBTITLE ? process.env.REACT_APP_APPLICATIONSUBTITLE : ''}
        defaultTab={tab}
        onChangeTab={function onRef(e) {
          navigate('/' + e);
          setTab(e);
        }}
        tabs={[
          {
            key: 'curveviewer',
            label: 'Curve Viewer'
          },
          {
            key: 'curveconfiguration',
            label: 'Curve Configuration'
          },
          {
            key: 'alignemapping',
            label: 'Aligne'

          },
          {
            key: 'calendarconfiguration',
            label: 'Reference Data'

          }
        ]}
      />
      {sectionHeader}
    </>
  )
}