import { Checkbox, ColumnsType, RecordType,Tooltip,Text } from "@sede-x/shell-ds-react-framework";
import { PDSCONSTANT } from "../../common/constants";
import { IManualAligneExportColumnProps } from "../../interfaces/IManualAligneExportColumnProps";

// Columns for Manual Aligne Export Table
export const ManualAligneExportColumnsHelper=(props:IManualAligneExportColumnProps):ColumnsType<RecordType>=>
{   const manualAligneExportColumns:ColumnsType<RecordType>= [    
    { dataIndex: 'IsChecked',
      key: 'IsChecked',      
      render: props.renderCheckbox, 
      title: <Checkbox crossOrigin='false' size='small' label="" checked={props.headerCheckbox}  onChange={props.handleCheckboxAll} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/> },
    { dataIndex: 'CurveName',
      key: 'CurveName',
      title: 'Name',     
      sorter: {
        compare: (a, b): number => {
          if (a.CurveName !== b.CurveName) {
            return a.CurveName < b.CurveName ? -1 : 1;
          } 
          return 0;
        }, 
      },
      sortDirections: ['ascend', 'descend'],        
      render: props.renderLinkValue
    },
    { dataIndex: 'Granularity',
      key: 'Granularity',
      title: 'Granularity',
      sorter: {
        compare: (a, b): number => {
          if (a.Granularity !== b.Granularity) {
            return a.Granularity < b.Granularity ? -1 : 1;
          } 
          return 0;
        },
      },         
      sortDirections: ['ascend', 'descend'],            
      render: props.renderValue },
      { dataIndex: 'CurveType',
      key: 'CurveType',
      title: 'Curve Type',
      sorter: {
        compare: (a, b): number => {
          if (a.CurveType !== b.CurveType) {
            return a.CurveType < b.CurveType ? -1 : 1;
          } 
          return 0;
        },
      },         
      sortDirections: ['ascend', 'descend'],            
      render: props.renderValue },
      { dataIndex: 'ExportSchedule',
      key: 'ExportSchedule',
      title: 'Export Schedule',
      sorter: {
        compare: (a, b): number => {
          if (a.ExportSchedule !== b.ExportSchedule) {
            return a.ExportSchedule < b.ExportSchedule ? -1 : 1;
          } 
          return 0;
        },
      },         
      sortDirections: ['ascend', 'descend'],            
      render: props.renderValue },
    { dataIndex: 'Market',
      key: 'Market',
      title: 'MARKET',
      sorter: {
        compare: (a, b): number => {
          if (a.Market !== b.Market) {
            return a.Market < b.Market ? -1 : 1;
          } 
          return 0;
        },
      },         
      sortDirections: ['ascend', 'descend'],            
      render: props.renderValue },
    { dataIndex: 'Component',
      key: 'Component',
      title: 'COMPONENT', 
      sorter: {
        compare: (a, b): number => {
          if (a.Component !== b.Component) {
            return a.Component < b.Component ? -1 : 1;
          } 
          return 0;
        },
      },     
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
    { dataIndex: 'PSET',
      key: 'PSET',
      title: 'PSET', 
      sorter: {
        compare: (a, b): number => {
          if (a.PSET !== b.PSET) {
            return a.PSET < b.PSET ? -1 : 1;
          } 
          return 0;
        }, 
      },      
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue },
      {dataIndex: 'Settle',
      key: 'Settle',
      title: 'Settle',     
      sorter: {}, 
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
      {
        dataIndex: 'EffectiveDate',
        key: 'EffectiveDate',
        title: 'Effective Date',
        sorter: {
          compare: (a, b): number => {
            if (a.effectivedate !== b.effectivedate) {
              return a.effectivedate < b.effectivedate ? -1 : 1;
            }
            return 0;
          },
        },
        sortDirections: ['descend', 'ascend'],        
        render: props.renderDateValue
      },
      {
        dataIndex: 'UpdatedDate',
        key: 'UpdatedDate',
        title: <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" placement="topLeft" overlay={PDSCONSTANT.TOOLTIP_TEXT_PDS_LAST_UPDATED}>
        <span>Last Updated (PDS)</span>
        </Tooltip>,        
        sorter: {
          compare: (a, b): number => {
            if (a.UpdatedDate !== b.UpdatedDate) {
              return a.UpdatedDate < b.UpdatedDate ? -1 : 1;
            }
            return 0;
          },
        },
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder:'ascend',            
        render: props.renderSourceDateAndTimeValue 
      },      
      {dataIndex: 'IsComplete',
        key: 'IsComplete',
        title: <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" placement="topLeft" overlay={PDSCONSTANT.TOOLTIP_TEXT_IS_CURVE_COMPLETE_HEADER}>
        <span>Data complete</span>
     </Tooltip>,  
        sorter: {
          compare: (a, b): number => {
            if (a.IsComplete !== b.IsComplete) {
              return a.IsComplete < b.IsComplete ? -1 : 1;
            } 
            return 0;
          },
        },      
        sortDirections: ['ascend', 'descend'],        
        render: props.renderCompletionFlow }
  ];
  return(manualAligneExportColumns);  
}