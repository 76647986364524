import { ButtonLink, Checkbox, Grid, useSorter } from '@sede-x/shell-ds-react-framework';
import { Add, FileVersion, Download, Search } from '@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components';
import { ColumnsType, RecordType } from '@sede-x/shell-ds-react-framework/build/cjs/components/Table/Table.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TABLE_CLASS } from '@sede-x/shell-ds-react-framework/build/cjs/utils/constants';
import * as Styled from '../CommonComponent/CurveReference.style';
import DataTableWithScrollBar from '../../../library/Table/TableWithScrollBar';
import DataTablePagination from '../../../library/Pagination/Pagination';
import { IPaginationFilter } from '../../../interfaces/IPaginationFilter';
import { ISortCriteria } from '../../../interfaces/ISortCriteria';
import { PDSCONSTANT } from '../../../common/constants';
import { SortDirection, BtnGrpEnableDisableStatus } from '../../../common/enum';
import { CurveReferenceColumnsHelper } from '../CommonComponent/CurveReferenceColumnsHelper';
import { ICurveConfigurationColumnProps } from '../../../interfaces/ICurveConfigurationColumnProps';
import { MasterData } from '../../../services/MasterData';
import Loader from '../../../library/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IDownloadCurveReferenceFilter } from '../../../interfaces/IDownloadFilter';

import { UserPermission } from '../../../services/UserPermission';
import dayjs from 'dayjs';
import { ICurveReferenceDataPayload } from '../../../interfaces/ICurveReferenceDataPayload';
import { ICurveReferenceFilter } from '../../../interfaces/ICurveReferenceFilter';
import { ICurveReferenceData } from '../../../interfaces/ICurveReferenceData';
import CurveReferenceDrawer from '../CommonComponent/CurveReferenceSideDrawer';
import { DownloadHelper } from '../../../common/DownloadHelper';
import { ICurveConfigurationColumnPropsExtensions } from '../../../interfaces/ICurveConfigurationColumnPropsExtension';
import CurveReferenceAuditDrawer from '../CommonComponent/CurveReferenceAuditDrawer';
// React screen for Curve Viewer
export const SourceProvider = () => {

    //object containing user permissons
    const permissions = UserPermission();

    // React state variables  
    const defaultPageSize = PDSCONSTANT.DEFAULT_PAGE_SIZE;
    const [currentPage, setPage] = useState(1);
    const [curveReferenceData, setCurveReferenceData] = useState<ICurveReferenceData[]>([]);
    const [curveReference, setCurveReference] = useState<ICurveReferenceData>({ _id: '', Name: '', Value: '', IsActive: false, IsChecked: false });
    const [isProcessing, setIsProcessing] = useState(false); const { GetCurveReferenceDataAttributes, DownloadSelectedReferenceData } = MasterData();
    const [wildCardSearchText, setWildCardSearchText] = useState('');
    const curveReferenceFilter: ICurveReferenceFilter = { Name: null, IsActive: false, WildCardSearchText: null };
    const [filterCriteria, setFilterCriteria] = useState<ICurveReferenceFilter>({ Name: null, WildCardSearchText: null, IsActive: true });
    const [paginationFilter, setPaginationFilter] = useState<IPaginationFilter>({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
    const [sortCriteria, setSortCriteria] = useState<ISortCriteria[]>([]);

    //used for maintain and send the selectAll Status to backend api
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    //use to maintain the header checkbox check/uncheck status at UI only, no role in backend selectAll Status
    const [headerCheckboxUI, setHeaderCheckboxUI] = useState(false);

    let [selectedData, setSelectedData] = useState<string[]>([]);
    let [unSelectedData, setUnSelectedData] = useState<string[]>([]);
    const referenceAttribute = PDSCONSTANT.CURVE_SOURCE_PROVIDER_REFERENCE_ATTRIBUTE;

    const productCurveReferenceDataPayload: ICurveReferenceDataPayload = {
        WildCardSearchText: wildCardSearchText,
        FilterCriteria: filterCriteria,
        PaginationFilter: paginationFilter,
        SortCriteria: sortCriteria
    }

    //initialize to default
    const downloadFilter: IDownloadCurveReferenceFilter = {
        ReferenceDataAttributeFilter: filterCriteria,
        IsAllSelected: headerCheckbox,
        SelectedAttributeList: selectedData,
        UnSelectedAttributeList: unSelectedData
    }

    useEffect(() => {
        // Set Page Size on the basis of  Window size 
        paginationFilter.PageSize = window.innerHeight ? parseInt((window.innerHeight / 70).toFixed()) : 10;
        // Fetch the Curve Reference data on load and on change of Curve Reference Data payload
        getCurveReferenceData();
    }, [wildCardSearchText, paginationFilter, sortCriteria]);


    // Api calls for Curve Reference data
    const getCurveReferenceData = () => {
        // Comment for Loader - To be built    
        setCurveReferenceData([]);
        setIsProcessing(true);
        productCurveReferenceDataPayload.FilterCriteria.Name = referenceAttribute;
        GetCurveReferenceDataAttributes(productCurveReferenceDataPayload).then(result => {

            //maintain the checked status of current page on pagination
            let currentPageData: any = result.data.Data;
            currentPageData.map((curve: any) => {

                //handle checkbox selection on pagination  
                if (headerCheckbox) {
                    //handle unchecked list -> marked them all unchecked
                    var uncheckMe = unSelectedData.some(el => el === curve.Value);
                    curve.IsChecked = !uncheckMe && headerCheckbox;
                }
                else {
                    //handle checked list - marked them all checked             
                    var checkedMe = selectedData.some(el => el === curve.Value);
                    curve.IsChecked = checkedMe;
                }

                return curve;
            });




            //set the grid data
            setCurveReferenceData(currentPageData);
            setIsProcessing(false);
            paginationFilter.TotalRecords = result.data.PaginationFilter.TotalRecords;
            paginationFilter.PageNumber = currentPage;
            paginationFilter.PageSize = result.data.PaginationFilte.PageSize;
        }).catch((err) => {
            setIsProcessing(false);
        });
    }


    // For creation of curve open Side drawer
    const createCurve = () => {
        setCurveReference({ _id: '', Name: '', Value: '', IsActive: true, IsChecked: false });
        setOpen(true);
    }


    // Method for Download reference data
    const downloadSelectedReferenceData = () => {

        //show error message if no curve is selected to download
        if (!downloadFilter.IsAllSelected && downloadFilter.SelectedAttributeList.length === 0) {
            toast.error("No curve selected to download!", { position: toast.POSITION.TOP_CENTER });
            return;
        }
        downloadFilter.ReferenceDataAttributeFilter.Name = PDSCONSTANT.CURVE_SOURCE_PROVIDER_REFERENCE_ATTRIBUTE;
        // API calls for download selected reference data 
        DownloadSelectedReferenceData(downloadFilter).then(result => {
            //call to helper to download
            DownloadHelper().ExportToCSV(PDSCONSTANT.EXPORT_FILENAME_SOURCE_PROVIDER_REFERENCE_DATA, result.data);
        });
    }


    // On Page Change updated the Pagination filter
    const handlePaginationFilter = (paginationData: IPaginationFilter) => {
        setPaginationFilter(paginationData);
    }

    // For rendering check box all functionality 
    const handleCheckboxAll = () => {
        // In case of clicking on select/unselect all check box render the curve data again    
        const checkedCurveData = curveReferenceData.map((item) => ({
            ...item,
            IsChecked: !headerCheckbox,
        }));
        // Setting the curve data again
        setCurveReferenceData(checkedCurveData);
        // Populate the array with checked curve names
        const selectedCurveData = checkedCurveData.filter((obj) => {
            return obj.IsChecked === true;
        }).map(a => a.Value);

        //clear checkbox selection list as all is selected
        setSelectedData([]);
        setUnSelectedData([]);
        // Setting the header checkbox on click 
        setHeaderCheckbox(!headerCheckbox);
        setHeaderCheckboxUI(!headerCheckbox);
    };

    // handle child checkbox selection when header is checked
    const handleChildSelectionWhenHeaderIsChecked = (currentSelection: any) => {

        if (!currentSelection.IsChecked) {
            unSelectedData.push(currentSelection._id);
            //also uncheck the global UI checkbox
            setHeaderCheckboxUI(false);
        }
        else {
            unSelectedData = unSelectedData.filter(function (item) {
                return item !== currentSelection._id
            });
            //checked header check box checked, if no unchecked
            if (unSelectedData.length === 0) {
                setHeaderCheckboxUI(true);
            }
        }
        // Assign state variable value to new variable to update with new state    
        let unselectedCurveConfigurationData: string[] = unSelectedData;
        //clear the selected list
        setUnSelectedData(unselectedCurveConfigurationData);
        setSelectedData([]);
    };


    // For rendering checkbox in table  
    const renderCheckbox = (value: any, record: any, index: number) => {
        //Handle the changes on check or uncheck of a particular row checkbox
        const handleChange = () => {
            // Update the particular row checked/unchecked status 
            const selectCurveData = [
                ...curveReferenceData.slice(0, index),
                {
                    ...curveReferenceData[index],
                    IsChecked: !curveReferenceData[index].IsChecked,
                },
                ...curveReferenceData.slice(index + 1),
            ];
            // Set the updated curve status 
            setCurveReferenceData(selectCurveData);

            var currentSelection = selectCurveData[index];

            //handle checkbox selection when header is checked/unchecked
            if (headerCheckbox) {
                //handle child checkbox selection when header checkbox is unchecked   
                //re-factored separate method to reduce congnative complexity - sonar issue fix
                handleChildSelectionWhenHeaderIsChecked(currentSelection);
            }
            else {
                //handle when header checkbox is unchecked

                if (currentSelection.IsChecked) {
                    selectedData.push(currentSelection._id);
                }
                else {
                    selectedData = selectedData.filter(function (item) {
                        return item !== currentSelection._id
                    });
                    setHeaderCheckboxUI(false);
                }

                //if all item selected -> checked header checkbox
                if (selectedData.length === paginationFilter.TotalRecords && unSelectedData.length === 0) {
                    setHeaderCheckboxUI(true);
                }
                // Assign state variable value to new variable to update with new state    
                let selectedCurveReferenceData: string[] = selectedData;
                //clear the selected list
                setSelectedData(selectedCurveReferenceData);
                setUnSelectedData([]);
            }

        };
        // Rendering the checkbox for row            
        return <Checkbox crossOrigin='false' label="" size='small' id={index.toString()} checked={value} onChange={handleChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />;
    };

    // reset checkbox selections
    const resetCheckboxSelection = () => {
        //clear all selection and headerCheck to false
        setSelectedData([]);
        setUnSelectedData([]);
        // Setting the header checkbox on click 
        setHeaderCheckbox(false);
    };

    // For rendering string value in table
    const renderValue = (value: any, record: any, index: number) => {
        return (<div>{value}</div>)
    };
    //Set the Page Number to 1 
    const resetPageNumber = () => {
        setPage(1);
        setPaginationFilter({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
    }

    // Filtering the disable curves
    const onDisabled = (value: any) => {
        resetPageNumber();
        filterCriteria.IsActive = !value;
        // Assign state variable value to new variable to update with new state    
        let curveReferenceFilterCriteria: ICurveReferenceFilter = filterCriteria;
        setFilterCriteria(curveReferenceFilterCriteria);
        getCurveReferenceData();
    };
    // For rendering link in table
    const renderLinkValue = (value: any, record: any, index: number) => {
        return (<ButtonLink onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={() => {
            var productCurveReferenceData = curveReferenceData[index];
            //passing clone object to prevent the state change in original object
            const clone = JSON.parse(JSON.stringify(productCurveReferenceData));
            setCurveReference(clone);
            setOpen(true);

        }} >{value}</ButtonLink>)
    };

    // For rendering date value in table
    const renderDateAndTimeValue = (value: any, record: any, index: number) => {
        value = dayjs(value).format(PDSCONSTANT.DATE_FORMAT) + " | " + dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
        return (<div>{value}</div>)
    };

    // For rendering link in table
    const renderStatus = (value: any, record: any, index: number) => {
        value = (value === true ? BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] : BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Disable]);
        return (<div>{value}</div>)
    };
    // For Right Overlay state variable for Drawer component open state
    const [open, setOpen] = useState(false);
    const onClose = (isLoading: boolean = false, message: string = "") => {
        if (isLoading) {
            toast.success(message, { position: toast.POSITION.TOP_CENTER });
            getCurveReferenceData();
        }
        setOpen(false);
    };
    // for rendering Audit history button
    const renderAuditHistory = (value: any, record: any, index: number) => {
        return (permissions.CanRead_ReferenceData() && <ButtonLink onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={() => {
            var productCurveReferenceData = curveReferenceData[index];
            setCurveReference(productCurveReferenceData);
            setOpenAudit(true);

        }} >{<FileVersion />}</ButtonLink>)
    }
    // for opening audit drawer
    const [openAudit, setOpenAudit] = useState(false);
    const onCloseAudit = (isLoading: boolean = false, message: string = "") => {
        if (isLoading) {
            toast.success(message, { position: toast.POSITION.TOP_CENTER });
            getCurveReferenceData();
        }
        setOpenAudit(false);
    };
    // Passing render methods as a props in CurveReferenceColumnHelper
    const curveReferenceColumnProps: ICurveConfigurationColumnPropsExtensions = { renderCheckbox: renderCheckbox, renderLinkValue: renderLinkValue, renderValue: renderValue, handleCheckboxAll: handleCheckboxAll, headerCheckbox: headerCheckboxUI, renderStatus: renderStatus, renderDateAndTimeValue: renderDateAndTimeValue, renderButtonLink: renderAuditHistory };
    const curveReferenceColumns = CurveReferenceColumnsHelper(curveReferenceColumnProps);
    // For Sorting 
    const [transformSorterColumns, sortStates] = useSorter<RecordType>({
        prefixCls: TABLE_CLASS,
        columns: curveReferenceColumns,
    });
    useMemo(() => {
        const SortCriteriaResult: ISortCriteria[] = [];
        for (var sortNumber in sortStates) {
            if (Object.hasOwn(sortStates, sortNumber)) {
                const SortCriterion: ISortCriteria = {
                    ColumnName: '',
                    Direction: ''
                };
                if (sortStates[sortNumber].sortOrder !== undefined) {
                    SortCriterion.ColumnName = sortStates[sortNumber].column.dataIndex?.toString() ?? "";
                    SortCriterion.Direction = sortStates[sortNumber].sortOrder === 'ascend' ? SortDirection[SortDirection.DESC] : SortDirection[SortDirection.ASC];
                    SortCriteriaResult.push(SortCriterion);
                }
            }
        }
        setSortCriteria(SortCriteriaResult);
    }, [sortStates]);
    // For changing the header sort icons
    const transformColumns = useCallback(
        (innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> => transformSorterColumns(innerColumns),
        [transformSorterColumns],
    );
    // Passing the columns 
    const transformedColumns = transformColumns(curveReferenceColumns);
    return (
        <>
            {isProcessing && <Loader />}
            <CurveReferenceDrawer referenceAttributeName={referenceAttribute} onClose={onClose} open={open} selectedCurve={curveReference} />
            <CurveReferenceAuditDrawer selectedItem={curveReference} onClose={onCloseAudit} open={openAudit} ></CurveReferenceAuditDrawer>
            <Styled.InnerContainer>

                <Styled.ContentContainer>
                    <Styled.CenteredContainer>
                        <Styled.SideGrid>
                            <Styled.GridStyled columns={2}>
                                <Grid.Cell>
                                    <b>Source Provider</b>
                                </Grid.Cell>
                                <Grid.Cell>
                                    <Styled.FlexboxStyled gap='24px;'>
                                        {/* create will be allowed as per authorization */}
                                        {permissions.CanWrite_ReferenceData() && <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={createCurve} icon={<Add />}>Create Source Provider</Styled.ButtonStyled>}
                                        <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={downloadSelectedReferenceData} variant='outlined' icon={<Download />}>Export Data</Styled.ButtonStyled>
                                    </Styled.FlexboxStyled>
                                </Grid.Cell>
                            </Styled.GridStyled>
                            <Grid columns={1}>
                                <Grid.Cell>
                                    <hr />
                                </Grid.Cell>
                            </Grid>
                            <Styled.GridStyled columns={2} >
                                <Grid.Cell >
                                    <Styled.TextInputStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' size='small' icon={<Search height={24} />} placeholder="Search" onKeyUp={(e: any) => {
                                        // Fire event on enter press
                                        if (e.keyCode === 13) {
                                            resetPageNumber();
                                            setPage(1);
                                            paginationFilter.PageNumber = 1;
                                            // Setting the value enetered in search textbox
                                            filterCriteria.WildCardSearchText = e.target.value;
                                            // Assign state variable value to new variable to update with new state    
                                            let curveReferenceFilterCriteria: ICurveReferenceFilter = filterCriteria;
                                            // Set the filtercriteria with the updated text value
                                            setFilterCriteria(curveReferenceFilterCriteria);
                                            // Calling the GetProductCurveConfigurationData method for fetching
                                            getCurveReferenceData();
                                        }
                                    }} />
                                </Grid.Cell>

                            </Styled.GridStyled>
                            <Grid rowGap="24px">
                                <DataTableWithScrollBar data={curveReferenceData} columns={transformedColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} />
                                <DataTablePagination currentPage={paginationFilter.PageNumber} pageSize={paginationFilter.PageSize} total={paginationFilter.TotalRecords} setPage={setPage} onPageChange={handlePaginationFilter} />
                            </Grid>
                        </Styled.SideGrid>
                    </Styled.CenteredContainer>
                </Styled.ContentContainer>
            </Styled.InnerContainer>
            <ToastContainer />
        </>);
}




