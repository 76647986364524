import { PageLayout } from "@sede-x/glass-design-library";
import { Card } from "@sede-x/shell-ds-react-framework";
import exp from "constants";
import styled from "styled-components";

// Styling for Page Layout container
const LogOutMainContainer =styled.div`
height: 100vh ! important;
width: 100% ! important;
align-items: center!important;    /* aligns vertically */
justify-content: center !important;

`;

const LogOutContainer = styled.div`
background-color: ${({ theme }) => theme.background.surface};
margin-left:25%;

`;

const ImgStyled = styled.img `
height:80px;
margin-left:35%;
margin-top:10%
`


const CardStyled = styled(Card)`
margin-left:30%;
padding:5%;
align-items: center!important;
`;

export {LogOutContainer,LogOutMainContainer,CardStyled,ImgStyled}