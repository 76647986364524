import { Pagination } from "@sede-x/shell-ds-react-framework";
import styled from "styled-components";

// styling for Pagination
const PaginationStyled = styled(Pagination)`
li.shell-pagination-total-text {
    margin-right:9px;
    margin-top:9px;
  }
`;

export {PaginationStyled}