import styled, { css } from 'styled-components';
import { SectionHeader } from "@sede-x/glass-design-library";

// Styling SectionHeader
const SectionHeaderStyled = styled(SectionHeader)`
color: lightgray !important; 
padding :36px 420px !important; 

`;

export {SectionHeaderStyled}