import { AnyCnameRecord } from "dns";

// Helper class to download export
export const DownloadHelper =()=>
{     
    
    function AddZero(num:any) {
        return (num >= 0 && num < 10) ? "0" + num : num + "";
    }

    function getFormatedCSVFileName(fileName:string){
    
        var now = new Date();
        var strDateTime = [[AddZero(now.getDate()), 
            AddZero(now.getMonth() + 1), 
            now.getFullYear()].join("-"), 
            [AddZero(now.getHours()), 
            AddZero(now.getMinutes())].join(":")].join(" ");

            return `${fileName} - ${strDateTime}.csv` 

    }
    
    //method to export in csv format
   function ExportToCSV(fileName:string,data:any) {         
    
    //creat blob object from csv string
    const url = window.URL.createObjectURL(new Blob(['\uFEFF'+data]));
    fileName = getFormatedCSVFileName(fileName);

    //create link and use download property to download
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link);
    //start the download
    link.click();

   }

   return {
           
      ExportToCSV,
   };

}