import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./hooks/auth-provider";


interface Props {
    allowedRoles : string[]
}

//component to validate loggedIn user authorization in order to render the rout content
const RequireAuth = ({allowedRoles}:Props) => {   
    const { isAuthenticated,appUser } = useAuth();
    const location = useLocation(); 
    return (   
        //render the content if user is authenticated and authorise
        //rout to unauthorize page if not authorize
        //rount to signin page if not authenticated
        isAuthenticated() && appUser?.Roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : isAuthenticated()
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/signin" state={{ from: location }} replace />
             
    );
}

export default RequireAuth;