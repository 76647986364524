import { Filters } from "@sede-x/glass-design-library";
import { useEffect, useState, useCallback } from "react";
import { ICurveViewerFilterProps } from "../../interfaces/ICurveViewerFilterProps";
import { IMasterData } from "../../interfaces/IMasterData";
import { MasterData } from "../../services/MasterData";
import { dateFilterHelper } from "../../common/DateFilterHelper";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { PDSCONSTANT } from "../../common/constants";
import { IPDSFilterProps } from "../../interfaces/IPDSFilterProps";
dayjs.extend(weekday);
dayjs.extend(localeData);

//Using @sede-x/glass-design-library Filter component for filter component
export const CurveViewerFilter = (props: ICurveViewerFilterProps) => {
  // Initialise the state variable
  const { GetBooleanValueFilters } = MasterData();
  const [granularityData, setGranularityData] = useState<IMasterData[]>([]);
  const [curveTypeData, setCurveTypes] = useState<IMasterData[]>([]);
  const [sourceTypeData, setSourceTypes] = useState<IMasterData[]>([]);
  const [booleanTrueFalseData, setBooleanTrueFalseData] = useState<
    IMasterData[]
  >([]);
  const { GetGranularities, GetCurveTypes, GetSources } = MasterData();
  const granularities: IMasterData[] = [];
  const curvetypes: IMasterData[] = [];
  const sourceTypes: IMasterData[] = [];
  // DateFilterHelper methods
  const { getDefaultEffectiveDate, onChangeDateRange, resetDateRange } =
    dateFilterHelper();
  // Setting default date
  let effectiveDate = getDefaultEffectiveDate();
  const [effectiveStartDateRange, setEffectiveStartDateRange] = useState(
    dayjs(effectiveDate.EffectiveStartDate)
  );
  const [effectiveEndDateRange, setEffectiveEndDateRange] = useState(
    dayjs(effectiveDate.EffectiveEndDate)
  );
  // On change of date in Effective Date range or on resetting the date on dateRange
  const onChangeDate = (dates: { $d: dayjs.Dayjs | Date | null }[] | null) => {
    effectiveDate = onChangeDateRange(dates);
    setEffectiveStartDateRange(dayjs(effectiveDate.EffectiveStartDate));
    setEffectiveEndDateRange(dayjs(effectiveDate.EffectiveEndDate));
    filterValues["effectivedaterange"] = [
      dayjs(effectiveDate.EffectiveStartDate),
      dayjs(effectiveDate.EffectiveEndDate),
    ];
  };

  // Reset Effective Date Range filter
  const resetDate = () => {
    // Setting the effective start date range and effective end date range to filter values
    effectiveDate = resetDateRange();
    setEffectiveStartDateRange(dayjs(effectiveDate.EffectiveStartDate));
    setEffectiveEndDateRange(dayjs(effectiveDate.EffectiveEndDate));
    filterValues["effectivedaterange"] = [
      dayjs(effectiveDate.EffectiveStartDate),
      dayjs(effectiveDate.EffectiveEndDate),
    ];
  };
  // Represents the filter default values
  const filterValues: any = [];

  useEffect(() => {
    setBooleanTrueFalseData(GetBooleanValueFilters());
    // Setting Effective Date Range default values
    filterValues["effectivedaterange"] = [
      dayjs(effectiveStartDateRange),
      dayjs(effectiveEndDateRange),
    ];
  }, []);

  // Api calls for Filter dropdowns
  useEffect(() => {
    GetGranularities().then((result) => {
      for (var i in result.data) {
        if (result.data.hasOwnProperty(i)) {
          const granularity: IMasterData = {
            text: "",
            value: "",
          };

          granularity.text = result.data[i];
          granularity.value = result.data[i];
          granularities.push(granularity);
        }
      }
      setGranularityData(granularities);
    });

    GetCurveTypes().then((result) => {
      for (var i in result.data) {
        if (result.data.hasOwnProperty(i)) {
          const curvetype: IMasterData = {
            text: "",
            value: "",
          };

          curvetype.text = result.data[i];
          curvetype.value = result.data[i];
          curvetypes.push(curvetype);
        }
      }
      setCurveTypes(curvetypes);
    });

    GetSources().then((result) => {
      for (var i in result.data) {
        if (result.data.hasOwnProperty(i)) {
          const sources: IMasterData = {
            text: "",
            value: "",
          };

          sources.text = result.data[i];
          sources.value = result.data[i];
          sourceTypes.push(sources);
        }
      }
      setSourceTypes(sourceTypes);
    });

    // Find the Filter's reset button
    const resetButton = document.querySelector(
      PDSCONSTANT.RESET_BTN_IDENTIFIER
    ) as HTMLButtonElement;
    // Add click event on the reset button
    resetButton.addEventListener("click", resetDate, false);
  }, []);

  // Callback function on click of Filter Apply button
  const onApplyfunc = useCallback(
    (values: object) => onPDSApply(values),
    [effectiveStartDateRange, effectiveEndDateRange]
  );
  // Method to pass the filter criteria to Parentcomponent
  function onPDSApply(values: object) {
    if (Object.keys(values).length > 0) {
      // Cast the object to IPDSFilterProps
      const res = values as IPDSFilterProps;
      res.effectivedaterange[0] = effectiveStartDateRange;
      res.effectivedaterange[1] = effectiveEndDateRange;
      props.onApply(res);
    } else {
      filterValues["effectivedaterange"] = [
        effectiveStartDateRange,
        effectiveEndDateRange,
      ];
      props.onApply(filterValues);
    }
  }
  return (
    <Filters
      collapsable
      fields={[
        {
          label: "Name",
          name: "name",
          placeholder: "",
          type: "SEARCH",
        },
        {
          label: "Granularity",
          name: "granularity",
          options: granularityData,
          placeholder: "Please select",
          type: "MULTISELECTION",
        },
        {
          label: "Curve Type",
          name: "curvetype",
          options: curveTypeData,
          placeholder: "Please select",
          type: "MULTISELECTION",
        },
        {
          label: "Source",
          name: "sources",
          options: sourceTypeData,
          placeholder: "Please select",
          type: "MULTISELECTION",
        },
        {
          label: "Trader Approved",
          name: "traderapprovalindicator",
          options: booleanTrueFalseData,
          placeholder: "Please select",
          type: "SINGLE_SELECTION",
        },
        {
          label: "Data Complete",
          name: "iscomplete",
          options: booleanTrueFalseData,
          placeholder: "Please select",
          type: "SINGLE_SELECTION",
        },
        {
          label: "Effective Date Range",
          name: "effectivedaterange",
          placeholder: ["Start", "End"],
          type: "DATE_RANGE",
          onChange: onChangeDate,
          value: [effectiveStartDateRange, effectiveEndDateRange],
        },
      ]}
      defaultValues={filterValues}
      onApply={onApplyfunc}
    />
  );
};
