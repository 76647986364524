import { AxiosInstance } from "axios";
import { HTTPClient } from "../common/apiservice";
import { IAligneCurvePayload } from "../interfaces/IAligneCurvePayload";
import { APIURL } from "../common/api-url";
import { IAligneCurveConfigurationRequest } from "../interfaces/IAligneCurveConfigurationRequest";
import { HttpHeader } from "../common/HttpHeader";
import { useAuth } from "../common/hooks/auth-provider";
import { IAligneCurveExportFilter } from "../interfaces/IAligneCurveExportFilter";

// Service class for Aligne Curves Configuration
export const ManualAligneCurveExport = () => {

    const { appUser } = useAuth();
    //setup http header
    var config = HttpHeader().GetHeader(appUser?.user?.access_token, process.env.REACT_APP_HEADER_API_VERSION);
    // Instance of Axios to call get and post method
    const _httpclient: AxiosInstance = HTTPClient(config);
    // Method for fetching GetAligneExportCurves  data based on AligneCurveConfigurationFiletr payload
    async function GetAligneExportCurves(aligneCurveFilter: IAligneCurvePayload) {
        const result = await _httpclient.post(APIURL.ALIGNE_EXPORT_CURVE_URL, aligneCurveFilter);
        return result;
    }

    // Method for exporting the selected product curve to Aligne
    async function ExportSelectedProductCurveData(exportFilter:IAligneCurveExportFilter) {

        return await _httpclient.post(APIURL.ALIGNE_CURVE_EXPORT_DATA_URL, exportFilter);
    }

     // Method for fetching GetAligneExportCurvesForPopup data based on AligneCurveConfigurationFilter payload
     async function GetAligneExportCurvesForPopup(aligneCurveFilter: IAligneCurvePayload) {
        const result = await _httpclient.post(APIURL.ALIGNE_EXPORT_CURVE_POPUP_URL, aligneCurveFilter);
        return result;
    }

    return {
        GetAligneExportCurves,
        ExportSelectedProductCurveData,
        GetAligneExportCurvesForPopup
    };

}