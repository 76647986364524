import React from "react";
import { EnvironmentHelper } from "./environmentHelper"

//model to hold http header
export interface Header {
    //represent header key
    Key : string ;
    //represent header value
    Value : string | undefined ;   
  }

// service/util to prepare http header config
export const HttpHeader = ()  => 
{
    // Setting APIM subscription
    const APIM_SUBSCRIPTION = EnvironmentHelper().APIM_SUBSCRIPTION;
    
    //method to get header config
    function GetHeader(accessToken:string|undefined,apiVersion :string | undefined):Header[] {
                 
        const httpHeaders : Header[] = [

            {Key:'api-version',Value: apiVersion || "v1"},
            {Key:'Authorization',Value: `Bearer ${accessToken}`},
            {Key:'Cache-Control',Value: process.env.REACT_APP_HEADER_CACHE},            
            {Key:'Ocp-Apim-Subscription-Key',Value: APIM_SUBSCRIPTION}            
        
        ]
        return httpHeaders;
     }

     return {
        GetHeader         
       };
  }