import { EnvironmentHelper } from "./environmentHelper"
// Setting Base URL for API calls 
const BASE_URL = EnvironmentHelper().API_URL;
export const APIURL = {

    //URL for fetching product curves based on ProductCurvesFilter 
    CURVE_VIEWER_URL: BASE_URL + 'productcurves/paged',
    CURVES_VIEWER_URL: BASE_URL + 'productcurves',
    //URL for fetching granularities 
    GRANULARITIES_URL: BASE_URL + "productcurves/granularities",
    //URL for fetching curve types
    CURVETYPE_URL: BASE_URL + "productcurves/curvetypes",
    //URL for fetching sources
    SOURCES_URL: BASE_URL + "productcurves/sources",
    //URL for fetching product curves prices based on ProductCurvePriceFilter
    CURVE_VIEWER_PRICE_URL: BASE_URL + 'productcurves/prices',
    //URL for fetching product curves configuration filters
    PRODUCT_CURVE_CONFIGURATION_FILTERS_URL: BASE_URL + 'productcurves/configuration/filters',
    //URL for fetching product curves configuration 
    PRODUCT_CURVE_CONFIGURATION_URL: BASE_URL + 'productcurves/configuration/paged',
    //URL for fetching product curves configuration 
    CREATE_EDIT_PRODUCT_CURVE_CONFIGURATION_URL: BASE_URL + 'productcurves/configuration',
    //URL for downloading product curve and its prices based on curve unique Ids
    CURVE_VIEWER_DOWNLOAD_PRODUCT_DATA_URL: BASE_URL + 'productcurves/download',
    //URL for downloading product curves configuration based on curve names
    CURVE_CONFIGURATION_DOWNLOAD_PRODUCT_DATA_URL: BASE_URL + 'productcurves/configuration/download',
    //URL for product curves exists based on curve names
    CURVE_CONFIGURATION_EXIST_DATA_URL: BASE_URL + 'productcurves/configuration/curveexists',
    //URL for product curves exists based on curve names
    GET_PRODUCT_CURVE_CONFIGURATION: BASE_URL + 'productcurves/configuration/curveconfiguration',
    //URL for fetching aligne curves configuration filters
    ALIGNE_CURVE_CONFIGURATION_FILTERS_URL: BASE_URL + 'aligneexport/configuration/filters',
    //URL for fetching aligne curves configuration 
    ALIGNE_CURVE_CONFIGURATION_URL: BASE_URL + 'aligneexport/configuration/paged',
    //URL for fetching granularities 
    PRODUCT_CURVE_NAME_URL: BASE_URL + "productcurves/curvesname",
    //URL for inserting aligne curves 
    CREATE_EDIT_ALIGNE_CURVE_CONFIGURATION_URL: BASE_URL + 'aligneexport/configuration',
    //URL for downloading product curves configuration based on curve names
    ALIGNE_CURVE_CONFIGURATION_DOWNLOAD_DATA_URL: BASE_URL + 'aligneexport/configuration/download',

    //URL for fetching manual export  filters
    ALIGNE_MANUAL_EXPORT_FILTERS_URL: BASE_URL + 'aligneexport/manual/filters',

    // URL for Aligne Export Curves List
    ALIGNE_EXPORT_CURVE_URL: BASE_URL + 'aligneexport/manual',

    // URL for Aligne Export Curves trigger
    ALIGNE_CURVE_EXPORT_DATA_URL: BASE_URL + 'aligneexport/manual/trigger',

    // URL for Aligne Export Status Report Filter
    ALIGNE_EXPORT_STATUS_REPORT_FILTERS_URL: BASE_URL + 'aligneexport/status/filters',

    ALIGNE_EXPORT_REPORT_STATUS_PAGED_URL: BASE_URL + 'aligneexport/status/report/paged',
    // URL for Aligne Response Paged
    ALIGNE_RESPONSE_PAGED_URL: BASE_URL + 'aligneexport/response/paged',
    // URL for Aligne Export Status Report Download
    ALIGNE_EXPORT_REPORT_DOWNLOAD_DATA_URL: BASE_URL + 'aligneexport/status/report/download',
    // URL for Aligne Export Curve Popup
    ALIGNE_EXPORT_CURVE_POPUP_URL: BASE_URL + 'aligneexport/manual/exportcurves',

     // URL for Aligne Export Status Report Edit
     ALIGNE_EXPORT_REPORT_UPDATE_DATA_URL: BASE_URL + 'aligneexport/status/update',
    //endpoint to get users
    SECURITY_GET_USER: BASE_URL + 'users/',
    //endpoint to get pds roles    
    SECURITY_GET_ROLES: BASE_URL + 'roles/',
    //URL for fetching curve meta data 
    CURVE_META_DATA_ATTRIBUTE_URL: BASE_URL + "productcurves/referenceattributes/paged",
    //URL for saving reference data
    CREATE_REFERENCE_DATA_URL: BASE_URL + 'productcurves/referenceAttributes/save',
    //URL for downloading reference data
    DOWNLOAD_REFERENCE_DATA_URL: BASE_URL + 'productcurves/referenceAttributes/download',
    // URL for downloading reference timezone data
    DOWNLOAD_REFERENCE_TIMEZONE_DATA_URL: BASE_URL + 'productcurves/referenceAttributes/timezones/download',
    //URL  for fetching timezone reference data paged
    TIMEZONE_META_DATA_URL: BASE_URL + 'productcurves/referenceAttributes/timezone/paged',
    //URL for updating timezone data
    UPDATE_REFERENCE_DATA_TIMEZONE_URL: BASE_URL + 'productcurves/referenceAttributes/timezone/update',
    //endpoint to get list of calendars
    CALENDARDAY_FILTERED_LIST_URL: BASE_URL + 'productcurves/calendars/paged',
    //endpoint to get list of calendars
    CALENDARDAY_LIST_URL: BASE_URL + 'productcurves/calendars/list',
    //endpoint to update calendar
    CALENDARDAY_SAVE_URL: BASE_URL + 'productcurves/calendars/save',
    //endpoint to delete a calendar Day
    CALENDARDAY_DELETE_URL: BASE_URL + 'productcurves/calendars/deleteCalendarDay',
    //Endpoint to get list of calendar names
    CALENDAR_NAMES_URL: BASE_URL + 'productcurves/calendars/Names',
    //Endpoint to download calendar days as csv
    CALENDAR_DOWNLOAD_URL: BASE_URL + 'productcurves/calendars/download',
    //URL for fetching curve meta data 
    CURVE_REFERENCE_ATTRIBUTE_AUDIT_HISTORY_URL: BASE_URL + "productcurves/referenceAttributeAuditHistory",
}



