import { useEffect,useState } from "react";
import { IDropDownOptions } from "../../interfaces/IDropDownOptions";
import { DropdownStyled } from './Dropdown.style';
import styled from "styled-components";
import { Select, Option } from "@sede-x/shell-ds-react-framework";
// Dropdown 
export default function Dropdown(dropDownProperties:any) {  
  
   // Dropdown options array    
    const dropDownOptions:IDropDownOptions[]=[];
    // disabledDrpDwn properties exist in Dropdownproperties then set the disabledDrpDwn else set to false
    const disabled=dropDownProperties.disabledDrpDwn!==undefined?dropDownProperties.disabledDrpDwn:false;
   
    // get selected data in case of set the value of dropdown
    const selectedData=dropDownProperties.selectedData?dropDownProperties.selectedData:'';
    // Check whether the value is selected or not
    const isSelectedValue =dropDownProperties.selectedData?true:false;
   

     const onDropdownChange=(value:any)=>{   
      dropDownProperties.onDropDownChange(dropDownProperties.id,value);
     }
     // If value is there render the section. Needs to find the proper way to implement it
     if(isSelectedValue)
      {
     return (          
        <DropdownStyled 
        placeholder= "Select"
        size="medium"
        value={selectedData} 
        disabled={disabled}
        onChange={(e) =>           
          onDropdownChange(e)
        }>
          {dropDownProperties.masterData.map((i:IDropDownOptions, _index:number) => (
            <Option key={`${i.value}`} value={i.value}>
              {i.text}
            </Option>
          ))}
      </DropdownStyled>
     )
      }
      else
      {
        return (   
        <DropdownStyled      
      placeholder= "Select"
      size="medium"
      value={selectedData} 
      disabled={disabled}
      onChange={(e) =>           
          onDropdownChange(e)
        }
    >
      {dropDownProperties.masterData.map((i:IDropDownOptions, _index:number) => (
        <Option key={`${i.value}`} value={i.value}>
          {i.text}
        </Option>
      ))}
    </DropdownStyled>
        )
      }      
}