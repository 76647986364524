import { Button } from "@sede-x/shell-ds-react-framework";
import { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../common/hooks/auth-provider";
import { AligneMapping } from "../components/AligneMapping/AligneMappingMain";
import { CurveConfiguration } from "../components/CurveConfiguration/CurveConfigurationMain";
import { CurveViewer } from "../components/CurveViewer/CurveViewerMain";
import { ManualAligneExport } from "../components/ManualAligneExport/ManualAligneExportMain";
import { AligneExportReport } from "../components/AligneExportReport/AligneExportReportMain";
import { CalendarConfiguration } from "../components/CalendarConfiguration/CalendarConfigurationMain";
import { CurveGranularity } from "../components/CurveMetaDataAttribute/CurveGranularity/CurveGranularityMain";
import { MainContainer } from "./AppLayout.styles";
import routepaths from "../router/routepaths";
import { useIdleTimer } from 'react-idle-timer';
import { LogOut } from "../components/Logout/LogOut";
import { Header } from "../components/Header";
import RequireAuth from "../common/RequireAuth";
import { ROLES } from "../common/enum";
import { Unauthorized } from "../components/pages/UnAuthorized";
import { UserPermission } from "../services/UserPermission";
import { CurveType } from "../components/CurveMetaDataAttribute/CurveType/CurveTypeMain";
import { PriceType } from "../components/CurveMetaDataAttribute/PriceType/PriceTypeMain";
import { Currency } from "../components/CurveMetaDataAttribute/Currency/CurrencyMain";
import { Unit } from "../components/CurveMetaDataAttribute/Unit/UnitMain";
import { CurveLocation } from "../components/CurveMetaDataAttribute/Location/LocationMain";
import { Commodity } from "../components/CurveMetaDataAttribute/Commodity/CommodityMain";
import { Profile } from "../components/CurveMetaDataAttribute/Profile/ProfileMain";
import { DataProvider } from "../components/CurveMetaDataAttribute/DataProvider/DataProviderMain";
import { CommodityDeliveryPoint } from "../components/CurveMetaDataAttribute/CommodityDeliveryPoint/CommodityDeliveryPointMain";
import { SourceProvider } from "../components/CurveMetaDataAttribute/SourceProvider/SourceProviderMain";
import { Timezone } from "../components/CurveMetaDataAttribute/Timezone/Timezone";


// Layout container. All the child component loads into this base component
export const AppLayout = () => {
  // Intialize the state variable
  // user details, sign-in method, and isAuthenticated is fetched from useAuth hook
  // User details contains Access token, User name 
  // isAuthenticated method to find whether the user is logged in or not
  // signin  method for  the first time login  
  const { isAuthenticated, signin, appUser } = useAuth();
  // isLoggedIn to find whether the user is logged-in
  const isLoggedIn = isAuthenticated();
  // Session timeout time
  const timeout = parseInt(process.env.REACT_APP_SESSIONEXPIRATIONTIME ?? "0") * 1000;
  // Set statae for Idle bool for session timeout page to load
  const [isIdle, setIsIdle] = useState(false);
  // user full name
  const [fullName, setFullName] = useState('');
  // user initial
  const [shortName, setShortName] = useState('');
  // user email address
  const [emailAddress, setEmailAddress] = useState('');

  // On Session time out
  const OnIdle = () => {
    setIsIdle(true)
  }

  // Idle timer 
  useIdleTimer({
    timeout,
    onIdle: OnIdle
  })

  useEffect(() => {
    // Redirect to login      
    if (isLoggedIn) {

      // For setting the user full name on the top navbar
      if (appUser?.user?.profile.FirstName !== undefined) {
        setFullName(`${appUser?.user?.profile.FirstName} ${appUser?.user?.profile.LastName}`);
        setShortName(appUser?.user?.profile.FirstName.charAt(0).toUpperCase());
      }
      else {
        setFullName(`${appUser?.user?.profile.givenName} ${appUser?.user?.profile.last_name}`);
        setShortName(appUser?.user?.profile.givenName.charAt(0).toUpperCase());
      }
      setEmailAddress(appUser?.user?.profile.sub ?? '');
    }
    // If not logged in redirect to Login page
    var homeUrl = process.env.REACT_APP_HOME_URL
    if (!isLoggedIn) {
      signin({ returnTo: window.location.pathname });
    }

  }, [isLoggedIn]);

  const permissions = UserPermission();
  const location = useLocation();
  //check if rout path is valid
  const isValidRout = Object.values(routepaths).includes(location.pathname);

  // On Idle state for the configured time session will be timedout
  if (!isIdle) {
    return (
      <>
        {isLoggedIn &&
          (
            <MainContainer>
              {/* load  header only for authorized user and valid rout */}
              {!permissions.UnAuthorizeAccess() && isValidRout && <Header emailAddress={emailAddress} shortname={shortName} fullname={fullName} />}
              <MainContainer>
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path="/" element={<CurveViewer />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curveviewer} element={<CurveViewer />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curveconfiguration} element={<CurveConfiguration />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.alignemapping} element={<AligneMapping />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.manualaligneexport} element={<ManualAligneExport />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.aligneexportreport} element={<AligneExportReport />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.calendarconfiguration} element={<CalendarConfiguration />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curvegranularityattribute} element={<CurveGranularity />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curvetypeattribute} element={<CurveType />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curvepricetypeattribute} element={<PriceType />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curvecurrencyattribute} element={<Currency />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curveunitattribute} element={<Unit />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curvelocationattribute} element={<CurveLocation />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curvecommodityattribute} element={<Commodity />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curvedeliverypointattribute} element={<CommodityDeliveryPoint />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curveprofileattribute} element={<Profile />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curvedataproviderattribute} element={<DataProvider />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.curvesourceproviderattribute} element={<SourceProvider />} />
                    </Route>
                    <Route element={<RequireAuth allowedRoles={[ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly]} />}>
                      <Route path={routepaths.timezoneidentifierattribute} element={<Timezone />} />
                    </Route>
                    {/* uncommnet if - render page with empty content */}
                    {/* <Route path="*" element={<EmptyStateComp/>} /> */}
                    <Route path="*" element={<Unauthorized />} />

                  </Routes>
                </Suspense>
              </MainContainer>
            </MainContainer>
          )
        }
      </>
    );
  }
  else {
    return (
      <>
        <LogOut></LogOut>
      </>
    );
  }
};
