import { IDataTableProps } from '../../interfaces/ITableProps';
import * as Styled from './Table.styles';

// Styling of Table component of shell-ds-react-framework
export default function DataTableWithVerticalScrollBar(tableProps:IDataTableProps) {
  
  return (  
    <Styled.PopupContainer>
    <Styled.DataTableStyled  data={tableProps.data} columns={tableProps.columns}  rowKey={tableProps.rowKey} size='small' emptyText="No Data" />
    </Styled.PopupContainer>
  );
}