import { Grid } from '@sede-x/shell-ds-react-framework';
import { IDataTablePaginationProps } from '../../interfaces/IDataTablePaginationProps';
import { IPaginationFilter } from '../../interfaces/IPaginationFilter';
import { PaginationStyled } from './Pagination.style';

// Styling of Pagination component of shell-ds-react-framework
export default function DataTablePagination(props:IDataTablePaginationProps) { 
     
  const paginationFilter:IPaginationFilter={PageNumber:0,PageSize:0,TotalRecords:0}; 
  const pageSizeTitle:string = props.total>1?`Total ${props.total} items`:`Total ${props.total} item`;
  // on change of page number update current page 
  const handleOnChange = (current: number, pageSize: number): void => { 
    paginationFilter.PageNumber=current;
    paginationFilter.PageSize=pageSize;
    paginationFilter.TotalRecords=props.total;
    props.setPage(current); 
    props.onPageChange(paginationFilter);
       };
  return (      
    <Grid columns={1} >    
    <Grid.Cell>
    <PaginationStyled current={props.currentPage} showTotal={(total, range) => pageSizeTitle} showQuickJumper={true} total={props.total} pageSize={props.pageSize} onChange={handleOnChange}/>
    </Grid.Cell>
  </Grid>
    
  );
}


