import { useCallback, useEffect, useMemo, useState } from 'react';
import Loader from '../../library/Loader/Loader';
import { ButtonLink, Checkbox, ColumnsType, Grid, RecordType, useSorter } from '@sede-x/shell-ds-react-framework';
import DataTableWithScrollBar from '../../library/Table/TableWithScrollBar';
import DataTablePagination from '../../library/Pagination/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import { TABLE_CLASS } from '@sede-x/shell-ds-react-framework/build/cjs/utils/constants';
import { Add, Download, Search } from '@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components';

import * as Styled from './CalendarConfiguration.style';
import { FilterContainer, FilterStyled } from './CalendarConfiguration.style';
import { ICalendarDay } from '../../interfaces/ICalendarDay';
import { ICalendarDayDownloadFilter } from '../../interfaces/ICalendarDayDownloadFilter';
import { CalendarConfigurations } from '../../services/CalendarConfigurations';
import { ICalendarDayColumnProps } from '../../interfaces/ICalendarDayColumnProps';
import { CalendarConfigurationColumnsHelper } from './CalendarConfigurationColumnsHelper';
import { ICalendarDayPayload } from '../../interfaces/ICalendarDayPayload';
import { ICalendarDayFilter } from '../../interfaces/ICalendarDayFilter';
import CalendarConfigurationSideDrawer from './CalendarConfigurationSideDrawer';

import { IPaginationFilter } from '../../interfaces/IPaginationFilter';
import { PDSCONSTANT } from '../../common/constants';
import { UserPermission } from '../../services/UserPermission';
import { ISortCriteria } from '../../interfaces/ISortCriteria';
import dayjs from 'dayjs';
import { BtnGrpEnableDisableStatus, SortDirection } from '../../common/enum';
import { DownloadHelper } from '../../common/DownloadHelper';

export const CalendarConfiguration = () => {
    //object containing user permissons
    const permissions = UserPermission();
    // React state variables  
    const defaultPageSize = PDSCONSTANT.DEFAULT_PAGE_SIZE;
    const [currentPage, setPage] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);

    const { GetCalendarDaysFiltered, GetCalendarNames, DownloadSelectedCalendarDayData } = CalendarConfigurations();

    const [wildCardSearchText, setWildCardSearchText] = useState('');
    const [filterCriteria, setFilterCriteria] = useState<ICalendarDayFilter>({ Name: "", Description: "", IsActive: null, WildCardSearchText: '' });
    const [paginationFilter, setPaginationFilter] = useState<IPaginationFilter>({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
    const [sortCriteria, setSortCriteria] = useState<ISortCriteria[]>([]);
    const calendarPayload: ICalendarDayPayload = {
        WildCardSearchText: wildCardSearchText,
        FilterCriteria: filterCriteria,
        PaginationFilter: paginationFilter,
        SortCriteria: sortCriteria
    }


    const [editCalendarDay, setEditCalendarDay] = useState<ICalendarDay>({ _id: "", Date: dayjs(), Description: '', Name: "", DisplayName: "", IsActive: true, IsChecked: false });



    const [CalendarDaysFilter, setCalendarDaysFilter] = useState<any>();
    const [calendarDayData, setCalendarDayData] = useState<ICalendarDay[]>([]);
    //used for maintain and send the selectAll Status to backend api
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    //use to maintain the header checkbox check/uncheck status at UI only, no role in backend selectAll Status
    const [headerCheckboxUI, setHeaderCheckboxUI] = useState(false);
    let [selectedData, setSelectedData] = useState<string[]>([]);
    let [unSelectedData, setUnSelectedData] = useState<string[]>([]);
    //initialize to default
    const downloadFilter: ICalendarDayDownloadFilter = {
        CalendarDayFilter: filterCriteria,
        IsAllSelected: headerCheckbox,
        SelectedCalendarDayList: selectedData,
        UnSelectedCalendarDayList: unSelectedData
    }

    //On load manual export filters on load
    useEffect(() => {
        GetCalendarDayFilters();
    }, []);

    useEffect(() => {
        // Set Page Size on the basis of  Window size 
        paginationFilter.PageSize = window.innerHeight ? parseInt((window.innerHeight / 70).toFixed()) : 10;
        // Fetch the calendar Day data on load and on change of calendar filter payload
        GetCalendarDayData();
    }, [wildCardSearchText, filterCriteria, paginationFilter, sortCriteria]);

    // For Right Overlay state variable for Drawer component open state
    const [open, setOpen] = useState(false);
    const onClose = (isLoading: boolean = false, message: string = "") => {
        if (isLoading) {
            toast.success(message, { position: toast.POSITION.TOP_CENTER });
            GetCalendarDayData();
            GetCalendarDayFilters();
        }
        setOpen(false);
    };
    //Set the Page Number to 1 
    const resetPageNumber = () => {
        setPage(1);
        setPaginationFilter({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
    }

    const GetCalendarDayFilters = () => { GetCalendarNames().then(result => { setCalendarDaysFilter(result.data) }); }

    // For creation of calendar open Side drawer
    const createCalendar = () => {
        setEditCalendarDay({ Date: dayjs(), Description: "", Name: "", DisplayName: "", _id: "", IsActive: true, IsChecked: false });
        setOpen(true);
    }

    // For rendering date value in table
    const renderDateAndTimeValue = (value: any, record: any, index: number) => {
        value = dayjs(value).format(PDSCONSTANT.DATE_FORMAT) + " | " + dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
        return (<div>{value}</div>)
    };


    // For rendering link in table
    const renderLinkValue = (value: any, record: any, index: number) => {
        return (<ButtonLink onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size='small' placeholder='' onClick={() => {
            var dayData = calendarDayData[index];
            //passing clone object to prevent the state change in original object
            const clone = JSON.parse(JSON.stringify(dayData));
            setEditCalendarDay(clone);
            setOpen(true);
        }} >{value}</ButtonLink>)
    };

    // For rendering  date value in table
    const renderDateValue = (value: any, record: any, index: number) => {
        value = dayjs(value).format(PDSCONSTANT.DATE_FORMAT);
        return (<div>{value}</div>)
    };
    // For rendering string value in table
    const renderValue = (value: any, record: any, index: number) => {
        return (<div>{value}</div>)
    };
    // For rendering status in table
    const renderStatus = (value: any, record: any, index: number) => {
        value = (value === true ? BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] : BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Disable]);
        return (<div>{value}</div>)
    };

    // For rendering check box all functionality 
    const handleCheckboxAll = () => {
        // In case of clicking on select/unselect all check box render the curve data again    
        const checkedCalendarDayData = calendarDayData.map((item) => ({
            ...item,
            IsChecked: !headerCheckbox,
        }));
        // Setting the curve data again
        setCalendarDayData(checkedCalendarDayData);
        // Populate the array with checked curve names
        const selectedCurveData = checkedCalendarDayData.filter((obj) => {
            return obj.IsChecked === true;
        }).map(a => a._id);

        //clear checkbox selection list as all is selected
        setSelectedData([]);
        setUnSelectedData([]);
        // Setting the header checkbox on click 
        setHeaderCheckbox(!headerCheckbox);
        setHeaderCheckboxUI(!headerCheckbox);
    };

    // handle child checkbox selection when header is checked
    const handleChildSelectionWhenHeaderIsChecked = (currentSelection: any) => {

        if (!currentSelection.IsChecked) {
            unSelectedData.push(currentSelection._id);
            //also uncheck the global UI checkbox
            setHeaderCheckboxUI(false);
        }
        else {
            unSelectedData = unSelectedData.filter(function (item) {
                return item !== currentSelection.CurveName
            });
            //checked header check box checked, if no unchecked
            if (unSelectedData.length === 0) {
                setHeaderCheckboxUI(true);
            }
        }
        // Assign state variable value to new variable to update with new state    
        let unselectedCurveConfigurationData: string[] = unSelectedData;
        //clear the selected list
        setUnSelectedData(unselectedCurveConfigurationData);
        setSelectedData([]);
    };


    // For rendering checkbox in table  
    const renderCheckbox = (value: any, record: any, index: number) => {
        //Handle the changes on check or uncheck of a particular row checkbox
        const handleChange = () => {
            // Update the particular row checked/unchecked status 
            const selectCalendarData = [
                ...calendarDayData.slice(0, index),
                {
                    ...calendarDayData[index],
                    IsChecked: !calendarDayData[index].IsChecked,
                },
                ...calendarDayData.slice(index + 1),
            ];
            // Set the updated calendar status 
            setCalendarDayData(selectCalendarData);

            var currentSelection = selectCalendarData[index];

            //handle checkbox selection when header is checked/unchecked
            if (headerCheckbox) {
                //handle child checkbox selection when header checkbox is unchecked   
                //re-factored separate method to reduce congnative complexity - sonar issue fix
                handleChildSelectionWhenHeaderIsChecked(currentSelection);
            }
            else {
                //handle when header checkbox is unchecked

                if (currentSelection.IsChecked) {
                    selectedData.push(currentSelection._id);
                }
                else {
                    selectedData = selectedData.filter(function (item) {
                        return item !== currentSelection._id
                    });
                    setHeaderCheckboxUI(false);
                }

                //if all item selected -> checked header checkbox
                if (selectedData.length === paginationFilter.TotalRecords && unSelectedData.length === 0) {
                    setHeaderCheckboxUI(true);
                }
                // Assign state variable value to new variable to update with new state    
                let selectedCalendarConfigurationData: string[] = selectedData;
                //clear the selected list
                setSelectedData(selectedCalendarConfigurationData);
                setUnSelectedData([]);
            }

        };
        // Rendering the checkbox for row            
        return <Checkbox crossOrigin='false' label="" size='small' id={index.toString()} checked={value} onChange={handleChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />;
    };

    // reset checkbox selections
    const resetCheckboxSelection = () => {
        //clear all selection and headerCheck to false
        setSelectedData([]);
        setUnSelectedData([]);
        // Setting the header checkbox on click 
        setHeaderCheckbox(false);
    };

    // Passing render methods as a props in CurveConfigurationColumnHelper
    const calendarDayColumnProps: ICalendarDayColumnProps = { renderCheckbox: renderCheckbox, renderLinkValue: renderLinkValue, renderValue: renderValue, renderDateValue: renderDateValue, handleCheckboxAll: handleCheckboxAll, headerCheckbox: headerCheckboxUI, renderStatus: renderStatus, renderDateAndTimeValue: renderDateAndTimeValue };
    const calendarConfigurationColumns = CalendarConfigurationColumnsHelper(calendarDayColumnProps);

    // Method for Download calendar data
    const downloadSelectedCalendarData = () => {

        //show error message if no curve is selected to download
        if (!downloadFilter.IsAllSelected && downloadFilter.SelectedCalendarDayList.length === 0) {
            toast.error("No curve selected to download!", { position: toast.POSITION.TOP_CENTER });
            return;
        }
        // API calls for download selected calendar day data 
        DownloadSelectedCalendarDayData(downloadFilter).then(result => {
            //call to helper to download
            DownloadHelper().ExportToCSV(PDSCONSTANT.EXPORT_FILENAME_CALENDARDAYDATA, result.data);
        });
    }
    // Api calls for GetCalendarData
    const GetCalendarDayData = () => {
        // Comment for Loader - To be built    
        setCalendarDayData([]);
        setIsProcessing(true);
        GetCalendarDaysFiltered(calendarPayload).then(result => {

            //maintain the checked status of current page on pagination
            let currentPageData: any = result.data.Data;
            currentPageData.map((calendarDay: any) => {



                return calendarDay;
            });


            //set the grid data
            setCalendarDayData(currentPageData);
            setIsProcessing(false);
            paginationFilter.TotalRecords = result.data.PaginationFilter.TotalRecords;
            paginationFilter.PageNumber = currentPage;
            paginationFilter.PageSize = result.data.PaginationFilte.PageSize;
        }).catch((err) => {
            setIsProcessing(false);
        });
    }

    // Call this method on click of Apply in filters
    const handleFilterCriteria = (filterValues: any) => {
        resetPageNumber();
        if (filterValues['calendars'] == undefined || filterValues['calendars'] == null)
            filterCriteria.Name = '';
        else
            filterCriteria.Name = filterValues['calendars'];
        filterCriteria.IsActive = null; //set the default value
        if (filterValues['isActive'] !== null && filterValues['isActive'] !== undefined) {
            const selection = filterValues['isActive'];
            filterCriteria.IsActive = selection === 'TRUE' ? true : false;
        }

        // Assign state variable value to new variable to update with new state    
        let calendarConfigurationFilterCriteria: ICalendarDayFilter = filterCriteria;

        setFilterCriteria(calendarConfigurationFilterCriteria);

        //clear previous checked status on filter search
        resetCheckboxSelection();
        GetCalendarDayData();
    }


    // For Sorting 
    const [transformSorterColumns, sortStates] = useSorter<RecordType>({
        prefixCls: TABLE_CLASS,
        columns: calendarConfigurationColumns,
    });
    useMemo(() => {
        const SortCriteriaResult: ISortCriteria[] = [];
        for (var sortNumber in sortStates) {
            if (Object.hasOwn(sortStates, sortNumber)) {
                const SortCriterion: ISortCriteria = {
                    ColumnName: '',
                    Direction: ''
                };
                if (sortStates[sortNumber].sortOrder !== undefined) {
                    SortCriterion.ColumnName = sortStates[sortNumber].column.dataIndex?.toString() ?? "";
                    SortCriterion.Direction = sortStates[sortNumber].sortOrder === 'ascend' ? SortDirection[SortDirection.DESC] : SortDirection[SortDirection.ASC];
                    SortCriteriaResult.push(SortCriterion);
                }
            }
        }
        setSortCriteria(SortCriteriaResult);
    }, [sortStates]);

    // For changing the header sort icons
    const transformColumns = useCallback(
        (innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> => transformSorterColumns(innerColumns),
        [transformSorterColumns],
    );
    // Passing the columns 
    const transformedColumns = transformColumns(calendarConfigurationColumns);
    // On Page Change updated the Pagination filter
    const handlePaginationFilter = (paginationData: IPaginationFilter) => {
        setPaginationFilter(paginationData);
    }

    return (
        <>
            {isProcessing && <Loader />}
            <CalendarConfigurationSideDrawer onClose={onClose} open={open} {...CalendarDaysFilter} selectedCalendarDay={editCalendarDay} />

            <Styled.InnerContainer>
                <Styled.Sidebar>
                    <FilterContainer><FilterStyled onApply={handleFilterCriteria} masterData={CalendarDaysFilter}></FilterStyled></FilterContainer>
                </Styled.Sidebar>
                <Styled.ContentContainer>
                    <Styled.CenteredContainer>
                        <Styled.SideGrid>
                            <Styled.GridStyled columns={2}>
                                <Grid.Cell>
                                    <b>Calendar Configuration</b>
                                </Grid.Cell>
                                <Grid.Cell>
                                    <Styled.FlexboxStyled gap='24px;'>
                                        {/* create will be allowed as per authorization */}
                                        {permissions.CanWrite_CalendarData() && <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' onClick={createCalendar} icon={<Add />}>Create Calendar Day</Styled.ButtonStyled>}
                                        <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size='small' placeholder="" onClick={downloadSelectedCalendarData} variant='outlined' icon={<Download />}>Export Data</Styled.ButtonStyled>

                                    </Styled.FlexboxStyled>
                                </Grid.Cell>
                            </Styled.GridStyled>
                            <Grid columns={1}>
                                <Grid.Cell>
                                    <hr />
                                </Grid.Cell>
                            </Grid>
                            <Styled.GridStyled columns={2} >
                                <Grid.Cell >
                                    <Styled.TextInputStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' size='small' icon={<Search height={24} />} placeholder="Search" onKeyUp={(e: any) => {
                                        // Fire event on enter press
                                        if (e.keyCode === 13) {
                                            resetPageNumber();
                                            setPage(1);
                                            paginationFilter.PageNumber = 1;
                                            // Setting the value enetered in search textbox
                                            filterCriteria.WildCardSearchText = e.target.value;
                                            setWildCardSearchText(filterCriteria.WildCardSearchText ?? "")
                                            // Assign state variable value to new variable to update with new state    
                                            let calendarConfigurationFilterCriteria: ICalendarDayFilter = filterCriteria;
                                            // Set the filtercriteria with the updated text value
                                            setFilterCriteria(calendarConfigurationFilterCriteria);
                                            // Calling the GetProductCurveConfigurationData method for fetching
                                            GetCalendarDayData();
                                        }
                                    }} />
                                </Grid.Cell>
                            </Styled.GridStyled>
                            <Grid rowGap="24px">
                                <DataTableWithScrollBar data={calendarDayData} columns={transformedColumns} rowKey={PDSCONSTANT.CURVE_VIEWER_TABLE_ROW_KEY} />
                                <DataTablePagination currentPage={paginationFilter.PageNumber} pageSize={paginationFilter.PageSize} total={paginationFilter.TotalRecords} setPage={setPage} onPageChange={handlePaginationFilter} />
                            </Grid>
                        </Styled.SideGrid>
                    </Styled.CenteredContainer>
                </Styled.ContentContainer>
            </Styled.InnerContainer>
            <ToastContainer />
        </>);
}