import { Checkbox, ColumnsType, RecordType,Tooltip,Text } from "@sede-x/shell-ds-react-framework";
import { PDSCONSTANT } from "../../common/constants";
import { ICurveConfigurationColumnProps } from "../../interfaces/ICurveConfigurationColumnProps";
import { HeaderText } from "../CurveViewer/CurveViewerMain.styles";
import React from "react";

// Columns for Aligne mapping Table
export const AligneMappinColumnsHelper=(props:ICurveConfigurationColumnProps):ColumnsType<RecordType>=>
{   const alignecurveConfigurationColumns:ColumnsType<RecordType>= [    
    { dataIndex: 'IsChecked',
      key: 'IsChecked',      
      render: props.renderCheckbox, 
      title: <Checkbox crossOrigin='false' size='small' label="" checked={props.headerCheckbox}  onChange={props.handleCheckboxAll} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/> },
    { dataIndex: 'CurveName',
      key: 'CurveName',
      title: 'Name',     
      sorter: {
        compare: (a, b): number => {
          if (a.CurveName !== b.CurveName) {
            return a.CurveName < b.CurveName ? -1 : 1;
          } 
          return 0;
        }, 
      },
      sortDirections: ['ascend', 'descend'],        
      render: props.renderLinkValue},
    { dataIndex: 'Market',
      key: 'Market',
      title: 'MARKET',
      sorter: {
        compare: (a, b): number => {
          if (a.Market !== b.Market) {
            return a.Market < b.Market ? -1 : 1;
          } 
          return 0;
        },
      },         
      sortDirections: ['ascend', 'descend'],            
      render: props.renderValue },
    { dataIndex: 'Component',
      key: 'Component',
      title: 'COMPONENT', 
      sorter: {
        compare: (a, b): number => {
          if (a.Component !== b.Component) {
            return a.Component < b.Component ? -1 : 1;
          } 
          return 0;
        },
      },     
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
    { dataIndex: 'PSET',
      key: 'PSET',
      title: 'PSET', 
      sorter: {
        compare: (a, b): number => {
          if (a.PSET !== b.PSET) {
            return a.PSET < b.PSET ? -1 : 1;
          } 
          return 0;
        }, 
      },      
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue },
    { dataIndex: 'ZeroCal',
      key: 'ZeroCal',
      title: '0CAL', 
      sorter: {
        compare: (a, b): number => {
          if (a.ZeroCal !== b.ZeroCal) {
            return a.ZeroCal < b.ZeroCal ? -1 : 1;
          } 
          return 0;
        },  
      },       
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
      { dataIndex: 'AddToMarket',
      key: 'AddToMarket',
      title: 'Add To Market', 
      sorter: {
        compare: (a, b): number => {
          if (a.AddToMarket !== b.AddToMarket) {
            if(a.AddToMarket < b.AddToMarket){
              return -1;
            }              
            else{
              return 1;         
            }               
          } 
          return 0;
        },  
      },       
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
    {dataIndex: 'AligneImportType',
      key: 'AligneImportType',
      title: 'Aligne Import Type', 
      sorter: {
        compare: (a, b): number => {         
          if (a.AligneImportType !== b.AligneImportType) {
            return a.AligneImportType < b.AligneImportType ? -1 : 1;
          } 
          return 0;
        },  
      },     
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue },
    {dataIndex: 'Settle',
      key: 'Settle',
      title: 'Settle',     
      sorter: {        
       
      }, 
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
    {dataIndex: 'ContractOption',
      key: 'ContractOption',
      title: 'PUTCALL',  
      sorter: {
        compare: (a, b): number => {
          if (a.ContractOption !== b.ContractOption) {
            return a.ContractOption < b.ContractOption ? -1 : 1;
          } 
          return 0;
        },
      },    
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
    {dataIndex: 'SecondaryMarket',
      key: 'SecondaryMarket',
      title: 'MARKET2',  
      sorter: {
        compare: (a, b): number => {
          if (a.SecondaryMarket !== b.SecondaryMarket) {
            return a.SecondaryMarket < b.SecondaryMarket ? -1 : 1;
          } 
          return 0;
        }, 
      },    
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
    {dataIndex: 'SecondaryComponent',
      key: 'SecondaryComponent',
      title: 'COMPONENT2',  
      sorter: {
        compare: (a, b): number => {
          if (a.SecondaryComponent !== b.SecondaryComponent) {
            return a.SecondaryComponent < b.SecondaryComponent ? -1 : 1;
          } 
          return 0;
        },   
      },    
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
    {dataIndex: 'Unit',
      key: 'Unit',
      title: 'UNIT',   
      sorter: {
        compare: (a, b): number => {
          if (a.Unit !== b.Unit) {
            return a.Unit < b.Unit ? -1 : 1;
          } 
          return 0;
        },
      },    
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},
     {dataIndex: 'ExportSchedule',
      key: 'ExportSchedule',
      title: 'Export Schedule',  
      sorter: {
        compare: (a, b): number => {
          if (a.ExportSchedule !== b.ExportSchedule) {
            return a.ExportSchedule < b.ExportSchedule ? -1 : 1;
          } 
          return 0;
        },
      },      
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue },
      
      {
        dataIndex: 'UpdatedDate',
        key: 'UpdatedDate',
        
        title: <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" placement="topLeft" overlay={PDSCONSTANT.TOOLTIP_TEXT_PDS_LAST_UPDATED}>
             <span>Last Updated</span>
          </Tooltip>,
        sorter: {
          compare: (a, b): number => {
            if (a.UpdatedDate !== b.UpdatedDate) {
              return a.UpdatedDate < b.UpdatedDate ? -1 : 1;
            }
            return 0;
          },
        },
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder:'ascend', 
        render: props.renderDateAndTimeValue 
      },
      { dataIndex: 'UpdatedBy',
      key: 'UpdatedBy',
      title: 'UpdatedBy',     
      sorter: {
        compare: (a, b): number => {
          if (a.UpdatedBy !== b.UpdatedBy) {
            return a.UpdatedBy < b.UpdatedBy ? -1 : 1;
          } 
          return 0;
        }, 
      },
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue}     
      

  ];
  return(alignecurveConfigurationColumns);  
}