import { AxiosInstance } from "axios";
import { HTTPClient } from "../common/apiservice";
import { IProductCurvePayload } from "../interfaces/IProductCurvePayload";
import { IProductCurveFilter } from "../interfaces/IProductCurveFilter";
import { IProductCurvePricePayload } from "../interfaces/IProductCurvePricePayload";
import { APIURL } from "../common/api-url";
import { useAuth } from "../common/hooks/auth-provider";
import { HttpHeader } from "../common/HttpHeader";
import { IDownloadFilter } from "../interfaces/IDownloadFilter";

// Service class for Product Curves
export const ProductCurves = () => {

   // axios instance for HTTP calls
   const { appUser } = useAuth();
   
   //set http header
   var config = HttpHeader().GetHeader(appUser?.user?.access_token,  process.env.REACT_APP_HEADER_API_VERSION);   
   const _httpclient: AxiosInstance = HTTPClient(config);
   
   // Method for fetching GetProductCurve data based on ProductCurveFilter
   async function GetAllProductCurves(productCurveFilter: IProductCurvePayload) {
      return await _httpclient.post(APIURL.CURVE_VIEWER_URL, productCurveFilter);      
   }

   // Method for fetching Product Curve Prices based on ProductCurvePriceFilter
   async function GetProductCurvePriceByProductCurve(productCurvePriceFilter: IProductCurvePricePayload) {
      return await _httpclient.post(APIURL.CURVE_VIEWER_PRICE_URL, productCurvePriceFilter);      
   }
   
    // Method for downloading Product Curve based on downloadFiter 
   async function DownloadSelectedProductCurveData(downloadFilter:IDownloadFilter){
        return await _httpclient.post(APIURL.CURVE_VIEWER_DOWNLOAD_PRODUCT_DATA_URL,downloadFilter);      
    }

    // Method for fetching GetProductCurve data based on ProductCurveFilter
   async function GetProductCurves(productCurveFilter:IProductCurveFilter) {
      return await _httpclient.post(APIURL.CURVES_VIEWER_URL, productCurveFilter);      
   }


   return {
      GetAllProductCurves,
      GetProductCurvePriceByProductCurve,
      DownloadSelectedProductCurveData,
      GetProductCurves
   };
}