import { Checkbox, ColumnsType, RecordType,Tooltip,Text } from "@sede-x/shell-ds-react-framework";
import { PDSCONSTANT } from "../../common/constants";
import { IManualAligneExportPopupColumnProps } from "../../interfaces/IManualAligneExportPopupColumnProps";


// Columns for Aligne Response Table
export const AligneResponsePopupColumnsHelper=(props:IManualAligneExportPopupColumnProps):ColumnsType<RecordType>=>
{   const aligneResponseColumns:ColumnsType<RecordType>= [  
    
      { dataIndex: 'error',
      key: 'error',
      title: 'Error Message',     
            
      render: props.renderValue},
    { dataIndex: 'description',
      key: 'description',
      title: 'Description',     
            
      render: props.renderValue},  
      { dataIndex: 'PDSError',
      key: 'PDSError',
      title: 'Recommendation',     
            
      render: props.renderValue}    
      
      

  ];
  return(aligneResponseColumns);  
}