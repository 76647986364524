import { Filters } from '@sede-x/glass-design-library';
import { useEffect, useState } from 'react';
import { ICurveConfigurationFilterProps } from "../../interfaces/ICurveConfigurationFilterProps";
import { IMasterData } from '../../interfaces/IMasterData';
import { MasterData } from '../../services/MasterData';

export const CalendarConfigurationFilters = (props: ICurveConfigurationFilterProps) => {
    const { GetBooleanValueFilters } = MasterData();
    const [calendars, setCalendar] = useState<IMasterData[]>([]);
    const [isActive, setIsActive] = useState<IMasterData[]>([]);
    // Represents the filter default values
    const filterValues: any = [];
    const dropdownPlaceHolder = 'Please select';
    const dropdownType = 'SINGLE_SELECTION';

    // Api calls for Filter dropdowns
    useEffect(() => {

        if (props.masterData) {
            let calendarDataList: IMasterData[] = [];
            for (let i = 0; i < props.masterData.length; i++) {
                let calendarData: IMasterData = { text: "", value: "" }
                calendarData.text = props.masterData[i].Name;
                calendarData.value = props.masterData[i].Name;
                calendarDataList.push(calendarData);

            }
            setCalendar(calendarDataList);
            setIsActive(GetBooleanValueFilters());
        }
    }
        , [props.masterData]);



    return (
        <Filters collapsable
            fields={
                [

                    {
                        label: 'Calendar',
                        name: 'calendars',
                        options: calendars,
                        placeholder: dropdownPlaceHolder,
                        type: dropdownType
                    },
                    {
                        label: 'Is Active',
                        name: 'isActive',
                        options: isActive,
                        placeholder: dropdownPlaceHolder,
                        type: dropdownType
                    }

                ]}
            defaultValues={filterValues}
            onApply={function onApply(values: any) {
                props.onApply(values);

            }} />
    );
}