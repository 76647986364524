import { Permission, ROLES } from "../common/enum";
import { useAuth } from "../common/hooks/auth-provider";


// Helper class for user permissions
export const UserPermission = () => {
   // current logged in app user
   const { appUser } = useAuth();

   // Method to get the write permission for curve master data
   function CanWrite_CurveMasterData() {
      return appUser?.Permissions?.includes(Permission.CurveMasterData_WRITE);
   }

   // Method to get the write permission for align mapping
   function CanWrite_AligneMapping() {
      return appUser?.Permissions?.includes(Permission.AligneMapping_WRITE);
   }

   // Method to get the export permission for align export
   function CanExport_AligneExport_MANUAL() {
      return appUser?.Permissions?.includes(Permission.AligneExport_MANUAL);
   }

   // Method to get the config permision to config private price curve
   function CanConfig_PrivatePriceCurve() {
      return appUser?.Permissions?.includes(Permission.PriceCurvePrivate_CONFIG);
   }

   // Method to get the read permission for private price curve
   function CanRead_PrivatePriceCurve() {
      return appUser?.Permissions?.includes(Permission.PriceCurvePrivate_READ);
   }

   // Method to get the read permission for ReferenceData
   function CanRead_ReferenceData() {
      return appUser?.Permissions?.includes(Permission.ReferenceData_READ);
   }

   // Method to get the write permission for ReferenceData
   function CanWrite_ReferenceData() {
      return appUser?.Permissions?.includes(Permission.ReferenceData_WRITE);
   }

   // Method to get the write permission for CalendarData
   function CanWrite_CalendarData() {
      return appUser?.Permissions?.includes(Permission.CalendarData_WRITE);
   }

   //ReadOnly user - access by all
   // Method to get the read permission for curve master data
   function CanRead_CurveMasterData() {
      return appUser?.Permissions?.includes(Permission.CurveMasterData_READ);
   }

   // Method to get the read permission read price curve
   function CanRead_PriceCurve_READ() {
      return appUser?.Permissions?.includes(Permission.PriceCurve_READ);
   }

   // Method to get the read permission read align mapping
   function CanRead_AligneMapping_READ() {
      return appUser?.Permissions?.includes(Permission.AligneMapping_READ);
   }

   // Method to get the export permission to export align export report intraday
   function CanExport_AligneExportReport_INTRADAY() {
      return appUser?.Permissions?.includes(Permission.AligneExportReport_INTRADAY);
   }

   // Method to get the export permission  to align export report EOD
   function CanExport_AligneExportReport_EOD() {
      return appUser?.Permissions?.includes(Permission.AligneExportReport_EOD);
   }

   // Method to to validate if logged in user has authorized access
   function UnAuthorizeAccess() {
      var allowedRoles: string[] = [ROLES.MarketRiskOperator, ROLES.MarketRiskAdvisor, ROLES.ITSupport, ROLES.ReadOnly];
      var matchedRole = appUser?.Roles?.find(role => allowedRoles?.includes(role))
      return ((matchedRole === undefined) || appUser?.Permissions?.length === 0);
   }


   return {

      //accessible only by MarketRiskOperator
      CanWrite_CurveMasterData,
      CanWrite_AligneMapping,
      CanExport_AligneExport_MANUAL,
      CanConfig_PrivatePriceCurve,
      CanWrite_CalendarData,

      //accessible by all but ReadOnly
      CanRead_PrivatePriceCurve,
      CanRead_ReferenceData,

      //accessible by MRO and ITSupport Only
      CanWrite_ReferenceData,

      //accessible by all
      CanRead_CurveMasterData,
      CanRead_PriceCurve_READ,
      CanRead_AligneMapping_READ,
      CanExport_AligneExportReport_INTRADAY,
      CanExport_AligneExportReport_EOD,

      //unauthorize access
      UnAuthorizeAccess
   };

}