import { Filters } from '@sede-x/glass-design-library';
import { useEffect, useState,useCallback } from 'react';
import { PDSCONSTANT } from '../../common/constants';
import { AligneExportFiltersName } from '../../common/enum';
import { IAligneExportFilterProps, ICurveConfigurationFilterProps } from '../../interfaces/ICurveConfigurationFilterProps';
import {IMasterData} from '../../interfaces/IMasterData';
import { UserPermission } from '../../services/UserPermission';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import {dateFilterHelper} from '../../common/DateFilterHelper';
import { IPDSFilterProps } from '../../interfaces/IPDSFilterProps';
dayjs.extend(weekday)
dayjs.extend(localeData)

//Using @sede-x/glass-design-library Filter component for filter component
export const  AligneExportReportFilters =(props:IAligneExportFilterProps) => {
   // Initialise the state variable  
  const [granularity, setGranularity] = useState<IMasterData[]>([]);
  const [market, setMarket] = useState<IMasterData[]>([]);
  const [component, setComponent] = useState<IMasterData[]>([]);
  const [pset, setPSet] = useState<IMasterData[]>([]);
  const [curveType, setCurveType] = useState<IMasterData[]>([]);
  const [exportStatus, setExportStatus] = useState<IMasterData[]>([]);
  const [exportType, setExportType] = useState<IMasterData[]>([]);
  const [exportStatusFilter, setExportStatusFilter] = useState<string[]>([]);
  const dropdownPlaceHolder ='Please select';
  const dropdownType = 'MULTISELECTION';  
    // DateFilterHelper methods
    const { getDefaultEffectiveDate,onChangeDateRange,resetDateRange,getDefaultExportDate,resetExportDateRange } = dateFilterHelper(); 
  
    // Setting default date
    let effectiveDate = getDefaultEffectiveDate();
    const [effectiveStartDateRange, setEffectiveStartDateRange] = useState(dayjs(effectiveDate.EffectiveStartDate));
    const [effectiveEndDateRange, setEffectiveEndDateRange] = useState(dayjs(effectiveDate.EffectiveEndDate));
    //Setting default Export Date
    const exportDate = getDefaultExportDate();
    const [exportStartDateRange, setExportStartDateRange] = useState(dayjs(exportDate.EffectiveStartDate));
    const [exportEndDateRange, setExportEndDateRange] = useState(dayjs(exportDate.EffectiveEndDate));
    
    // On change of date in Effective Date range or on resetting the date on dateRange
    const onEffectiveChangeDate = (dates: { $d: dayjs.Dayjs | Date | null ; }[] | null) => {    
      effectiveDate= onChangeDateRange(dates);
      setEffectiveStartDateRange(dayjs(effectiveDate.EffectiveStartDate));
      setEffectiveEndDateRange(dayjs(effectiveDate.EffectiveEndDate));
      filterValues['effectivedaterange'] = [dayjs(effectiveDate.EffectiveStartDate), dayjs(effectiveDate.EffectiveEndDate)];      
    }

     // On change of date in Effective Date range or on resetting the date on dateRange
     const onExportChangeDate = (dates: { $d: dayjs.Dayjs | Date | null ; }[] | null) => {    
      effectiveDate= onChangeDateRange(dates);
      setExportStartDateRange(dayjs(effectiveDate.EffectiveStartDate));
      setExportEndDateRange(dayjs(effectiveDate.EffectiveEndDate));
      filterValues['exportdaterange'] = [dayjs(effectiveDate.EffectiveStartDate), dayjs(effectiveDate.EffectiveEndDate)];      
    }
      
    // Reset Effective Date Range and Export Date range filter
    const resetDate = () => {
      // Setting the effective start date range and effective end date range to filter values
      effectiveDate= resetDateRange();      
      setEffectiveStartDateRange(dayjs(effectiveDate.EffectiveStartDate));
      setEffectiveEndDateRange(dayjs(effectiveDate.EffectiveEndDate));  
      filterValues['effectivedaterange'] = [dayjs(effectiveDate.EffectiveStartDate), dayjs(effectiveDate.EffectiveEndDate)]; 
       // Setting the export start date range and export end date range to filter values
      effectiveDate= resetExportDateRange();      
      setExportStartDateRange(dayjs(effectiveDate.EffectiveStartDate));
      setExportEndDateRange(dayjs(effectiveDate.EffectiveEndDate));     
      filterValues['exportdaterange'] = [dayjs(effectiveDate.EffectiveStartDate), dayjs(effectiveDate.EffectiveEndDate)];    
    }

    // On change of date in Effective Date range or on resetting the date on dateRange
    const onExportStatusChange = (aligneExportStatus: string[]) => {    
           
           setExportStatusFilter(aligneExportStatus);
           filterValues['exportStatus']=aligneExportStatus;
    }
    
    // Represents the filter default values
    const filterValues: any = [];
    
    useEffect(() => {
      // Setting Effective Date Range default values
    filterValues['effectivedaterange'] = [dayjs(effectiveStartDateRange), dayjs(effectiveEndDateRange)];     
  // Setting Export Date Range default values 
     filterValues['exportdaterange'] = [dayjs(exportStartDateRange), dayjs(exportEndDateRange)];
    }, []);

  //  calls for Filter dropdowns
  useEffect(() => {  
      
          // Master dropdown data
          if(props.masterData)
          {    
            // Market dropdown values        
            setMarket(props.masterData[PDSCONSTANT.ALIGNE_MARKET].map((str:string, _index:number) => ({ value: str, text: str})));
            // Component dropdown values  
            setComponent(props.masterData[PDSCONSTANT.ALIGNE_COMPONENT].map((str:string, _index:number) => ({ value: str, text: str })));
            // PSET dropdown values  
            setPSet(props.masterData[PDSCONSTANT.ALIGNE_PSET].map((str:string, _index:number) => ({ value: str, text: str })));    
             // Granularity dropdown values          
            setGranularity(props.masterData[PDSCONSTANT.ALIGNE_GRANULARITY].map((str:string, _index:number) => ({ value: str, text: str })));
             // CurveType dropdown values  
            setCurveType(props.masterData[PDSCONSTANT.ALIGNE_CURVETYPE].map((str:string, _index:number) => ({ value: str, text: str })));
             // Export Type dropdown values  
            setExportType(props.masterData[PDSCONSTANT.ALIGNE_EXPORTSCHEDULE].map((str:string, _index:number) => ({ value: str, text: str })));     
             // Export Status dropdown values        
            setExportStatus(props.masterData[PDSCONSTANT.ALIGNE_EXPORTSTATUS].map((str:string, _index:number) => ({ value: str, text: str }))); 
            
          }  
            // Find the Filter's reset button 
    const resetButton = document.querySelector(PDSCONSTANT.RESET_BTN_IDENTIFIER) as HTMLButtonElement;
    // Add click event on the reset button 
    resetButton.addEventListener('click', resetDate, false);
    setExportStatusFilter(props.selectedExportStatusFilter);
        }  
  ,[props.masterData, props.selectedExportStatusFilter]); 
  
    // Callback function on click of Filter Apply button 
    const onApplyfunc = useCallback(
      (values: object) => onPDSApply(values),[effectiveStartDateRange,effectiveEndDateRange,exportStartDateRange,exportEndDateRange,exportStatusFilter]
    )
    // Method to pass the filter criteria to Parentcomponent
    function onPDSApply(values: object) {      
      if(Object.keys(values).length>0){
      // Cast the object to IPDSFilterProps
      const res = values as IPDSFilterProps;      
      res.effectivedaterange[0]=effectiveStartDateRange;
      res.effectivedaterange[1]=effectiveEndDateRange;
      res.exportdaterange[0]=exportStartDateRange;
      res.exportdaterange[1]=exportEndDateRange;
      res.exportStatus=exportStatusFilter;
      props.onApply(res);
      }
      else
      {
        filterValues['effectivedaterange'] = [effectiveStartDateRange, effectiveEndDateRange];
        filterValues['exportdaterange'] = [exportStartDateRange, exportEndDateRange];
        filterValues['exportStatus'] =exportStatusFilter;
        props.onApply(filterValues); 
      }
    }
  return (  
    <Filters collapsable 
    fields={
      [        
         {
            label: 'Name',
            name: 'name',
            placeholder: '',
            type: 'SEARCH'
          },
          {
            label: 'Granularity',
            name: 'granularity',
            options: granularity,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'CurveType',
            name: 'curveType',
            options: curveType,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },          
          {
            label: 'Effective Date Range',
            name: 'effectivedaterange',
            placeholder: [
              'Start',
              'End'
            ],
            type: 'DATE_RANGE',
            onChange: onEffectiveChangeDate,
            value: [effectiveStartDateRange, effectiveEndDateRange]
          },
          {
            label: 'Market',
            name: 'market',
            options: market,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Component',
            name: 'component',
            options: component,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'PSET',
            name: 'pset',
            options: pset,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Export Initiated Date Range',
            name: 'exportdaterange',
            placeholder: [
              'Start',
              'End'
            ],
            type: 'DATE_RANGE',
            picker:'DATEHOUR' ,
            onChange: onExportChangeDate,
            value: [exportStartDateRange, exportEndDateRange]        
           
           
          },
          {
            label: 'Export Type',
            name: 'exportType',
            options: exportType,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },           
          {
            label: 'Export Status',
            name: 'exportStatus',
            options: exportStatus,
            placeholder: dropdownPlaceHolder,
            type: dropdownType,
            value:exportStatusFilter,      
            onChange: onExportStatusChange,
          },  

    ]} 
    defaultValues={filterValues}   
    onApply={onApplyfunc} />   
  );
}
