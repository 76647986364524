import {
  ButtonLink,
  Checkbox,
  Grid,
  useSorter,
} from "@sede-x/shell-ds-react-framework";
import {
  Columns,
  Download,
  Search,
} from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";
import {
  ColumnsType,
  RecordType,
} from "@sede-x/shell-ds-react-framework/build/cjs/components/Table/Table.types";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { TABLE_CLASS } from "@sede-x/shell-ds-react-framework/build/cjs/utils/constants";
import * as Styled from "./CurveViewerMain.styles";
import DataTable from "../../library/Table/Table";
import DataTablePagination from "../../library/Pagination/Pagination";
import CurveViewerDrawer from "./CurveViewerSideDrawer";
import {
  FilterContainer,
  FilterStyled,
  FlagImgStyled,
} from "./CurveViewerMain.styles";
import { IProductCurveFilter } from "../../interfaces/IProductCurveFilter";
import { IProductCurvePayload } from "../../interfaces/IProductCurvePayload";
import { IPaginationFilter } from "../../interfaces/IPaginationFilter";
import { ISortCriteria } from "../../interfaces/ISortCriteria";
import { ProductCurves } from "../../services/ProductCurves";
import { IProductCurveData } from "../../interfaces/IProductCurveData";
import { SortDirection, ZeroCalEnum } from "../../common/enum";
import { PDSCONSTANT } from "../../common/constants";
import { CurveViewerColumnsHelper } from "./CurveViewerColumnsHelper";
import { ICurveViewerColumnProps } from "../../interfaces/ICurveViewerColumnProps";
import { ICurveViewerProductCurveData } from "../../interfaces/ICurveViewerProductCurveData";
import Loader from "../../library/Loader/Loader";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { IDownloadFilter } from "../../interfaces/IDownloadFilter";
import { DownloadHelper } from "../../common/DownloadHelper";
import { UserPermission } from "../../services/UserPermission";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

dayjs.extend(utc);
// Add 0 if number is lesser than 10
const pad = (num: number) => {
  return (num < 10 ? "0" : "") + num;
};
// Method to change date into ISO format string
const toIsoString = (date: Date) => {
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T00:00:00Z"
  );
};
// Set Default Page size
const defaultPageSize = PDSCONSTANT.DEFAULT_PAGE_SIZE;
let drawer: any;

// Setting Default Effective Start Date
var effectiveStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
// Setting effective start Date hours, minutes and milliseconds to Zero
effectiveStartDate.setUTCHours(0, 0, 0, 0);

// Setting Default Effective End Date
var effectiveEndDate = new Date();
// Setting effective End Date hours, minutes and milliseconds to Zero
effectiveEndDate.setUTCHours(0, 0, 0, 0);

// React screen for Curve Viewer
export const CurveViewer = () => {
  //object containing user permissons
  const permissions = UserPermission();
  const imgUrl = process.env.REACT_APP_HOME_URL + "/redflagIcon.png";

  // React state variables
  const [currentPage, setPage] = useState(1);
  const [headerCheckbox, setHeaderCheckbox] = useState(false);
  const [curveViewerData, setCurveViewerData] = useState<IProductCurveData[]>(
    []
  );
  const { GetAllProductCurves, DownloadSelectedProductCurveData } =
    ProductCurves();
  const [wildCardSearchText, setWildCardSearchText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState<IProductCurveFilter>({
    Name: null,
    CurveType: [],
    Granularity: [],
    PriceType: [],
    Profile: [],
    DeliveryPoint: [],
    Currency: [],
    Unit: [],
    Location: [],
    ContractOption: [],
    Commodity: [],
    DataProvider: [],
    Source: [],
    IsPrivate: null,
    IsActive: true,
    SourceCurveName: null,
    WildCardSearchText: null,
    EffectiveStartDate: effectiveStartDate.toISOString(),
    EffectiveEndDate: effectiveEndDate.toISOString(),
    TraderApprovalIndicator: null,
  });
  const [paginationFilter, setPaginationFilter] = useState<IPaginationFilter>({
    PageNumber: 1,
    PageSize: defaultPageSize,
    TotalRecords: 0,
  });
  const [sortCriteria, setSortCriteria] = useState<ISortCriteria[]>([]);
  const [productCurvePriceData, setProductCurvePriceData] =
    useState<ICurveViewerProductCurveData>({
      CurveName: "",
      CurveType: "",
      EffectiveDate: "",
      Granularity: "",
      UpdatedDate: "",
      SourceUpdatedDate: "",
      CurveBuildId: "",
      TraderApprovalDateTimeUTC: "",
      TraderApprovalName: "",
      TraderApprovalIndicator: false,
    });
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [unSelectedData] = useState<string[]>([]);
  const productCurvePayload: IProductCurvePayload = {
    WildCardSearchText: wildCardSearchText,
    FilterCriteria: filterCriteria,
    PaginationFilter: paginationFilter,
    SortCriteria: sortCriteria,
  };

  //initialize to default
  const downloadFilter: IDownloadFilter = {
    ProductCurvesFilter: filterCriteria,
    IsAllSelected: headerCheckbox,
    SelectedCurveList: selectedData,
    UnSelectedCurveList: unSelectedData,
  };

  //clear the drawer object when moving out from curve viewer screen
  useLayoutEffect(() => {
    return () => {
      drawer = undefined;
    };
  }, []);

  useEffect(() => {
    // Set Page Size on the basis of  Window size
    paginationFilter.PageSize = window.innerHeight
      ? parseInt((window.innerHeight / 70).toFixed())
      : 10;
    // Fetch the Product Curve data on load and on change of product curve payload
    getProductCurveData();
  }, [wildCardSearchText, filterCriteria, paginationFilter, sortCriteria]);
  // Api calls for Product Curve data
  const getProductCurveData = () => {
    // Comment for Loader - To be built
    setCurveViewerData([]);
    setIsProcessing(true);
    GetAllProductCurves(productCurvePayload)
      .then((result) => {
        setCurveViewerData(result.data.Data);
        setIsProcessing(false);
        // Mapping IsChecked property and set it as false for Product curve data
        var responseResult = result.data.Data.map((o: any, i: any) => ({
          ...o,
          IsChecked: false,
        }));

        //exclude private curve based authorization
        if (!permissions.CanRead_PrivatePriceCurve()) {
          responseResult = responseResult.filter(
            (curve: any) => curve.IsPrivate !== true
          );
        }

        // Setting the product curve data for table
        setCurveViewerData(responseResult);
        // Setting the pagination parameters
        paginationFilter.TotalRecords =
          result.data.PaginationFilter.TotalRecords;
        paginationFilter.PageNumber = currentPage;
        paginationFilter.PageSize = result.data.PaginationFilter.PageSize;
      })
      .catch((err) => {
        setIsProcessing(false);
      });
  };
  // Method for Download product Curve data
  const downloadSelectedCurveData = () => {
    //show error message if no curve is selected to download
    if (
      !downloadFilter.IsAllSelected &&
      downloadFilter.SelectedCurveList.length === 0
    ) {
      toast.error("No curve selected to download!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    // API calls to download selected product curve with price data
    DownloadSelectedProductCurveData(downloadFilter).then((result) => {
      //call to helper to download
      DownloadHelper().ExportToCSV(
        PDSCONSTANT.EXPORT_FILENAME_PRODUCTCURVE,
        result.data
      );
    });
  };

  //Set the Page Number to 1
  const resetPageNumber = () => {
    setPage(1);
    setPaginationFilter({
      PageNumber: 1,
      PageSize: defaultPageSize,
      TotalRecords: 0,
    });
  };

  // Call this method on click of Apply in filters
  const handleFilterCriteria = (filterValues: any) => {
    resetPageNumber();
    filterCriteria.Name = filterValues["name"];
    filterCriteria.CurveType = filterValues["curvetype"];
    filterCriteria.Source = filterValues["sources"];
    filterCriteria.Granularity = filterValues["granularity"];
    filterCriteria.TraderApprovalIndicator = null; //set the default value
    if (
      filterValues["traderapprovalindicator"] !== null &&
      filterValues["traderapprovalindicator"] !== undefined
    ) {
      if (filterValues["traderapprovalindicator"].length > 0) {
        filterCriteria.TraderApprovalIndicator =
          filterValues["traderapprovalindicator"] === "TRUE" ? true : false;
      }
    }
    filterCriteria.IsComplete = null; //set the default value
    if (
      filterValues["iscomplete"] !== null &&
      filterValues["iscomplete"] !== undefined
    ) {
      if (filterValues["iscomplete"].length > 0) {
        filterCriteria.IsComplete =
          filterValues["iscomplete"] === "TRUE" ? true : false;
      }
    }
    if (
      filterValues["effectivedaterange"] !== undefined &&
      filterValues["effectivedaterange"] !== null
    ) {
      filterCriteria.EffectiveEndDate = toIsoString(
        filterValues["effectivedaterange"][1]["$d"]
      );
      filterCriteria.EffectiveStartDate = toIsoString(
        filterValues["effectivedaterange"][0]["$d"]
      );
    } else {
      filterCriteria.EffectiveEndDate = null;
      filterCriteria.EffectiveStartDate = null;
    }
    // Assign state variable value to new variable to update with new state
    let curveViewerFilterCriteria: IProductCurveFilter = filterCriteria;
    setFilterCriteria(curveViewerFilterCriteria);

    //clear previous checked status on filter search
    resetCheckboxSelection();
    getProductCurveData();
  };
  // On Page Change updated the Pagination filter
  const handlePaginationFilter = (paginationData: IPaginationFilter) => {
    setHeaderCheckbox(false);
    setPaginationFilter(paginationData);
  };

  // reset checkbox selections
  const resetCheckboxSelection = () => {
    //clear all selection and headerCheck to false
    setSelectedData([]);
    // Setting the header checkbox on click
    setHeaderCheckbox(false);
  };

  // For rendering checkbox in table
  const renderCheckbox = (value: any, record: any, index: number) => {
    //Handle the changes on check or uncheck of a particular row checkbox
    const handleChange = () => {
      //only single curve selection is allowed

      //uncheck if any checked previously
      const curveList = curveViewerData.map((item) => ({
        ...item,
        IsChecked: false,
      }));

      // Update the particular row checked/unchecked status
      const selectCurveData = [
        ...curveList.slice(0, index),
        {
          ...curveList[index],
          IsChecked: !curveViewerData[index].IsChecked,
        },
        ...curveList.slice(index + 1),
      ];
      // Set the updated curve status
      setCurveViewerData(selectCurveData);

      //maintain the global checked list
      //add if checked, and removed if unchecked
      if (selectCurveData[index].IsChecked) {
        selectedData.length = 0; //reset previous selections,only single selection is allowed
        //if checked add, track by _id not by name for curve viewer page
        selectedData.push(selectCurveData[index]._id);
      } else {
        //if unchecked - remove
        selectedData.length = 0;
        //also uncheck the global checkbox
        setHeaderCheckbox(false);
      }
      setSelectedData([...selectedData]);
    };
    // Rendering the checkbox for row
    return (
      <Checkbox
        onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        crossOrigin="false"
        label=""
        size="small"
        id={index.toString()}
        checked={value}
        onChange={handleChange}
      />
    );
  };
  // For rendering check box all functionality
  const handleCheckboxAll = () => {
    // In case of clicking on select/unselect all check box render the curve data again
    const checkedCurveData = curveViewerData.map((item) => ({
      ...item,
      IsChecked: !headerCheckbox,
    }));
    // Setting the curve data again
    setCurveViewerData(checkedCurveData);
    // Populate the array with checked curve names
    const selectedCurveData = checkedCurveData
      .filter((obj) => {
        return obj.IsChecked === true;
      })
      .map((a) => a.CurveName);
    // Setting the selected curve names
    setSelectedData(selectedCurveData);
    // Setting the header checkbox on click
    setHeaderCheckbox(!headerCheckbox);
  };
  // For rendering string value in table
  const renderValue = (value: any, record: any, index: number) => {
    return <div>{value}</div>;
  };
  // For rendering date value in table
  const renderDateValue = (value: any, record: any, index: number) => {
    value = dayjs(value).utcOffset(0).format(PDSCONSTANT.DATE_FORMAT);
    return <div>{value}</div>;
  };
  // For rendering date value in table
  const renderDateAndTimeValue = (value: any, record: any, index: number) => {
    value =
      dayjs(value).utcOffset(0).format(PDSCONSTANT.DATE_FORMAT) +
      " | " +
      dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
    return <div>{value}</div>;
  };
  // For rendering source date value in table
  const renderSourceDateAndTimeValue = (
    value: any,
    record: any,
    index: number
  ) => {
    if (dayjs(value).isValid()) {
      value =
        dayjs(value).utcOffset(0).format(PDSCONSTANT.DATE_FORMAT) +
        " | " +
        dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
    } else {
      value = "";
    }
    return <div>{value}</div>;
  };
  // For rendering link in table
  const renderLinkValue = (value: any, record: any, index: number) => {
    return (
      <ButtonLink
      onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        placeholder=""
        size="small"
        onClick={() => {
          var productCurveData = curveViewerData[index];

          //set selected curve
          selectedData.length = 0; //clear previous selection
          selectedData.push(productCurveData._id);

          //uncheck any existing selection in grid - only single selection is allowed at a time
          const checkedCurveData = curveViewerData.map((item) => ({
            ...item,
            IsChecked: false,
          }));
          // Setting the curve data again
          setCurveViewerData(checkedCurveData);

          //load price data
          setProductCurvePriceData(productCurveData);
          setOpen(true);
        }}
      >
        {value}
      </ButtonLink>
    );
  };

  // For rendering link in table
  const renderTraderApproval = (value: any, record: any, index: number) => {
    value =
      value === true
        ? ZeroCalEnum[ZeroCalEnum.TRUE]
        : value === false
        ? ZeroCalEnum[ZeroCalEnum.FALSE]
        : "";
    return <div>{value}</div>;
  };

  // For rendering link in table
  const renderCompletionFlow = (value: any, record: any, index: number) => {
    value =
      value === false ? (
        <FlagImgStyled
          src={imgUrl}
          title={PDSCONSTANT.TOOLTIP_TEXT_IS_CURVE_COMPLETE}
        ></FlagImgStyled>
      ) : (
        ""
      );
    return <div>{value}</div>;
  };
  // For Right Overlay state variable for Drawer component open state
  const [open, setOpen] = useState(false);
  const onClose = () => {
    //reset selection on drawer close
    selectedData.length = 0;

    setOpen(false);
  };
  // Passing render methods as a props in CurveViewerColumnHelper
  const curveViewerColumnProps: ICurveViewerColumnProps = {
    renderCheckbox: renderCheckbox,
    renderDateAndTimeValue: renderDateAndTimeValue,
    renderDateValue: renderDateValue,
    renderLinkValue: renderLinkValue,
    renderValue: renderValue,
    handleCheckboxAll: handleCheckboxAll,
    headerCheckbox: headerCheckbox,
    renderSourceDateAndTimeValue: renderSourceDateAndTimeValue,
    renderTraderApproval: renderTraderApproval,
    renderCompletionFlow: renderCompletionFlow,
  };
  const curveViewerColumns = CurveViewerColumnsHelper(curveViewerColumnProps);
  // For Sorting
  const [transformSorterColumns, sortStates] = useSorter<RecordType>({
    prefixCls: TABLE_CLASS,
    columns: curveViewerColumns,
  });
  useMemo(() => {
    const SortCriteriaResult: ISortCriteria[] = [];
    for (var sortNumber in sortStates) {
      if (Object.hasOwn(sortStates, sortNumber)) {
        const SortCriterion: ISortCriteria = {
          ColumnName: "",
          Direction: "",
        };
        if (sortStates[sortNumber].sortOrder !== undefined) {
          SortCriterion.ColumnName =
            sortStates[sortNumber].column.dataIndex?.toString() ?? "";
          SortCriterion.Direction =
            sortStates[sortNumber].sortOrder === "ascend"
              ? SortDirection[SortDirection.DESC]
              : SortDirection[SortDirection.ASC];
          SortCriteriaResult.push(SortCriterion);
        }
      }
    }
    setSortCriteria(SortCriteriaResult);
  }, [sortStates]);
  // For changing the header sort icons
  const transformColumns = useCallback(
    (innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> =>
      transformSorterColumns(innerColumns),
    [transformSorterColumns]
  );
  if (productCurvePriceData.CurveName !== "") {
    drawer = (
      <CurveViewerDrawer
        onClose={onClose}
        open={open}
        downloadSelectedCurveData={downloadSelectedCurveData}
        {...productCurvePriceData}
      />
    );
  }
  // Passing the columns
  const transformedColumns = transformColumns(curveViewerColumns);
  return (
    <>
      {isProcessing && <Loader />}
      {drawer}
      <Styled.InnerContainer>
        <Styled.Sidebar>
          <FilterContainer>
            <FilterStyled
              onApply={handleFilterCriteria}
              filterCriteria={filterCriteria}
            ></FilterStyled>
          </FilterContainer>
        </Styled.Sidebar>
        <Styled.ContentContainer>
          <Styled.CenteredContainer>
            <Styled.SideGrid>
              <Styled.GridStyled columns={2}>
                <Grid.Cell>
                  <b>Curve Viewer</b>
                </Grid.Cell>
                <Grid.Cell>
                  {permissions.CanRead_PriceCurve_READ() && (
                    <Styled.ButtonStyled
                      placeholder=""
                      size="small"
                      variant="outlined"
                      icon={<Download />}
                      onClick={downloadSelectedCurveData}
                      onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
                    >
                      Export Data
                    </Styled.ButtonStyled>
                  )}
                </Grid.Cell>
              </Styled.GridStyled>
              <Grid columns={1}>
                <Grid.Cell>
                  <hr />
                </Grid.Cell>
              </Grid>
              <Styled.GridStyled columns={2}>
                <Grid.Cell>
                  <Styled.TextInputStyled
                  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
                    crossOrigin="false"
                    size="small"
                    icon={<Search height={24} />}
                    placeholder="Search"
                    onKeyUp={(e: any) => {
                      // Fire event on enter press
                      if (e.keyCode === 13) {
                        // Setting the value enetered in search textbox
                        filterCriteria.WildCardSearchText = e.target.value;
                        let curveViewerFilterCriteria: IProductCurveFilter =
                          filterCriteria;
                        // Set the filtercriteria with the updated text value
                        setFilterCriteria(curveViewerFilterCriteria);
                        setPage(1);
                        // Calling the Get method for fetching
                        getProductCurveData();
                      }
                    }}
                  />
                </Grid.Cell>
                <Grid.Cell>
                  <Styled.ButtonStyled
                    placeholder=""
                    hidden
                    size="small"
                    variant="outlined"
                    onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
                    icon={<Columns />}
                  >
                    Show/Hide Columns
                  </Styled.ButtonStyled>
                </Grid.Cell>
              </Styled.GridStyled>
              <Grid rowGap="24px">
                <DataTable
                  data={curveViewerData}
                  columns={transformedColumns}
                  rowKey={PDSCONSTANT.CURVE_VIEWER_TABLE_ROW_KEY}
                />
                <DataTablePagination
                  currentPage={currentPage}
                  pageSize={paginationFilter.PageSize}
                  total={paginationFilter.TotalRecords}
                  setPage={setPage}
                  onPageChange={handlePaginationFilter}
                />
              </Grid>
            </Styled.SideGrid>
          </Styled.CenteredContainer>
        </Styled.ContentContainer>
      </Styled.InnerContainer>
      <ToastContainer />
    </>
  );
};
