// @ts-nocheck - don't typecheck because process.env.[...] is always possibly undefined
// configuration settings  interface for oidc-client library
import { EnvironmentHelper } from "./environmentHelper"
export interface Config {    
    auth: {
      algorithms: string[];
      audience: string;
      authority: string;
      clientId: string;
      domain: string;
      endpoints: {
        authorize: string;
        logout: string;
        register: string;
        revoke: string;
        token: string;
        userinfo: string;
      };
      issuer: string;
      jwksUrl: string;
      openIdConfigurationUrl: string;
      scope: string;
      storeUserInSessionStorage: boolean;
      redirectUrl: string;
      silentRedirectUrl: string;
      logoutRedirectUrl: string;
    };
  }
  // Identity server configuration properties
  const Authority_URL =EnvironmentHelper().Authority_URL;
  const ClientID_URL = EnvironmentHelper().ClientID_URL;
  const AUthorize_URL =EnvironmentHelper().AUthorize_URL;
  const Register_URL = EnvironmentHelper().Register_URL;
  const Revoke_URL = EnvironmentHelper().Revoke_URL;
  const Token_URL = EnvironmentHelper().Token_URL;
  const JWKS_URL =  EnvironmentHelper().JWKS_URL;
  const UserInfo_URL = EnvironmentHelper().UserInfo_URL;
  const OpendID_URL = EnvironmentHelper().OpendID_URL;
  // configuration settings to be populated from environment variable for oidc-client library
  export const config: Config = {      
    auth: {      
      authority: Authority_URL,
      clientId: ClientID_URL,
      domain: Authority_URL,
      endpoints: {
        authorize: AUthorize_URL,
        register: Register_URL,
        revoke: Revoke_URL,
        token:Token_URL,
        userinfo: UserInfo_URL,
      },
      issuer: Authority_URL,
      jwksUrl:JWKS_URL,
      openIdConfigurationUrl: OpendID_URL,
      scope: "openid profile",
      storeUserInSessionStorage: false,
      redirectUrl: `${window.location.origin}/signin-callback`,      
      logoutRedirectUrl: `${window.location.origin}/signout-callback`,
    },
  };
  