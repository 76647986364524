import { Checkbox, ColumnsType, RecordType,Tooltip,Text } from "@sede-x/shell-ds-react-framework";
import { PDSCONSTANT } from "../../common/constants";
import { IManualAligneExportPopupColumnProps } from "../../interfaces/IManualAligneExportPopupColumnProps";
import { HeaderText } from "../CurveViewer/CurveViewerMain.styles";
import React from "react";

// Columns for Manual Aligne Export Table
export const ManualAligneExportPopupColumnsHelper=(props:IManualAligneExportPopupColumnProps):ColumnsType<RecordType>=>
{   const alignecurveConfigurationColumns:ColumnsType<RecordType>= [    
    { dataIndex: 'IsChecked',
      key: 'IsChecked',      
      render: props.renderCheckbox, 
      title: '' },
    { dataIndex: 'CurveName',
      key: 'CurveName',
      title: 'Name',     
      sorter: {
        compare: (a, b): number => {
          if (a.CurveName !== b.CurveName) {
            return a.CurveName < b.CurveName ? -1 : 1;
          } 
          return 0;
        }, 
      },
      sortDirections: ['ascend', 'descend'],        
      render: props.renderValue},      
      {
        dataIndex: 'EffectiveDate',
        key: 'EffectiveDate',
        title: 'Effective Date',
        sorter: {
          compare: (a, b): number => {
            if (a.effectivedate !== b.effectivedate) {
              return a.effectivedate < b.effectivedate ? -1 : 1;
            }
            return 0;
          },
        },
        sortDirections: ['descend', 'ascend'],        
        render: props.renderDateValue
      },
      {
        dataIndex: 'SourceUpdatedDate',
        key: 'SourceUpdatedDate',
        title: <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" placement="topLeft"  overlay={PDSCONSTANT.TOOLTIP_TEXT_SOURCE_LAST_UPDATED}>
        <React.Fragment><HeaderText>Last Updated</HeaderText></React.Fragment>
      </Tooltip>,
        sorter: {
          compare: (a, b): number => {
            if (a.lastupdated !== b.lastupdated) {
              return a.lastupdated < b.lastupdated ? -1 : 1;
            }
            return 0;
          },
        },
        sortDirections: ['descend', 'ascend'],             
        render: props.renderSourceDateAndTimeValue 
      }
        
      

  ];
  return(alignecurveConfigurationColumns);  
}