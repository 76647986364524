import { PDSCONSTANT } from "./constants";

// Helper class to perform the input validation
export const ValidationHelper = () => {
  //method to validate and prevent unwanted input characters
  function OnKeyDown(event: any) {

    switch (event.key.toString()) {
      case "=":
      case "+":
      case "-":
      case "@":
      case "Tab":
      case "Enter": // carrige return - Enter           
        event.preventDefault();
        return false;
      default:
        return true;
    }
  };

  //method to remove first special character from text box recursively
  function RecursiveRemoveFirstSpecialChar(value: any): any {

    var firstChar = value[0];
    var regex = /[!@+-=]/g;
    if (regex.test(firstChar) && !/^\d+$/g.test(firstChar)) {
      return RecursiveRemoveFirstSpecialChar(value.substring(1));
    }
    else {
      return value;
    }

  }

  return {

    OnKeyDown,
    RecursiveRemoveFirstSpecialChar,
  };

}

// Represents the Validation message 
export const ValidationMessage = {
  // Product Curve Name Required validation message  
  CURVE_NAME_REQUIRED: "Curve Name is required",
  // Product Curve Granularity Required validation message 
  GRANULARITY_REQUIRED: "Granularity is required",
  // Product Curve CurveType Required validation message 
  CURVE_TYPE_REQUIRED: "Curve Type is required",
  // Product Curve Commodity Required validation message 
  COMMODITY_REQUIRED: "Commodity is required",
  // Product Curve Commodity Delivery point Required validation message 
  COMMODITY_DELIVERYPOINT_REQUIRED: "Commodity Delivery Point is required",
  // Product Curve Location Required validation message 
  CURVE_LOCATION_REQUIRED: "Location is required",
  // Product Curve Contract Option Required validation message 
  CONTRACT_OPTION_REQUIRED: "ContractOption is required",
  // Product Curve Currency Required validation message 
  CURRENCY_REQUIRED: "Currency is required",
  // Product Curve Secondary Currency Required validation message 
  SECONDARY_CURRENCY_REQUIRED: "Secondary Currency is required",
  // Product Curve Unit Required validation message 
  UNIT_REQUIRED: "Unit is required",
  // Product Curve DataProvider Required validation message 
  DATA_PROVIDER_REQUIRED: "Data Provider is required",
  // Product Curve PriceType Required validation message 
  PRICE_TYPE_REQUIRED: "Price Type is required",
  // Product Curve CommodityProfile Required validation message 
  PROFILE_REQUIRED: "Profile is required",
  // Product Curve Source Required validation message 
  SOURCE_REQUIRED: "Source Provider is required",
  // Product Curve SourceCurveName Required validation message 
  SOURCE_CURVE_NAME_REQUIRED: "Source Curve Name is required",
  // Product Curve Name already exist validation message 
  CURVE_NAME_ALREADY_EXIST: "Curve Name already exist",
  //Market Required validation message 
  MARKET_REQUIRED: "Market is required",
  // Component Required validation message 
  COMPONENT_REQUIRED: "Component is required",
  // PSET Required validation message 
  PSET_REQUIRED: "PSET is required",
  // Aligne Import type Required validation message 
  ALIGNE_IMPORT_TYPE_REQUIRED: "Aligne Import Type is required",
  // Export Schedule  Required validation message 
  EXPORT_SCHEDULE_REQUIRED: "Export Schedule is required",
  //Settle Required validation message 
  SETTLE_REQUIRED: "Settle is required",
  // Currency Field and Secondary Currency Field same validation message
  SAME_SECONDARY_CURRENCY_AS_CURRENCY : "Currency and Secondary Currency is same",

  MATURITY_DATE_REQUIRED: "Maturity Date is required",

  CALENDAR_NAME_REQUIRED: "Calendar Name is required",

  CALENDAR_DATE_REQUIRED: "Calendar Date is required",

  CALENDAR_DESC_REQUIRED: "Calendar Description is required"
  
}

export const TransformAligneMessageToPDS = (message: string): string => {
  let errorMessage:string ='';
  if(message==null || message==undefined)
  {
    return errorMessage;
  }
  if(message.includes(PDSCONSTANT.ALIGNE_INVALID_MATURITY_DATE_ERROR_MESSAGE))
  {
    errorMessage= PDSCONSTANT.PDS_INVALID_MATURITY_DATE_ERROR_MESSAGE;
  }
  else if(message.includes(PDSCONSTANT.ALIGNE_INVALID_MKT_COMP_ERROR_MESSAGE))
  {
    errorMessage= PDSCONSTANT.PDS_INVALID_MKT_COMP_ERROR_MESSAGE;
  }
  else if(message.includes(PDSCONSTANT.ALIGNE_INVALID_UNIT_ERROR_MESSAGE))
  {
    errorMessage= PDSCONSTANT.PDS_INVALID_UNIT_ERROR_MESSAGE;
  }
  else if(message.includes(PDSCONSTANT.ALIGNE_EOF_ERROR_MESSAGE))
  {
    errorMessage= PDSCONSTANT.PDS_EOF_ERROR_MESSAGE;
  }
  else if(message.includes(PDSCONSTANT.ALIGNE_CONVERT_XML_ERROR_MESSAGE))
  {
    errorMessage= PDSCONSTANT.PDS_CONVERT_XML_ERROR_MESSAGE;
  }
  else if(message.includes(PDSCONSTANT.ALIGNE_SERVER_DOWN_ERROR_MESSAGE))
  {
    errorMessage= PDSCONSTANT.PDS_SERVER_DOWN_ERROR_MESSAGE;
  }
  else if(message.includes(PDSCONSTANT.ALIGNE_INVALID_PASSWORD_ERROR_MESSAGE))
  {
    errorMessage= PDSCONSTANT.PDS_INVALID_PASSWORD_ERROR_MESSAGE;
  }
  else if(message.includes(PDSCONSTANT.ALIGNE_DAY_CLOSED))
  {
    errorMessage= PDSCONSTANT.PDS_DAY_CLOSED;
  }
  return errorMessage;
};