import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./common/hooks/auth-provider";
import { createAuthService } from "./common/auth";
import { config } from "./common/config";

// Creat auth service
const authService = createAuthService(config.auth);

// Rendering root div
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// Loading App component. AuthProvider is for authentication 
root.render(  
  <AuthProvider authService={authService}>
    <App />
  </AuthProvider> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
