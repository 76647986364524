import { AxiosInstance } from "axios";
import { HTTPClient } from "../common/apiservice";
import { IAligneCurvePayload } from "../interfaces/IAligneCurvePayload";
import { APIURL } from "../common/api-url";
import { IAligneCurveConfigurationRequest } from "../interfaces/IAligneCurveConfigurationRequest";
import { HttpHeader } from "../common/HttpHeader";
import { useAuth } from "../common/hooks/auth-provider";
import { IAligneCurveDownloadFilter } from "../interfaces/IAligneCurveDownloadFilter";
import { IAligneResponsePayload } from "../interfaces/IAligneResponsePayload";
import { IAligneUpdateModel } from "../interfaces/IAligneUpdateModel";

// Service class for Aligne Export Status Report
export const AligneExportStatusReport = () => {

    const { appUser } = useAuth();
    //setup http header
    var config = HttpHeader().GetHeader(appUser?.user?.access_token, process.env.REACT_APP_HEADER_API_VERSION);
    // Instance of Axios to call get and post method
    const _httpclient: AxiosInstance = HTTPClient(config);
    // Method for fetching AligneExportStatusReport data based on AligneCurveConfigurationFilter payload
    async function GetAligneExportStatusReport(aligneCurveFilter: IAligneCurvePayload) {
        const result = await _httpclient.post(APIURL.ALIGNE_EXPORT_REPORT_STATUS_PAGED_URL, aligneCurveFilter);
        return result;
    }

     // Method for downloading Aligne Export Report based on downlaodFiter     
     async function DownloadSelectedAligneExportReportData(downloadFilter:IAligneCurveDownloadFilter) {

        return await _httpclient.post(APIURL.ALIGNE_EXPORT_REPORT_DOWNLOAD_DATA_URL, downloadFilter);
    }

    // Method for fetching AligneResponseStatus data based on AligneResponseFilter payload
    async function GetAligneResponseStatus(aligneResponseFilter: IAligneResponsePayload) {
        const result = await _httpclient.post(APIURL.ALIGNE_RESPONSE_PAGED_URL, aligneResponseFilter);
        return result;
    }

    // Method for update Aligne Export Report based on downlaodFiter     
    async function UpdateAligneExportReportData(updateFilter:IAligneUpdateModel) {

        return await _httpclient.post(APIURL.ALIGNE_EXPORT_REPORT_UPDATE_DATA_URL, updateFilter);
    }
    return {
        GetAligneExportStatusReport,
        DownloadSelectedAligneExportReportData,
        GetAligneResponseStatus,
        UpdateAligneExportReportData       

    };

}