import { EmptyState, PageLayout } from '@sede-x/glass-design-library';
import { CardStyled, ImgStyled, LogOutMainContainer } from './Logout.style';

//Logout component 
export const LogOut=()=> {
   
  const imgUrl=process.env.REACT_APP_HOME_URL+"/Timeout.png";
  const mainMessage =process.env.REACT_APP_SESSIONTIMEOUTMAINMESSAGE;
  const otherMessage=process.env.REACT_APP_SESSIONTIMEOUTOTHERMESSAGE;
  const buttonText=process.env.REACT_APP_SESSION_TIMEOUTBUTTONTEXT??"";
  return ( 
    <LogOutMainContainer>
    <PageLayout
        content={<CardStyled  size='large'><ImgStyled src={imgUrl}></ImgStyled><EmptyState title={mainMessage} message={otherMessage} action={{
          onClick: function noRefCheck(){ window.location.href='/'}, text: buttonText   }}></EmptyState></CardStyled>}
        globalHeader={{
          avatar: {
            icon: '',
            action: function noRefCheck() { },
            src: '',
            title: '',
            description: ''
          },
          subtitle: process.env.REACT_APP_APPLICATIONSUBTITLE??"",        
          title: process.env.REACT_APP_APPLICATIONTITLE??""
        }}
        variant="CENTERED_CONTENT" leftSideBar={undefined} rightSideBar={undefined}  /></LogOutMainContainer> 
    );
  }
