import { EmptyState, PageLayout } from '@sede-x/glass-design-library';
import { CardStyled, ImgStyled, LogOutMainContainer } from "../Logout/Logout.style";


//UnAuthorize component 
export const Unauthorized = () => {

  const imgUrl = process.env.REACT_APP_HOME_URL + "/unauthorize.png";
  const mainMessage = process.env.REACT_APP_UNAUTHORIZEACCESS_MESSAGE;
  const otherMessage = process.env.REACT_APP_UNAUTHORIZE_OTHER_MESSAGE;
 

  return (
    <LogOutMainContainer>
      <PageLayout
        content={<CardStyled size='large'><ImgStyled src={imgUrl}></ImgStyled>
          <EmptyState title={mainMessage} message={otherMessage} ></EmptyState>
        </CardStyled>}
        globalHeader={{
          avatar: {
            icon: '',
            action: function noRefCheck() { },
            src: '',
            title: '',
            description: ''
          },
          subtitle: process.env.REACT_APP_APPLICATIONSUBTITLE ?? "",
          title: process.env.REACT_APP_APPLICATIONTITLE ?? ""
        }}
        variant="CENTERED_CONTENT" leftSideBar={undefined} rightSideBar={undefined} /></LogOutMainContainer>
  );
}

