import { ButtonLink, Checkbox, Grid, useSorter } from '@sede-x/shell-ds-react-framework';
import { Add, Download, Search } from '@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components';
import { ColumnsType, RecordType } from '@sede-x/shell-ds-react-framework/build/cjs/components/Table/Table.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TABLE_CLASS } from '@sede-x/shell-ds-react-framework/build/cjs/utils/constants';
import * as Styled from './AligneMapping.style';
import DataTableWithScrollBar from '../../library/Table/TableWithScrollBar';
import DataTablePagination from '../../library/Pagination/Pagination';
import AligneCurveConfigurationDrawer from './AligneMappingSideDrawer';
import { FilterContainer, FilterStyled } from './AligneMapping.style';
import { IAligneCurvePayload } from '../../interfaces/IAligneCurvePayload';
import { IPaginationFilter } from '../../interfaces/IPaginationFilter';
import { ISortCriteria } from '../../interfaces/ISortCriteria';
import { AligneCurveConfigurations } from '../../services/AligneCurveConfigurations';
import { ProductCurveConfigurations } from '../../services/ProductCurveConfigurations';
import { IAligneCurveConfigurationData } from '../../interfaces/IAligneCurveConfigurationData';
import { PDSCONSTANT } from '../../common/constants';
import { SortDirection, BtnGrpEnableDisableStatus, BtnGrpPublicPrivateStatus } from '../../common/enum';
import { AligneMappinColumnsHelper } from './AligneMappingColumnsHelper';
import { ICurveConfigurationColumnProps } from '../../interfaces/ICurveConfigurationColumnProps';
import { MasterData } from '../../services/MasterData';
import { IAligneCurveFilter } from '../../interfaces/IAligneCurveFilter';
import Loader from '../../library/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IAligneCurveDownloadFilter } from '../../interfaces/IAligneCurveDownloadFilter';
import { DownloadHelper } from '../../common/DownloadHelper';
import { UserPermission } from '../../services/UserPermission';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// For rendering date value in table
const renderDateAndTimeValue = (value: any, record: any, index: number) => {
  value = dayjs(value).format(PDSCONSTANT.DATE_FORMAT) + " | " + dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
  return (<div>{value}</div>)
};
// For rendering link in table
const renderStatus = (value: any, record: any, index: number) => {
  value = (value === true ? BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] : BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Disable]);
  return (<div>{value}</div>)
};
// For rendering string value in table
const renderValue = (value: any, record: any, index: number) => {
  return (<div>{value}</div>)
};
// React screen for Aligne Mapping
export const AligneMapping = () => {
  //object containing user permissons
  const permissions = UserPermission();
  // React state variables  
  const defaultPageSize = PDSCONSTANT.DEFAULT_PAGE_SIZE;
  const [currentPage, setPage] = useState(1);
  const [aligneMappingMainData, setAligneMappingMainData] = useState<IAligneCurveConfigurationData[]>([]);
  const [aligneCurveConfiguration, setAligneCurveConfiguration] = useState<IAligneCurveConfigurationData>({ Commodity: '', CommodityDeliveryPoint: '', CommodityProfile: '', ContractOption: '', CurveName: '', CurveType: '', Granularity: '', PriceType: '', Location: '', UnitOfMeasure: '', InterfaceApplication: '', IsActive: false, IsPrivate: false, IsChecked: false });
  const [isProcessing, setIsProcessing] = useState(false);
  const { GetProductCurveName } = ProductCurveConfigurations();
  const { GetAligneCurvesConfiguration, DownloadSelectedAligneCurveConfigurationData } = AligneCurveConfigurations();
  const { GetAligneCurvesConfigurationFilters } = MasterData();
  const [wildCardSearchText, setWildCardSearchText] = useState('');
  const aligneConfigFilter: IAligneCurveFilter = { Name: null, CurveType: [], Granularity: [], Component: [], ContractOption: [], ExportSchedule: [], ImportType: [], Settle: null, Market: [], PSET: [], SecondaryComponent: [], SecondaryMarket: [], Unit: [], ZeroCal: [], AddToMarket: [], IsActive: true, WildCardSearchText: null, EffectiveEndDate: null, EffectiveStartDate: null, ExportStartDateTime: null, ExportEndDateTime: null, Status: [] };
  const [filterCriteria, setFilterCriteria] = useState<IAligneCurveFilter>({ Name: null, CurveType: [], Granularity: [], Component: [], ContractOption: [], ExportSchedule: [], ImportType: [], Settle: null, Market: [], PSET: [], SecondaryComponent: [], SecondaryMarket: [], Unit: [], ZeroCal: [], AddToMarket: [], IsActive: true, WildCardSearchText: null, EffectiveEndDate: null, EffectiveStartDate: null, ExportStartDateTime: null, ExportEndDateTime: null, Status: [] });
  const [paginationFilter, setPaginationFilter] = useState<IPaginationFilter>({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
  const [sortCriteria, setSortCriteria] = useState<ISortCriteria[]>([]);
  const aligneCurvePayload: IAligneCurvePayload = { WildCardSearchText: wildCardSearchText, FilterCriteria: filterCriteria, PaginationFilter: paginationFilter, SortCriteria: sortCriteria };
  const [curveList, setCurveList] = useState<string[]>([]);
  const [aligneCurveConfigurations, setaligneCurveConfigurations] = useState<any>();
  //used for maintain and send the selectAll Status to backend api
  const [headerCheckbox, setHeaderCheckbox] = useState(false);
  //use to maintain the header checkbox check/uncheck status at UI only, no role in backend selectAll Status
  const [headerCheckboxUI, setHeaderCheckboxUI] = useState(false);
  //Selected Aligne Export Report Data array
  let [selectedData, setSelectedData] = useState<string[]>([]);
  //Unselected Aligne Export Report Data array
  let [unSelectedData, setUnSelectedData] = useState<string[]>([]);
  //initialize to default
  const downloadFilter: IAligneCurveDownloadFilter = { AligneCurvesFilter: filterCriteria, IsAllSelected: headerCheckbox, SelectedCurveList: selectedData, UnSelectedCurveList: unSelectedData };
  useEffect(() => {
    // Set Page Size on the basis of  Window size 
    paginationFilter.PageSize = window.innerHeight ? parseInt((window.innerHeight / 70).toFixed()) : 10;
    // Fetch the Aligne Curve configuration data on load and on change of Aligne curve  payload
    getAligneCurveConfigurationData();
  }, [wildCardSearchText, filterCriteria, paginationFilter, sortCriteria]);
  //On load curve configuration filters on load
  useEffect(() => {
    getCurveConfigurations();
    getCurvesName();
  }, []);
  // Api calls for Aligne Curve Configuration data
  const getAligneCurveConfigurationData = () => {
    // Comment for Loader - To be built    
    setAligneMappingMainData([]);
    setIsProcessing(true);
    GetAligneCurvesConfiguration(aligneCurvePayload).then(result => {
      //maintain the checked status of current page on pagination
      const currentPageData: any = result.data.Data;
      currentPageData.map((curve: any) => {
        //if HeaderChecbox is checked, uncheck only unselected curve data
        if (headerCheckbox) {
          //handle unchecked list -> marked them all unchecked
          var uncheckMe = unSelectedData.some(el => el === curve.CurveName);
          // if no curve is unchecked and headerCheckBox is true then set IsChecked property to true
          curve.IsChecked = !uncheckMe && headerCheckbox;
        }
        else {
          //handle checked list - marked them all checked             
          var checkedMe = selectedData.some(el => el === curve.CurveName);
          // in case of all curve checked set properties to True
          curve.IsChecked = checkedMe;
        }
        return curve;
      });
      //set the grid data
      setAligneMappingMainData(currentPageData);
      setIsProcessing(false);
      paginationFilter.TotalRecords = result.data.PaginationFilter.TotalRecords;
      paginationFilter.PageNumber = currentPage;
      paginationFilter.PageSize = result.data.PaginationFilte.PageSize;
    }).catch((err) => { setIsProcessing(false); });
  }
  // Method for aligne curve configuration filters load
  const getCurveConfigurations = () => { GetAligneCurvesConfigurationFilters().then(result => { setaligneCurveConfigurations(result.data) }); }
  // Method for curves name filters load
  const getCurvesName = () => { GetProductCurveName().then(result => { setCurveList(result.data) }); }
  // For creation of aligne mapping Side drawer
  const createAligneMapping = () => {
    setAligneCurveConfiguration({ Commodity: '', CommodityDeliveryPoint: '', CommodityProfile: '', ContractOption: '', CurveName: '', CurveType: '', Granularity: '', PriceType: '', Location: '', UnitOfMeasure: '', InterfaceApplication: '', IsActive: true, IsPrivate: false, IsChecked: false });
    setOpen(true);
  }
  // Method for Download product Curve data
  const downloadSelectedCurveCOnfigurationData = () => {
    //show error message if no curve is selected to download
    if (!downloadFilter.IsAllSelected && downloadFilter.SelectedCurveList.length === 0) {
      toast.error("No curve selected to download!", { position: toast.POSITION.TOP_CENTER });
      return;
    }
    // API calls for download selected product curve data 
    DownloadSelectedAligneCurveConfigurationData(downloadFilter).then(result => { DownloadHelper().ExportToCSV(PDSCONSTANT.EXPORT_FILENAME_ALIGNECURVECONFIG, result.data); });
  }
  // Call this method on click of Apply in filters
  const handleFilterCriteria = (filterValues: any) => {
    resetPageNumber();
    filterCriteria.Name = filterValues['name'];
    filterCriteria.Market = filterValues['market'];
    filterCriteria.Component = filterValues['component'];
    filterCriteria.PSET = filterValues['pset'];
    filterCriteria.ZeroCal = filterValues['zerocal'];
    filterCriteria.AddToMarket = filterValues['addToMarket'];
    filterCriteria.ImportType = filterValues['aligneimporttype'];
    filterCriteria.Settle = filterValues['Settle'];
    filterCriteria.ContractOption = filterValues['contractoptions'];
    filterCriteria.Unit = filterValues['unit'];
    filterCriteria.SecondaryMarket = filterValues['secondarymarket'];
    filterCriteria.SecondaryComponent = filterValues['secondarycomponent'];
    filterCriteria.ExportSchedule = filterValues['exportschedule'];
    // Assign state variable value to new variable to update with new state    
    let aligneMappingFilterCriteria: IAligneCurveFilter = filterCriteria;
    setFilterCriteria(aligneMappingFilterCriteria);

    //clear previous checked status on filter search
    resetCheckboxSelection();
  }
  // On Page Change updated the Pagination filter
  const handlePaginationFilter = (paginationData: IPaginationFilter) => { setPaginationFilter(paginationData); }
  // For rendering checkbox in table  
  const renderCheckbox = (value: any, record: any, index: number) => {
    //Handle the changes on check or uncheck of a particular row checkbox
    const handleChange = () => {
      // Update the particular row checked/unchecked status 
      const selectCurveData = [
        ...aligneMappingMainData.slice(0, index),
        {
          ...aligneMappingMainData[index],
          IsChecked: !aligneMappingMainData[index].IsChecked,
        },
        ...aligneMappingMainData.slice(index + 1),
      ];
      // Set the updated curve status 
      setAligneMappingMainData(selectCurveData);
      //maintain the global list
      //add if checked, and removed if unchecked                  
      var currentSelection = selectCurveData[index];
      //handle checkbox selection when header is checked/unchecked
      if (headerCheckbox) {
        //handle child checkbox selection when header checkbox is unchecked   
        //re-factored separate method to reduce congnative complexity - sonar issue fix
        handleChildSelectionWhenHeaderIsChecked(currentSelection);
      }
      else {
        //handle when header checkbox is unchecked
        if (currentSelection.IsChecked) {
          // If curve data is checked push curve name in selected curve list
          selectedData.push(currentSelection.CurveName);
        }
        else {
          // If curve data is unchecked pop curve name from selected curve list
          selectedData = selectedData.filter(function (item) {
            return item !== currentSelection.CurveName
          });
          // Set Header checkbox to unchecked
          setHeaderCheckboxUI(false);
        }

        //if all item selected -> checked header checkbox
        if (selectedData.length === paginationFilter.TotalRecords && unSelectedData.length === 0) {
          // Set Header checkbox to checked
          setHeaderCheckboxUI(true);
        }
        // Assign state variable value to new variable to update with new state    
        let selectedAligneMappingData: string[] = selectedData;
        //clear the selected list
        setSelectedData(selectedAligneMappingData);
        //clear the unselected list
        setUnSelectedData([]);
      };
    }
    // Rendering the checkbox for row            
    return <Checkbox crossOrigin='false' label="" size='small' id={index.toString()} checked={value} onChange={handleChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />;
  };
  // reset checkbox selections
  const resetCheckboxSelection = () => {
    //clear all selection and headerCheck to false
    setSelectedData([]);
    // Setting the header checkbox on click 
    setHeaderCheckbox(false);
  };
  // For rendering check box all functionality 
  const handleCheckboxAll = () => {
    // In case of clicking on select/unselect all check box render the curve data again    
    const checkedCurveData = aligneMappingMainData.map((item) => ({ ...item, IsChecked: !headerCheckbox }));
    // Setting the curve data again
    setAligneMappingMainData(checkedCurveData);
    // Populate the array with checked curve names
    const selectedCurveData = checkedCurveData.filter((obj) => {
      return obj.IsChecked === true;
    }).map(a => a.CurveName);

    //clear checkbox selection list as all is selected
    setSelectedData([]);
    setUnSelectedData([]);
    // Setting the header checkbox on click 
    setHeaderCheckbox(!headerCheckbox);
    setHeaderCheckboxUI(!headerCheckbox);
  };
  // handle child checkbox selection when header is checked
  const handleChildSelectionWhenHeaderIsChecked = (currentSelection: any) => {
    // If Current Curve is unchecked push curve data to unselected curve list
    if (!currentSelection.IsChecked) {
      unSelectedData.push(currentSelection.CurveName);
      //also uncheck the global UI checkbox
      setHeaderCheckboxUI(false);
    }
    else {
      unSelectedData = unSelectedData.filter(function (item) {
        return item !== currentSelection.CurveName
      });
      //checked header check box checked, if no unchecked
      if (unSelectedData.length === 0) {
        setHeaderCheckboxUI(true);
      }
    }
    // Assign state variable value to new variable to update with new state    
    let unselectetAligneMappingData: string[] = unSelectedData;
    //clear the selected list
    setUnSelectedData(unselectetAligneMappingData);
    //clear the unselected list
    setSelectedData([]);
  };
  //Set the Page Number to 1 
  const resetPageNumber = () => {
    setPage(1);
    setPaginationFilter({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
  }
  // Filtering the disable curves
  const onDisabled = (value: any) => {
    resetPageNumber();
    filterCriteria.IsActive = !value;
    // Assign state variable value to new variable to update with new state    
    let alingneMappingFilterCriteria: IAligneCurveFilter = filterCriteria;
    setFilterCriteria(alingneMappingFilterCriteria);
    getAligneCurveConfigurationData();
  };
  // For rendering link in table
  const renderLinkValue = (value: any, record: any, index: number) => {
    return (<ButtonLink onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={() => {
      var aligneCurveData = aligneMappingMainData[index];
      aligneConfigFilter.Name = aligneCurveData.CurveName;
      //passing clone object to prevent the state change in original object
      const clone = JSON.parse(JSON.stringify(aligneCurveData));
      setAligneCurveConfiguration(clone);
      setOpen(true);
    }} >{value}</ButtonLink>)
  };
  // For Right Overlay state variable for Drawer component open state
  const [open, setOpen] = useState(false);
  const onClose = (isLoading: boolean = false, message: string = "") => {
    if (isLoading) {
      toast.success(message, { position: toast.POSITION.TOP_CENTER });
      getAligneCurveConfigurationData();
      getCurveConfigurations();
    }
    setOpen(false);
  };
  // Passing render methods as a props in AligneCurveConfigurationColumnHelper
  const aligneCurveConfigurationColumnProps: ICurveConfigurationColumnProps = { renderCheckbox: renderCheckbox, renderLinkValue: renderLinkValue, renderValue: renderValue, handleCheckboxAll: handleCheckboxAll, headerCheckbox: headerCheckbox, renderStatus: renderStatus, renderDateAndTimeValue: renderDateAndTimeValue };
  const aligneCurveConfigurationColumns = AligneMappinColumnsHelper(aligneCurveConfigurationColumnProps);
  // For Sorting 
  const [transformSorterColumns, sortStates] = useSorter<RecordType>({ prefixCls: TABLE_CLASS, columns: aligneCurveConfigurationColumns });
  useMemo(() => {
    const SortCriteriaResult: ISortCriteria[] = [];
    for (var sortNumber in sortStates) {
      if (Object.hasOwn(sortStates, sortNumber)) {
        const SortCriterion: ISortCriteria = { ColumnName: '', Direction: '' };
        if (sortStates[sortNumber].sortOrder !== undefined) {
          SortCriterion.ColumnName = sortStates[sortNumber].column.dataIndex?.toString() ?? "";
          SortCriterion.Direction = sortStates[sortNumber].sortOrder === 'ascend' ? SortDirection[SortDirection.DESC] : SortDirection[SortDirection.ASC];
          SortCriteriaResult.push(SortCriterion);
        }
      }
    }
    setSortCriteria(SortCriteriaResult);
  }, [sortStates]);
  // For changing the header sort icons
  const transformColumns = useCallback(
    (innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> => transformSorterColumns(innerColumns), [transformSorterColumns]
  );
  // Passing the columns 
  const transformedColumns = transformColumns(aligneCurveConfigurationColumns);
  return (
    <>{isProcessing && <Loader />}
      <AligneCurveConfigurationDrawer onClose={onClose} open={open} {...aligneCurveConfigurations} curveList={curveList} selectedCurve={aligneCurveConfiguration} />
      <Styled.InnerContainer> <Styled.Sidebar>
        <FilterContainer><FilterStyled onApply={handleFilterCriteria} masterData={aligneCurveConfigurations}></FilterStyled></FilterContainer>
      </Styled.Sidebar>
        <Styled.ContentContainer><Styled.CenteredContainer><Styled.SideGrid>
          <Styled.GridStyled columns={2}>
            <Grid.Cell><b>Aligne Mapping</b></Grid.Cell>
            <Grid.Cell>
              <Styled.FlexboxStyled gap='24px;'>
                {/* create will be allowed as per authorization */}
                {permissions.CanWrite_AligneMapping() && <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={createAligneMapping} icon={<Add />}>Create Mapping</Styled.ButtonStyled>}
                <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={downloadSelectedCurveCOnfigurationData} variant='outlined' icon={<Download />}>Export Data</Styled.ButtonStyled>

              </Styled.FlexboxStyled>
            </Grid.Cell>
          </Styled.GridStyled>
          <Grid columns={1}><Grid.Cell> <hr /> </Grid.Cell></Grid>
          <Styled.GridStyled columns={2} >
            <Grid.Cell ><Styled.TextInputStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' size='small' icon={<Search height={24} />} placeholder="Search" onKeyUp={(e: any) => {
              // Fire event on enter press
              if (e.keyCode === 13) {
                resetPageNumber();
                setPage(1);
                paginationFilter.PageNumber = 1;
                // Setting the value enetered in search textbox
                filterCriteria.WildCardSearchText = e.target.value;
                setWildCardSearchText(filterCriteria.WildCardSearchText ?? "");
                // Assign state variable value to new variable to update with new state    
                let aligneMappingFilterCriteria: IAligneCurveFilter = filterCriteria;
                // Set the filtercriteria with the updated text value
                setFilterCriteria(aligneMappingFilterCriteria);
                // Calling the Aligne mapping method for fetching
                getAligneCurveConfigurationData();
              }
            }} />
            </Grid.Cell><Grid.Cell ><Styled.ToggleStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' label="View Disabled Mappings" onChange={onDisabled} labelPosition="left" size="medium" /></Grid.Cell>
          </Styled.GridStyled>
          <Grid rowGap="24px"><DataTableWithScrollBar data={aligneMappingMainData} columns={transformedColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} />
            <DataTablePagination currentPage={paginationFilter.PageNumber} pageSize={paginationFilter.PageSize} total={paginationFilter.TotalRecords} setPage={setPage} onPageChange={handlePaginationFilter} />
          </Grid> </Styled.SideGrid></Styled.CenteredContainer></Styled.ContentContainer></Styled.InnerContainer>
      <ToastContainer />
    </>);
}