import './App.css';
import { ShellThemeProvider} from '@sede-x/shell-ds-react-framework';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { useAuth } from "./common/hooks/auth-provider";
import { SigninCallback } from "./components/pages/signin-callback";
import { Signin } from "./components/pages/signin";
import { SilentSigninCallback } from "./components/pages/silent-signin-callback";
import { AppLayout } from "./layout/AppLayout";
import routepaths from './router/routepaths';
import Loader from './library/Loader/Loader';

export const App = () => {
  // isLoading to check whether the AuthContext provider is loaded or not
  const { isLoading} = useAuth();
  const isSignInURL:boolean = window.location.href.indexOf('signin-callback')!==-1;
  let layout:any;
  //Render the app after the auth provider is done loading. 
  if (isLoading) {   
    return (
      <>
      <Loader/>
      </>
    )
  }
// No loading of AppLayout page in case of Sign-In call back page.
 if(!isSignInURL)
 {
  layout= <AppLayout />;
 } 
 
 // Routing and Loading of App component
 // Shell theme provider is imported from @sede-x/shell-ds-react-framework. Documentation is available in sede-x repo
  return (
    
    <div className="App">
    <BrowserRouter>
     <ShellThemeProvider theme={'light'}>  
      {layout}
      <Routes>
       <Route path={routepaths.signin} element={ <Signin />} />
       <Route path={routepaths.signincallback}  element={ <SigninCallback />} />   
       <Route path={routepaths.signinsilent} element={ <SilentSigninCallback />} />                       
      </Routes>
      </ShellThemeProvider>
    </BrowserRouter>
    </div>    
  );
};
