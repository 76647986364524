import {
  Checkbox,
  ColumnsType,
  RecordType,
  Tooltip,
  Text,
} from "@sede-x/shell-ds-react-framework";
import { ICurveViewerColumnProps } from "../../interfaces/ICurveViewerColumnProps";
import { HeaderText } from "./CurveViewerMain.styles";
import { PDSCONSTANT } from "../../common/constants";
import React from "react";

// Columns for Curve Viewer Table
export const CurveViewerColumnsHelper = (
  props: ICurveViewerColumnProps
): ColumnsType<RecordType> => {
  const curveViewerColumns: ColumnsType<RecordType> = [
    {
      dataIndex: "IsChecked",
      key: "IsChecked",
      render: props.renderCheckbox,
      title: (
        <Checkbox
          crossOrigin="false"
          size="small"
          label=""
          disabled
          checked={props.headerCheckbox}
          onChange={props.handleCheckboxAll}
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        />
      ),
    },
    {
      dataIndex: "CurveName",
      key: "CurveName",
      title: "Name",
      sorter: {
        compare: (a, b): number => {
          if (a.name !== b.name) {
            return a.name < b.name ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderLinkValue,
    },
    {
      dataIndex: "Granularity",
      key: "Granularity",
      title: "Granularity",
      sorter: {
        compare: (a, b): number => {
          if (a.granularity !== b.granularity) {
            return a.granularity < b.granularity ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "CurveType",
      key: "CurveType",
      title: "Curve Type",
      sorter: {
        compare: (a, b): number => {
          if (a.curvetype !== b.curvetype) {
            return a.curvetype < b.curvetype ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "EffectiveDate",
      key: "EffectiveDate",
      title: "Effective Date",
      sorter: {
        compare: (a, b): number => {
          if (a.effectivedate !== b.effectivedate) {
            return a.effectivedate < b.effectivedate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["descend", "ascend"],
      render: props.renderDateValue,
    },
    {
      dataIndex: "SourceUpdatedDate",
      key: "SourceUpdatedDate",
      title: (
        <Tooltip
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
          placeholder=""
          placement="topLeft"
          overlay={PDSCONSTANT.TOOLTIP_TEXT_SOURCE_LAST_UPDATED}
        >
          <span>Last Updated (Source)</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b): number => {
          if (a.SourceUpdatedDate !== b.SourceUpdatedDate) {
            return a.SourceUpdatedDate < b.SourceUpdatedDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["descend", "ascend"],
      render: props.renderSourceDateAndTimeValue,
    },
    {
      dataIndex: "UpdatedDate",
      key: "UpdatedDate",

      title: (
        <Tooltip
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
          placeholder=""
          placement="topLeft"
          overlay={PDSCONSTANT.TOOLTIP_TEXT_PDS_LAST_UPDATED}
        >
          <span>Last Updated (PDS)</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b): number => {
          if (a.UpdatedDate !== b.UpdatedDate) {
            return a.UpdatedDate < b.UpdatedDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "ascend",
      render: props.renderDateAndTimeValue,
    },    
    {
      dataIndex: "TraderApprovalIndicator",
      key: "TraderApprovalIndicator",
      title: "Trader Approved",
      sorter: {
        compare: (a, b): number => {
          if (a.TraderApprovalIndicator !== b.TraderApprovalIndicator) {
            return a.TraderApprovalIndicator < b.TraderApprovalIndicator
              ? -1
              : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderTraderApproval,
    },
    {
      dataIndex: "TraderApprovalName",
      key: "TraderApprovalName",
      title: "Approved By",
      sorter: {
        compare: (a, b): number => {
          if (a.TraderApprovalName !== b.TraderApprovalName) {
            return a.TraderApprovalName < b.TraderApprovalName
              ? -1
              : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
    {
      dataIndex: "IsComplete",
      key: "IsComplete",
      title: (
        <Tooltip
          onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
          placeholder=""
          placement="topLeft"
          overlay={PDSCONSTANT.TOOLTIP_TEXT_IS_CURVE_COMPLETE_HEADER}
        >
          <span>Curve Price Data complete</span>
        </Tooltip>
      ),
      sorter: {
        compare: (a, b): number => {
          if (a.IsComplete !== b.IsComplete) {
            return a.IsComplete < b.IsComplete ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderCompletionFlow,
    },

    {
      dataIndex: "InterfaceApplication",
      key: "InterfaceApplication",
      title: "Source",
      sorter: {
        compare: (a, b): number => {
          if (a.InterfaceApplication !== b.InterfaceApplication) {
            return a.InterfaceApplication < b.InterfaceApplication ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ["ascend", "descend"],
      render: props.renderValue,
    },
  ];

  return curveViewerColumns;
};
