import { Checkbox, ColumnsType, RecordType, Tooltip, Text } from "@sede-x/shell-ds-react-framework";
import { PDSCONSTANT } from "../../common/constants";
import { ICalendarDayColumnProps } from "../../interfaces/ICalendarDayColumnProps";
import { HeaderText } from "../CurveViewer/CurveViewerMain.styles";
import React from "react";

// Columns for Calendar Configuration Table
export const CalendarConfigurationColumnsHelper = (props: ICalendarDayColumnProps): ColumnsType<RecordType> => {
    const calendarConfigurationColumns: ColumnsType<RecordType> = [
        {
            dataIndex: 'IsChecked',
            key: 'IsChecked',
            render: props.renderCheckbox,
            title: <Checkbox crossOrigin='false' size='small' label="" checked={props.headerCheckbox} onChange={props.handleCheckboxAll} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        },
        {
            dataIndex: 'Description',
            key: 'Description',
            title: 'Description',
            sorter: {
                compare: (a, b): number => {
                    if (a.Description !== b.Description) {
                        return a.Description < b.Description ? -1 : 1;
                    }
                    return 0;
                },
            },
            sortDirections: ['ascend', 'descend'],
            render: props.renderLinkValue
        },
        {
            dataIndex: 'Date',
            key: 'Date',
            title: 'Date',
            sorter: {
                compare: (a, b): number => {
                    if (a.Date !== b.Date) {
                        return a.Date < b.Date ? -1 : 1;
                    }
                    return 0;
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: props.renderDateValue
        },
        {
            dataIndex: 'Name',
            key: 'Name',
            title: 'Calendar Name',
            sorter: {
                compare: (a, b): number => {
                    if (a.Name !== b.Name) {
                        return a.Name < b.Name ? -1 : 1;
                    }
                    return 0;
                },
            },
            sortDirections: ['descend', 'ascend'],
            render: props.renderValue
        },
        {
            dataIndex: 'IsActive',
            key: 'IsActive',
            title: 'Status',
            sorter: {
                compare: (a, b): number => {
                    if (a.IsActive !== b.IsActive) {
                        return a.IsActive < b.IsActive ? -1 : 1;
                    }
                    return 0;
                },
            },
            sortDirections: ['ascend', 'descend'],
            render: props.renderStatus
        },

        {
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',

            title: <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placement="topLeft" placeholder="" overlay={PDSCONSTANT.TOOLTIP_TEXT_PDS_LAST_UPDATED}>
                <span>Last Updated</span>
            </Tooltip>,
            sorter: {
                compare: (a, b): number => {
                    if (a.UpdatedDate !== b.UpdatedDate) {
                        return a.UpdatedDate < b.UpdatedDate ? -1 : 1;
                    }
                    return 0;
                },
            },
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'ascend',
            render: props.renderDateAndTimeValue
        },
        {
            dataIndex: 'UpdatedBy',
            key: 'UpdatedBy',
            title: 'UpdatedBy',
            sorter: {
                compare: (a, b): number => {
                    if (a.UpdatedBy !== b.UpdatedBy) {
                        return a.UpdatedBy < b.UpdatedBy ? -1 : 1;
                    }
                    return 0;
                },
            },
            sortDirections: ['ascend', 'descend'],
            render: props.renderValue
        }

    ];
    return (calendarConfigurationColumns);
}
