
import { APIURL } from "../common/api-url";
import { AxiosInstance } from "axios";
import { HTTPClient } from "../common/apiservice";
import { HttpHeader } from "../common/HttpHeader";
import { useAuth } from "../common/hooks/auth-provider";
import { ICurveReferenceDataPayload } from "../interfaces/ICurveReferenceDataPayload";
import { ICurveReferenceDataRequest } from "../interfaces/ICurveReferenceDataRequest";
import { IDownloadCurveReferenceFilter } from "../interfaces/IDownloadFilter";
import { ICurveReferenceTimezoneDataRequest } from "../interfaces/ICurveReferenceTimezoneDataRequest";
import { ICurveReferenceData } from "../interfaces/ICurveReferenceData";
import { ICurveReferenceFilter } from "../interfaces/ICurveReferenceFilter";
// Service class for Granularity
export const MasterData = () => {

  const { appUser } = useAuth();
  //setup http header
  var config = HttpHeader().GetHeader(appUser?.user?.access_token, process.env.REACT_APP_HEADER_API_VERSION);
  // Instance of Axios to call get and post method
  const _httpclient: AxiosInstance = HTTPClient(config);

  // Method for fetching Granularities
  async function GetGranularities() {
    return await _httpclient.get(APIURL.GRANULARITIES_URL);
  }

  // Method for fetching CurveTypes
  async function GetCurveTypes() {
    return await _httpclient.get(APIURL.CURVETYPE_URL)
  }

  // Method for fetching Sources
  async function GetSources() {
    return await _httpclient.get(APIURL.SOURCES_URL)
  }

  // Method for fetching Product Curve Configuration Filters
  async function GetProductCurvesConfigurationFilters() {

    const result = _httpclient.get(APIURL.PRODUCT_CURVE_CONFIGURATION_FILTERS_URL)
    return result;
  }

  // Method for fetching Aligne Curve Configuration Filters
  async function GetAligneCurvesConfigurationFilters() {

    const result = _httpclient.get(APIURL.ALIGNE_CURVE_CONFIGURATION_FILTERS_URL)
    return result;
  }

  // Method for fetching Manual Export filter
  async function GetManualExportFilters() {

    const result = _httpclient.get(APIURL.ALIGNE_MANUAL_EXPORT_FILTERS_URL)
    return result;
  }

  // Method for fetching ALigne Export Status Report filter
  async function GetAligneExportStatusReportFilters() {

    const result = _httpclient.get(APIURL.ALIGNE_EXPORT_STATUS_REPORT_FILTERS_URL)
    return result;
  }

  function GetBooleanValueFilters() {

    return [{ text: "FALSE", value: "FALSE" }, { text: "TRUE", value: "TRUE" }]
  }

  function GetCurveReferenceDataAttributes(curveReferenceMetaDataFilter: ICurveReferenceDataPayload) {

    const result = _httpclient.post(APIURL.CURVE_META_DATA_ATTRIBUTE_URL, curveReferenceMetaDataFilter)
    return result;
  }

  async function CreateCurveReferenceData(curveReferenceMetaDataRequest: ICurveReferenceDataRequest[]) {
    const result = await _httpclient.post(APIURL.CREATE_REFERENCE_DATA_URL, curveReferenceMetaDataRequest);
    return result;
  }

  // Method for downloading Reference Data based on downlaodFiter     
  async function DownloadSelectedReferenceData(downloadFilter: IDownloadCurveReferenceFilter) {

    return await _httpclient.post(APIURL.DOWNLOAD_REFERENCE_DATA_URL, downloadFilter);
  }

  function GetReferenceDataTimezoneAttributes(curveReferenceMetaDataFilter: ICurveReferenceDataPayload) {

    const result = _httpclient.post(APIURL.TIMEZONE_META_DATA_URL, curveReferenceMetaDataFilter)
    return result;
  }

  async function UpdateTimezoneReferenceData(curveReferencDataTimezoneRequest: ICurveReferenceTimezoneDataRequest) {
    const result = await _httpclient.post(APIURL.UPDATE_REFERENCE_DATA_TIMEZONE_URL, curveReferencDataTimezoneRequest);
    return result;
  }

  // Method for downloading Reference Data based on downlaodFiter     
  async function DownloadSelectedReferenceDataTimezone(downloadFilter: IDownloadCurveReferenceFilter) {

    return await _httpclient.post(APIURL.DOWNLOAD_REFERENCE_TIMEZONE_DATA_URL, downloadFilter);
  }
  //method to get audit history for a particular reference attribute item
  function GetReferenceDataAttributeAuditHistory(curveReferenceDataFilter: ICurveReferenceFilter) {

    const result = _httpclient.post(APIURL.CURVE_REFERENCE_ATTRIBUTE_AUDIT_HISTORY_URL, curveReferenceDataFilter)
    return result;
  }

  return {
    GetGranularities,
    GetCurveTypes,
    GetSources,
    GetProductCurvesConfigurationFilters,
    GetAligneCurvesConfigurationFilters,
    GetManualExportFilters,
    GetAligneExportStatusReportFilters,
    GetBooleanValueFilters,
    GetCurveReferenceDataAttributes,
    CreateCurveReferenceData,
    DownloadSelectedReferenceData,
    GetReferenceDataTimezoneAttributes,
    UpdateTimezoneReferenceData,
    DownloadSelectedReferenceDataTimezone,
    GetReferenceDataAttributeAuditHistory
  };
}