import { PDSCONSTANT } from "./constants";
import  dayjs from 'dayjs';
import { EffectiveDateFilter } from "../interfaces/IEffectiveDateFilter";

// Helper methods for Date Range filter
export const dateFilterHelper =()=>
{     

 
    // Setting Default Effective Start Date
  const effectiveStartDate = new Date(new Date().setDate(new Date().getDate() - PDSCONSTANT.ONE_DAY_BEFORE_CURRENT_DATE));
  // Setting effective start Date hours, minutes and milliseconds to Zero
  effectiveStartDate.setUTCHours(0, 0, 0, 0);

  // Setting Seven days before date
  const sevenDaysBeforeDate = new Date(new Date().setDate(new Date().getDate() - PDSCONSTANT.SEVEN_DAYS_BEFORE_CURRENT_DATE));
  // Setting Seven days before date hours, minutes and milliseconds to Zero
  sevenDaysBeforeDate.setUTCHours(0, 0, 0, 0);
  // Setting Default Effective End Date
  const effectiveEndDate = new Date();
  // Setting effective End Date hours, minutes and milliseconds to Zero
  effectiveEndDate.setUTCHours(0, 0, 0, 0);

  
 // Setting Default Export Start Date
 const exportStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
 // Setting effective start Date hours, minutes and milliseconds to Zero
 exportStartDate.setHours(0, 0, 0, 0);  

// Setting Default Effective End Date
const exportEndDate = new Date(new Date().setDate(new Date().getDate() + 1));
// Setting effective End Date hours, minutes and milliseconds to Zero
exportEndDate.setHours(0, 0, 0, 0);  
  // Setting StartDateRange and EndDateRange state variable
  const effectiveStartDateRange = dayjs(effectiveStartDate);
  const effectiveEndDateRange = dayjs(effectiveEndDate);

  // Setting StartDateRange and EndDateRange state variable
  const exportStartDateRange = dayjs(exportStartDate);
  const exportEndDateRange= dayjs(exportEndDate);


   // Initiating the Effective Date Range
 const effectiveDateRange: EffectiveDateFilter ={EffectiveStartDate:effectiveStartDateRange, EffectiveEndDate:effectiveEndDateRange}; 
 // Initiating the Export Date Range
 const exportDateRange: EffectiveDateFilter ={EffectiveStartDate:exportStartDateRange, EffectiveEndDate:exportEndDateRange}; 
  const getDefaultEffectiveDate=():EffectiveDateFilter=>{    
    effectiveDateRange.EffectiveStartDate =effectiveStartDateRange;
    effectiveDateRange.EffectiveEndDate =effectiveEndDateRange;
    return effectiveDateRange;

  }

  const getDefaultExportDate=():EffectiveDateFilter=>{    
    exportDateRange.EffectiveStartDate =exportStartDateRange;
    exportDateRange.EffectiveEndDate =exportEndDateRange;
    return exportDateRange;

  }

   const onChangeDateRange = (dates: { $d: dayjs.Dayjs | Date | null ; }[] | null) : EffectiveDateFilter=> {
    if (dates != null) {
      
        effectiveDateRange.EffectiveStartDate=dayjs(dates[0].$d);
        effectiveDateRange.EffectiveEndDate =dayjs(dates[1].$d);
     
    }
    else {
        effectiveDateRange.EffectiveStartDate =dayjs(sevenDaysBeforeDate);
        effectiveDateRange.EffectiveEndDate =dayjs(effectiveEndDate);   
    }
    return effectiveDateRange
}

const resetDateRange = () : EffectiveDateFilter=> {
 
        effectiveDateRange.EffectiveStartDate =dayjs(effectiveStartDateRange);
        effectiveDateRange.EffectiveEndDate =dayjs(effectiveEndDateRange);   
   
    return effectiveDateRange
}
const resetExportDateRange = () : EffectiveDateFilter=> {
 
  exportDateRange.EffectiveStartDate =dayjs(exportStartDateRange);
  exportDateRange.EffectiveEndDate =dayjs(exportEndDateRange);   

return exportDateRange
}

  return {
           
    getDefaultEffectiveDate,
    onChangeDateRange,
    resetDateRange,
    getDefaultExportDate,
    resetExportDateRange
 };
   
}