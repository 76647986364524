
import { AxiosInstance } from "axios";
import { HTTPClient } from "../common/apiservice";
import { HttpHeader } from "../common/HttpHeader";
import { useAuth } from "../common/hooks/auth-provider";
import { APIURL } from "../common/api-url";
import { ICalendarDayPayload } from "../interfaces/ICalendarDayPayload";
import { ICalendarDayConfigurationRequest } from "../interfaces/ICalendarDayConfigurationRequest";
import { ICalendarDayDownloadFilter } from "../interfaces/ICalendarDayDownloadFilter";

export const CalendarConfigurations = () => {
    const { appUser } = useAuth();
    //setup http header
    var config = HttpHeader().GetHeader(appUser?.user?.access_token, process.env.REACT_APP_HEADER_API_VERSION);
    // Instance of Axios to call get and post method
    const _httpclient: AxiosInstance = HTTPClient(config);
    // Method for fetching GetCalendars List
    async function GetCalendars() {
        const result = await _httpclient.get(APIURL.CALENDARDAY_LIST_URL);
        return result;
    }
    // Method for fetching GetCalendar Names List
    async function GetCalendarNames() {
        const result = await _httpclient.get(APIURL.CALENDAR_NAMES_URL);
        return result;
    }
    // Method for fetching GetCalendar day filtered List
    async function GetCalendarDaysFiltered(calendarDayFilter: ICalendarDayPayload) {
        const result = await _httpclient.post(APIURL.CALENDARDAY_FILTERED_LIST_URL, calendarDayFilter);
        return result;
    }
    // Method for updating Calendar day object
    async function UpsertCalendarDay(calendarDayFilter: ICalendarDayConfigurationRequest) {
        const result = await _httpclient.post(APIURL.CALENDARDAY_SAVE_URL, calendarDayFilter);
        return result;
    }
    // Method for deleting Calendar day object
    async function DeleteCalendarDay(calendarDayFilter: ICalendarDayConfigurationRequest) {
        const result = await _httpclient.post(APIURL.CALENDARDAY_DELETE_URL, calendarDayFilter);
        return result;
    }
    // Method for downloading calendar day data    
    async function DownloadSelectedCalendarDayData(downloadFilter: ICalendarDayDownloadFilter) {

        return await _httpclient.post(APIURL.CALENDAR_DOWNLOAD_URL, downloadFilter);
    }
    return {
        GetCalendars,
        UpsertCalendarDay,
        GetCalendarDaysFiltered,
        GetCalendarNames,
        DeleteCalendarDay,
        DownloadSelectedCalendarDayData
    };
}