// Route Paths
// Routing path
export default {
  home: '/',
  curveviewer: '/curveviewer',
  curveconfiguration: '/curveconfiguration',
  alignemapping: '/alignemapping',
  aligneexportreport: '/aligneexportreport',
  manualaligneexport: '/manualaligneexport',
  calendarconfiguration: '/calendarconfiguration',
  curvegranularityattribute: '/curvegranularityattribute',
  curvetypeattribute: '/curvetypeattribute',
  curvepricetypeattribute: '/curvepricetypeattribute',
  curvecurrencyattribute: '/curvecurrencyattribute',
  curveunitattribute: '/curveunitattribute',
  curvelocationattribute: '/curvelocationattribute',
  curvecommodityattribute: '/curvecommodityattribute',
  curvedeliverypointattribute: '/curvedeliverypointattribute',
  curveprofileattribute: '/curveprofileattribute',
  curvedataproviderattribute: '/curvedataproviderattribute',
  curvesourceproviderattribute: '/curvesourceproviderattribute',
  timezoneidentifierattribute: '/timezoneidentifierattribute',
  signincallback: '/signin-callback',
  signinsilent: '/callback-silent-signin',
  signin: '/signin'
};
