import { Loading, ShellThemeProvider } from "@sede-x/shell-ds-react-framework";
// Loader 
export default function Loader() { 
    return ( <div className='overlay'>          
        <div className='center'>
        <ShellThemeProvider theme={'light'}>  
          <Loading type='primary'/>    
          </ShellThemeProvider>            
         </div>
      </div>)
}