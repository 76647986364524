import React from "react";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../../common/hooks/auth-provider";

export const SigninCallback = () => {
  const { signinCallback } = useAuth();
  const history = useNavigate();

  React.useEffect(() => {
    // Complete the sign in, the tokens should be provided
    // in the url at this point to be stored and then redirect
    // back to either the returnTo path or to the home page
    const handleSignin = async () => {
      const user = await signinCallback();
      history(user?.state?.returnTo ?? "/");
    };
    handleSignin();
  }, [signinCallback, history]);

  return<>
  <div className='overlay'>          
      <div className='center'>
      <ClipLoader size={50} /> 
               
      </div>
  </div>
  </>;
};
