import { ButtonLink, Checkbox, FormField, Grid, TextArea, TextInput, Tooltip, useSorter } from '@sede-x/shell-ds-react-framework';
import { Add, CopyFileSolid, Download, Edit, EditSolid, EditTableSolid, Search } from '@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components';
import { ColumnsType, RecordType } from '@sede-x/shell-ds-react-framework/build/cjs/components/Table/Table.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TABLE_CLASS } from '@sede-x/shell-ds-react-framework/build/cjs/utils/constants';
import * as Styled from './AligneExportReport.style';
import DataTableWithScrollBar from '../../library/Table/TableWithScrollBar';
import DataTablePagination from '../../library/Pagination/Pagination';
import { FilterContainer, FilterStyled } from './AligneExportReport.style';
import { IAligneCurvePayload } from '../../interfaces/IAligneCurvePayload';
import { IPaginationFilter } from '../../interfaces/IPaginationFilter';
import { ISortCriteria } from '../../interfaces/ISortCriteria';
import { AligneExportStatusReport } from '../../services/AligneExportStatusReport';
import { IAligneExportReportData } from '../../interfaces/IAligneExportReportData';
import { PDSCONSTANT } from '../../common/constants';
import { SortDirection, ExportStatus } from '../../common/enum';
import { AligneExportReportColumnsHelper } from './AligneExportReportColumnsHelper';
import { IAligneExportReportColumnProps } from '../../interfaces/IAligneExportReportColumnProps';
import { MasterData } from '../../services/MasterData';
import { IAligneCurveFilter } from '../../interfaces/IAligneCurveFilter';
import Loader from '../../library/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { IAligneCurveDownloadFilter } from '../../interfaces/IAligneCurveDownloadFilter';
import { DownloadHelper } from '../../common/DownloadHelper';
import { IMasterData } from '../../interfaces/IMasterData';
import DataTableWithVerticalScrollBar from '../../library/Table/TableWithVerticalScrollBar';
import { IAligneResponseData } from '../../interfaces/IAligneResponseData';
import { IAligneResponsePayload } from '../../interfaces/IAligneResponsePayload';
import { IAligneResponseFilter } from '../../interfaces/IAligneResponseFilter';
import { IManualAligneExportPopupColumnProps } from '../../interfaces/IManualAligneExportPopupColumnProps';
import { AligneResponsePopupColumnsHelper } from './AligneResponseColumnsHelper';
import { TransformAligneMessageToPDS } from '../../common/validationHelper';
import { IAligneUpdateModel } from '../../interfaces/IAligneUpdateModel';
import { IAligneUpdateData } from '../../interfaces/iAligneUpdateData';
import { AligneExportReportPopupColumnsHelper } from './AligneExportReportPopupColumnsHelper';
import { useAuth } from '../../common/hooks/auth-provider';
import { UserPermission } from '../../services/UserPermission';
import DataTableWithOnlyVerticalScrollBar from '../../library/Table/TableWithOnlyVerticalScrollBar';



// Declaring the rendering methods
// For rendering  date value in table
const renderDateValue = (value: any, record: any, index: number) => {
  value = dayjs(value).format(PDSCONSTANT.DATE_FORMAT);
  return (<div>{value}</div>)
};
  // For rendering source date value in table
  const renderSourceDateAndTimeValue = (value: any, record: any, index: number) => {
    
    if(value === null || value ===undefined || value ==='') {
      value = '';
    }else if (dayjs(value).isValid()) {
      value = dayjs(value).utc().format(PDSCONSTANT.DATE_FORMAT) + " | " + dayjs(value).utc().format(PDSCONSTANT.TIME_FORMAT);
    }
    else {
      value = '';
    }
    return (<div>{value}</div>)
  };

// Add 0 if number is lesser than 10
const pad = (num: number) => {
  return (num < 10 ? '0' : '') + num;
};
// Method to change date into ISO format string
const toIsoString = (date: Date) => {
  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T00:00:00Z'
};
var effectiveStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
// Setting effective start Date hours, minutes and milliseconds to Zero
effectiveStartDate.setUTCHours(0, 0, 0, 0);
// Setting Default Effective End Date
var effectiveEndDate = new Date();
// Setting effective End Date hours, minutes and milliseconds to Zero
effectiveEndDate.setUTCHours(0, 0, 0, 0); 

// Setting Default Export Start Date
var exportStartDate = new Date(new Date().setDate(new Date().getDate() - 1));
// Setting effective start Date hours, minutes and milliseconds to Zero
exportStartDate.setHours(0, 0, 0, 0); 

// Setting Default Effective End Date
var exportEndDate = new Date(new Date().setDate(new Date().getDate() + 1));
// Setting effective End Date hours, minutes and milliseconds to Zero
exportEndDate.setHours(0, 0, 0, 0);  

// React screen for AligneExportReport
export const AligneExportReport = () => { 

  // Rendering notes
const renderNotes = (value: any, record: any, index: number) => {
  if(value!==null && value.length>20)
  {
    return (
      <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" placement="topLeft" overlay={value}><div>{value.substring(0,20)}...</div></Tooltip>)
  }
  else{
    return (<div>{value}</div>)
  }
  
};
  // React state variables  
  const defaultPageSize = 10;
  const [currentPage, setPage] = useState(1);
  const [notes, setNotes] = useState('');
  const [aligneExportReportData, setAligneExportReportData] = useState<IAligneExportReportData[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const { GetAligneExportStatusReport,DownloadSelectedAligneExportReportData,GetAligneResponseStatus,UpdateAligneExportReportData } = AligneExportStatusReport();
  const { GetAligneExportStatusReportFilters } = MasterData();
  const [wildCardSearchText, setWildCardSearchText] = useState('');
  const [exportReportUpdatefilterCriteria, setExportReportUpdateFilterCriteria] = useState<IAligneExportReportData>();
  const [exportReportUpdateDataCriteria, setExportReportUpdateDataCriteria] = useState<IAligneUpdateData>();
  const [filterCriteria, setFilterCriteria] = useState<IAligneCurveFilter>({Name: null, CurveType: [], Granularity: [], Component:[],ContractOption:[],ExportSchedule:[],ImportType:[],Settle: null,Market:[],PSET:[],SecondaryComponent:[],SecondaryMarket:[],Unit:[],ZeroCal:[], AddToMarket:[], IsActive: true, WildCardSearchText: null, EffectiveStartDate: effectiveStartDate.toISOString(), EffectiveEndDate: effectiveEndDate.toISOString(),ExportStartDateTime:exportStartDate.toISOString(),ExportEndDateTime:exportEndDate.toISOString(),Status:[]});
  const [filterAligneResponseCriteria, setAligneResponseFilterCriteria] = useState<IAligneResponseFilter>({SETATrackingID:''});
  const [paginationFilter, setPaginationFilter] = useState<IPaginationFilter>({ PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0 });
  const [sortCriteria, setSortCriteria] = useState<ISortCriteria[]>([]);
  const [selectedExportStatusFilter,setSelectedExportStatusFilter] =useState<string[]>([]);
  const aligneResponsePayload: IAligneResponsePayload = { WildCardSearchText: wildCardSearchText, FilterCriteria: filterAligneResponseCriteria, PaginationFilter: paginationFilter, SortCriteria: sortCriteria }
  const [aligneExportData, setAligneExportData]= useState<IAligneExportReportData>();
  const aligneCurvePayload: IAligneCurvePayload = { WildCardSearchText: wildCardSearchText,FilterCriteria: filterCriteria, PaginationFilter: paginationFilter,SortCriteria: sortCriteria };
  const aligneExportStatusUpdatePayload: IAligneUpdateModel = {BatchIDs:[], FilterCriteria: exportReportUpdatefilterCriteria, Data: exportReportUpdateDataCriteria};
  const [aligneExportReportFilter, setaligneExportReportFilter] = useState<any>();
  const [PopupTitle, setPopupTitle] = useState('');
  const permissions = UserPermission();
  // signin  method for  the first time login  
  const { isAuthenticated, signin, appUser } = useAuth();
  //used for maintain and send the selectAll Status to backend api
  const [headerCheckbox, setHeaderCheckbox] = useState(false);
  //use to maintain the header checkbox check/uncheck status at UI only, no role in backend selectAll Status
  const [headerCheckboxUI, setHeaderCheckboxUI] = useState(false);
  //Selected Aligne Export Report Data array
  let [selectedData, setSelectedData] = useState<string[]>([]);
  //Unselected Aligne Export Report Data array
  let [unSelectedData, setUnSelectedData] = useState<string[]>([]);

  let [selectedPopupData, setSelectedPopupData] = useState<IAligneExportReportData[]>([]);
  // Export Selected Aligne Data
  let exportSelectedAligneData:string[] =[];
  // Export Selected Aligne Data
  const [exportSelectedFilterData,setExportSelectedFilterData] =useState<string>();
  const [isScrollableTable, setIsScrollableTable] = useState(true);
  // Aligne Export Report filterCriteria 
  let exportfilterCriteria: IAligneCurveFilter={Name: null, CurveType: [], Granularity: [], Component:[],ContractOption:[],ExportSchedule:[],ImportType:[],Settle: null,Market:[],PSET:[],SecondaryComponent:[],SecondaryMarket:[],Unit:[],ZeroCal:[], AddToMarket:[], IsActive: true, WildCardSearchText: null, EffectiveStartDate: effectiveStartDate.toISOString(), EffectiveEndDate: effectiveEndDate.toISOString(),ExportStartDateTime:exportStartDate.toISOString(),ExportEndDateTime:exportEndDate.toISOString(),Status:[]};
  //initialize to default
  const downloadFilter: IAligneCurveDownloadFilter = {    
    AligneCurvesFilter: filterCriteria,
    IsAllSelected: headerCheckbox,
    SelectedCurveList: selectedData,
    UnSelectedCurveList: unSelectedData
  };
  const [aligneResponsePopupData, setaligneResponsePopupData] = useState<IAligneResponseData[]>([]);
  const [openModal, setOpenModal] = useState(false); 
  const [openRemediationModal, setOpenRemediationModal] = useState(false); 
  const [aligneExportReportPopupData, setAligneExportReportPopupData] = useState<IAligneExportReportData[]>([]);; 
  // user email address
  const [emailAddress, setEmailAddress] = useState('');
  // Popup table 
  let popupTable: any;
  let remediationPopUp: any;
  let popupRemediationTable: any;
   useEffect(() => {
    // Set Page Size on the basis of  Window size 
    paginationFilter.PageSize = window.innerHeight ? parseInt((window.innerHeight / 70).toFixed()) : 10;
    // Fetch the Aligne Export Status Report data on load and on change of aligne curve payload
    getAligneExportStatusReportData();
  }, [wildCardSearchText, filterCriteria, paginationFilter, sortCriteria]);
  //On load aligne export report filters on load
  useEffect(() => {
    getAligneExportStatusReportFilters();    
  }, []);

  // For rendering link in table
  const renderLinkValue = (value: any, record: any, index: number) => {    
    if(record["Status"] === ExportStatus[ExportStatus.ALIGNE_ERROR]){
    return (
      <ButtonLink
      onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}
        placeholder=""
        size="small"
        onClick={() => {
          aligneResponsePayload.FilterCriteria.SETATrackingID = value;
          GetAligneResponseStatus(aligneResponsePayload).then(result => {           
            var res= result.data["Data"];
            let aligneResponseObj: IAligneResponseData[]=[];
            res.forEach((value:any,index:any)=>{
              if(JSON.parse(value['ResponseContent'])[0]['error']!='Processing')
              {
                let jsonData = JSON.parse(value['ResponseContent'])[0];
                jsonData['PDSError']=TransformAligneMessageToPDS(jsonData['description']);
                aligneResponseObj.push(jsonData);
              }
              
            });
            setaligneResponsePopupData(aligneResponseObj);            
            if (aligneResponseObj.length > 6) { setIsScrollableTable(true); }
             else { setIsScrollableTable(false); }
            setOpenModal(true)
          });
           
        }}
      >
        {value}
      </ButtonLink>
        )
      }
      else
      {
        return (<div>{value}</div>)
      }
      ;
  };
  // for rendering Edit Notes button
  const renderEdit = (value: any, record: any, index: number) => {
    // if(record["Status"] === ExportStatus[ExportStatus.ALIGNE_ERROR]){
    return (<ButtonLink onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={() => {
      setNotes(record.Notes); 
      setAligneExportData(record);
      setOpenRemediationModal(true);

    }} >{<Edit />}</ButtonLink>)
  //}
  }


  // Api calls for Aligne Export Status report data
  const getAligneExportStatusReportData = () => {      
    // Comment for Loader - To be built    
    setAligneExportReportData([]);
    setIsProcessing(true);
    GetAligneExportStatusReport(aligneCurvePayload).then(result => {   
      //maintain the checked status of current page on pagination
      const currentPageData :any = result.data.Data;   
         
      currentPageData.map((curve:any) => {
            //if HeaderChecbox is checked, uncheck only unselected curve data
            if(headerCheckbox)
            {
              //handle unchecked list -> marked them all unchecked
              var uncheckMe =  unSelectedData.some(el => el === curve.CurveName);
              // if no curve is unchecked and headerCheckBox is true then set IsChecked property to true
              curve.IsChecked = !uncheckMe && headerCheckbox ;
            }
            else{
              //handle checked list - marked them all checked             
              var checkedMe =  selectedData.some(el => el === curve.CurveName);
              // in case of all curve checked set properties to True
              curve.IsChecked = checkedMe;
            }
            const exportInitiatedDate = dayjs(curve.CreatedDate);
            const exportresponseUpdatedDate = dayjs(curve.UpdatedDate);
            // Calculating the time difference between ExportInitiatedDate and ExportStatusUpdatedDate and rounding off
            curve.TotalTimeElapsed = exportresponseUpdatedDate.diff(exportInitiatedDate,'m',true).toFixed(PDSCONSTANT.DECIMAL_ROUNDING_OFF);
            return curve;
        });  
      //set the grid data
      setAligneExportReportData(currentPageData);
      setIsProcessing(false);
      paginationFilter.TotalRecords = result.data.PaginationFilter.TotalRecords;
      paginationFilter.PageNumber = currentPage;
      paginationFilter.PageSize = result.data.PaginationFilte.PageSize;
      
      
    }).catch((err) => {
      setIsProcessing(false);
    });
  }
  // Method for Aligne Export Status report filters load
  const getAligneExportStatusReportFilters = () => {
    GetAligneExportStatusReportFilters().then(result => {            
      setaligneExportReportFilter(result.data)
    });
  }

  // Method for Download Aligne Export report Curve data
  const downloadSelectedAligneExportReportData = () => {
    //show error message if no curve is selected to download
    if(!downloadFilter.IsAllSelected && downloadFilter.SelectedCurveList.length===0)
    {            
        toast.error("No curve selected to download!", {position: toast.POSITION.TOP_CENTER});
        return;
    }    
    // API calls for download selected export data 
    DownloadSelectedAligneExportReportData(downloadFilter).then(result => { DownloadHelper().ExportToCSV(PDSCONSTANT.EXPORT_FILENAME_ALIGNEEXPORTREPORT,result.data); });
  }

  // Method for Add and edit Remediation notes
  const addEditReemediationNotes = () => {  
    if(downloadFilter.IsAllSelected===true)
    { 
      let pageNumber = aligneCurvePayload.PaginationFilter.PageNumber;
      let pageSize = aligneCurvePayload.PaginationFilter.PageSize;
      aligneCurvePayload.PaginationFilter.PageNumber=0;
      aligneCurvePayload.PaginationFilter.PageSize=0;
      setIsProcessing(true);
      GetAligneExportStatusReport(aligneCurvePayload).then(result => {   
        //maintain the checked status of current page on pagination
        const currentPageData :any = result.data.Data;  
        aligneCurvePayload.PaginationFilter.PageNumber=pageNumber;
        aligneCurvePayload.PaginationFilter.PageSize=pageSize;
        //set the grid data
        setAligneExportReportPopupData(currentPageData);
        setIsProcessing(false);
        setOpenRemediationModal(true);
        
        
      }).catch((err) => {
        setIsProcessing(false);
      });
        
    }
    else
    {
      setOpenRemediationModal(true);
    }
    
  }

  // Call this method on click of Apply in filters
  const handleFilterCriteria = (filterValues: any) => {
    resetPageNumber();
    exportfilterCriteria.Name = filterValues['name'];
    exportfilterCriteria.ExportSchedule =filterValues['exportType'];
    exportfilterCriteria.Granularity =filterValues['granularity'];
    exportfilterCriteria.CurveType =filterValues['curveType'];
    exportfilterCriteria.Market =filterValues['market'];
    exportfilterCriteria.Component =filterValues['component'];
    exportfilterCriteria.PSET =filterValues['pset'];    
    if (filterValues['effectivedaterange'] !== undefined && filterValues['effectivedaterange'] !== null) {
      exportfilterCriteria.EffectiveEndDate = toIsoString(filterValues['effectivedaterange'][1]['$d']);
      exportfilterCriteria.EffectiveStartDate = toIsoString(filterValues['effectivedaterange'][0]['$d']);
    }
    else {
      exportfilterCriteria.EffectiveEndDate = null;
      exportfilterCriteria.EffectiveStartDate = null;
    }    
    if (filterValues['exportdaterange'] !== undefined && filterValues['exportdaterange'] !== null) {
      exportfilterCriteria.ExportEndDateTime = dayjs(filterValues['exportdaterange'][1]['$d']).format(PDSCONSTANT.ISO_DATE_FORMAT);
      exportfilterCriteria.ExportStartDateTime = dayjs(filterValues['exportdaterange'][0]['$d']).format(PDSCONSTANT.ISO_DATE_FORMAT);
    }
    else {
      exportfilterCriteria.ExportEndDateTime = null;
      exportfilterCriteria.ExportStartDateTime = null;
    }       
    exportfilterCriteria.Status =  filterValues['exportStatus'];  
    setFilterCriteria(exportfilterCriteria);  
    //clear previous checked status on filter search
    resetCheckboxSelection();   
  }
  // On Page Change updated the Pagination filter
  const handlePaginationFilter = (paginationData: IPaginationFilter) => { setPaginationFilter(paginationData);  }
  // For rendering checkbox in table  
  const renderCheckbox = (value: any, record: any, index: number) => { 
    //Handle the changes on check or uncheck of a particular row checkbox
    const handleChange = () => {
      // Update the particular row checked/unchecked status 
      const selectCurveData = [
        ...aligneExportReportData.slice(0, index),
        {
          ...aligneExportReportData[index],
          IsChecked: !aligneExportReportData[index].IsChecked,
        },
        ...aligneExportReportData.slice(index + 1),
      ];
      // Set the updated curve status 
      setAligneExportReportData(selectCurveData);
      var currentSelection = selectCurveData[index];
      //handle checkbox selection when header is checked/unchecked
      if(headerCheckbox)
      {
        //handle child checkbox selection when header checkbox is unchecked   
        //re-factored separate method to reduce congnative complexity - sonar issue fix
        handleChildSelectionWhenHeaderIsChecked(currentSelection);
      }
      else
      {
        //handle when header checkbox is unchecked
        if(currentSelection.IsChecked){
          selectedData.push(currentSelection._id);
          selectedPopupData.push(currentSelection);
        }
        else
        {
            //if unchecked - remove  
            selectedData = selectedData.filter(function(item) {
              return item !== currentSelection._id
          });
          selectedPopupData = selectedPopupData.filter(function(item) {
            return item._id !== currentSelection._id});
          //Also set HeaderCheckbox to false
          setHeaderCheckboxUI(false);
        }        
        //if all item selected -> checked header checkbox
        if(selectedData.length === paginationFilter.TotalRecords && unSelectedData.length ===0)
        {
          setHeaderCheckboxUI(true);
        }    
        // Assign state variable value to new variable to update with new state    
        let selectedExportData:string[] = selectedData;
        //clear the selected list
        setSelectedData(selectedExportData);
        let selectedNotePoupData:IAligneExportReportData[] = selectedPopupData;
        setSelectedPopupData(selectedNotePoupData);
         //clear the unselected list
        setUnSelectedData([]);
        setAligneExportReportPopupData(selectedPopupData);
      }        
    
    };
    // Rendering the checkbox for row            
    return <Checkbox crossOrigin='false' label="" size='small' id={index.toString()} checked={value} onChange={handleChange} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />;
  };

  // reset checkbox selections
  const resetCheckboxSelection = () => {
    //clear all selection and headerCheck to false
    setSelectedData([]);
    // Setting the header checkbox on click 
    setHeaderCheckbox(false);
  };
  // For rendering check box all functionality 
  const handleCheckboxAll = () => {  
    // In case of clicking on select/unselect all check box render the curve data again    
    const checkedCurveData = aligneExportReportData.map((item) => ({
      ...item,
      IsChecked: !headerCheckbox,
    }));
    // Setting the curve data again
    setAligneExportReportData(checkedCurveData);      
    // Populate the array with checked curve names
    const selectedCurveData = checkedCurveData.filter((obj) => {
      return obj.IsChecked === true;
    }).map(a => a._id);
     
    //clear checkbox selection list as all is selected
    setSelectedData([]);
    setUnSelectedData([]);
    // Setting the header checkbox on click 
    setHeaderCheckbox(!headerCheckbox);
    setHeaderCheckboxUI(!headerCheckbox);
  };
// handle child checkbox selection when header is checked
const handleChildSelectionWhenHeaderIsChecked = (currentSelection:any) => {
  // Check for Checked property of Current Curve Data
  if(!currentSelection.IsChecked){
    unSelectedData.push(currentSelection._id);
    //also uncheck the global UI checkbox
    setHeaderCheckboxUI(false);
  }
  else{          
    unSelectedData = unSelectedData.filter(function(item) {
        return item !== currentSelection._id
    });
    //checked header check box checked, if no unchecked
    if(unSelectedData.length===0){
      setHeaderCheckboxUI(true);
    }
  }
  // Assign state variable value to new variable to update with new state    
  let unselectedExportCurvetData:string[] = unSelectedData;
  //clear the unselected list
  setUnSelectedData(unselectedExportCurvetData);
   //clear the selected list
  setSelectedData([]);
};
  
  // For rendering string value in table
  const renderValue = (value: any, record: any, index: number) => { return (<div>{value}</div>)};
  //Set the Page Number to 1  
  const resetPageNumber = () => {setPage(1);
    setPaginationFilter({PageNumber: 1, PageSize: defaultPageSize, TotalRecords: 0});}
   // For rendering link in table
   const renderStatus = (value: any, record: any, index: number) => {
    if(value===ExportStatus[ExportStatus.INITIATED]||value===ExportStatus[ExportStatus.TRACKINGID_RECEIVED]||value===ExportStatus[ExportStatus.TRACKING_INITIATED])
    {
      return (<Styled.SuccessStatusDiv>{value}</Styled.SuccessStatusDiv>)
    }
    else if(value===ExportStatus[ExportStatus.ALIGNE_SUCCESS]
      ||value===ExportStatus[ExportStatus.PAYLOAD_CREATED]||value===ExportStatus[ExportStatus.PDS_API_EXECUTED])
    {
      return (<Styled.SuccessStatusDiv>{value}</Styled.SuccessStatusDiv>)
    }
    else if(value===ExportStatus[ExportStatus.PDS_DATA_EXISTS]||value===ExportStatus[ExportStatus.ALIGNE_EXPORT_INITIATED])
    {
      return (<Styled.SuccessStatusDiv>{value}</Styled.SuccessStatusDiv>)
    }
    else if(value===ExportStatus[ExportStatus.ALIGNE_PROCESSING])
    {
      return (<Styled.ProcessingStatusDiv>{value}</Styled.ProcessingStatusDiv>)
    }
    else
    {
      return (<Styled.ErrorStatusDiv>{value}</Styled.ErrorStatusDiv>)
    }
    
  };

  // Passing render methods as a props in AligneExportReportColumnsHelper
  const aligneExportReportColumnProps: IAligneExportReportColumnProps = { renderCheckbox: renderCheckbox, renderValue: renderValue, handleCheckboxAll: handleCheckboxAll, headerCheckbox: headerCheckbox, renderDateValue: renderDateValue, renderSourceDateAndTimeValue:renderSourceDateAndTimeValue,renderStatusValue:renderStatus, renderLinkValue:renderLinkValue, renderAction: renderEdit ,renderNotes:renderNotes};
  const aligneExportReportColumns = AligneExportReportColumnsHelper(aligneExportReportColumnProps);
  // For Sorting 
  const [transformSorterColumns, sortStates] = useSorter<RecordType>({
    prefixCls: TABLE_CLASS,
    columns: aligneExportReportColumns,
  });
  useMemo(() => {
    const SortCriteriaResult: ISortCriteria[] = [];
    for (var sortNumber in sortStates) {
      if(Object.hasOwn(sortStates,sortNumber)){
        const SortCriterion: ISortCriteria = {
          ColumnName: '',
          Direction: ''
        };
        if (sortStates[sortNumber]!=undefined &&  sortStates[sortNumber].sortOrder !== undefined) {
          SortCriterion.ColumnName = sortStates[sortNumber].column.dataIndex?.toString() ?? "";
          SortCriterion.Direction = sortStates[sortNumber].sortOrder === 'ascend' ? SortDirection[SortDirection.DESC] : SortDirection[SortDirection.ASC];
          SortCriteriaResult.push(SortCriterion);
        }
     }
    }
    setSortCriteria(SortCriteriaResult);
  }, [sortStates]);
  // For changing the header sort icons
  const transformColumns = useCallback(
    (innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> => transformSorterColumns(innerColumns),
    [transformSorterColumns],
  );
  // Passing render methods as a props in AligneResponseColumn helper
  const aligneResponsePopupColumnProps: IManualAligneExportPopupColumnProps = {  renderValue: renderValue, handlePopupCheckboxAll: handleCheckboxAll, headerPopupCheckbox: headerCheckboxUI, renderDateValue: renderDateValue, renderSourceDateAndTimeValue: renderSourceDateAndTimeValue, renderCompletionFlow:null,renderCheckbox:null };
  const aligneResponsePopUpColumns = AligneResponsePopupColumnsHelper(aligneResponsePopupColumnProps);
  
  // For Sorting 
  const [transformSorterPopupColumns, sortPopupStates] = useSorter<RecordType>({ prefixCls: TABLE_CLASS, columns: aligneResponsePopUpColumns});
    // For changing the header sort icons
    const transformPopupColumns = useCallback((innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> => transformSorterPopupColumns(innerColumns),[transformSorterPopupColumns]);
    // Passing the columns 
    const transformedPopupColumns = transformPopupColumns(aligneResponsePopUpColumns);
   
   // Passing render methods as a props in AligneResponseColumn helper
  const aligneExportReportPopupColumnProps: IManualAligneExportPopupColumnProps = {  renderValue: renderValue, handlePopupCheckboxAll: handleCheckboxAll, headerPopupCheckbox: headerCheckboxUI, renderDateValue: renderDateValue, renderSourceDateAndTimeValue: renderSourceDateAndTimeValue, renderCompletionFlow:null,renderCheckbox:null };
  const aligneExportReportPopUpColumns = AligneExportReportPopupColumnsHelper(aligneExportReportPopupColumnProps);
  
  // For Sorting 
  const [transformRemediationSorterPopupColumns, sortRemediationPopupStates] = useSorter<RecordType>({ prefixCls: TABLE_CLASS, columns: aligneResponsePopUpColumns});
  // For changing the header sort icons
  const transformRemdiationPopupColumns = useCallback((innerColumns: ColumnsType<RecordType>): ColumnsType<RecordType> => transformSorterPopupColumns(innerColumns),[transformSorterPopupColumns]);
  // Passing the columns 
  const transformedRemediationPopupColumns = transformRemdiationPopupColumns(aligneExportReportPopUpColumns);


  
  const viewFailedExport = (value: any) => {
    if(value ===true)
    {
    var exportFilterValue = Object.values(ExportStatus) as string[];  
    filterCriteria.Status = exportFilterValue.filter(x=>x !='ALIGNE_SUCCESS').filter (k => isNaN (Number (k)));;
     setSelectedExportStatusFilter(filterCriteria.Status);
    let filterCriteriaState:IAligneCurveFilter = filterCriteria;
    setFilterCriteria(filterCriteriaState);
     // Calling the AligneExportReport method for fetching
     getAligneExportStatusReportData();
    }
    else
    {
     
    setSelectedExportStatusFilter([]);
      filterCriteria.Status = [];
    let filterCriteriaState:IAligneCurveFilter = filterCriteria;
    setFilterCriteria(filterCriteriaState);
     // Calling the AligneExportReport method for fetching
     getAligneExportStatusReportData();
    }

  };
  

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onCloseRemediationModal = () => {
    setOpenRemediationModal(false);
  };

  const setNoteText = (e:string) => {
    setNotes(e);
  };
  const saveNotes = () => {
    let notesData = notes;
    let exportData = aligneExportReportPopupData;
    let batchId:string[] = exportData.map(a => a.BatchID);
    var data = aligneExportData;
    var date = dayjs.utc().format(PDSCONSTANT.ISO_DATE_FORMAT)
    if(data!=undefined)
    {
     data.Notes = notes;
    }
    var updatedData: IAligneUpdateData = {Notes: notes,NotesUpdatedBy: appUser?.user?.profile.sub, NotesUpdatedDate:date};
    aligneExportStatusUpdatePayload.BatchIDs = batchId;
    aligneExportStatusUpdatePayload.Data=updatedData;
    UpdateAligneExportReportData(aligneExportStatusUpdatePayload).then((result) => 
      { result ? toast.success("Notes updated successfully", {position: toast.POSITION.TOP_CENTER})
      : toast.error("Error in updating notes", {position: toast.POSITION.TOP_CENTER});
      getAligneExportStatusReportData(); 
      }    
    ); 
      setOpenRemediationModal(false);
      setNotes('')
      setAligneExportReportPopupData([]);
      setSelectedPopupData([]);
      setWildCardSearchText('');      
         
      
  };
  // Passing the columns 
  const transformedColumns = transformColumns(aligneExportReportColumns);
  if (isScrollableTable) {  popupTable = <DataTableWithVerticalScrollBar data={aligneResponsePopupData} columns={aligneResponsePopUpColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} /> }
  else { popupTable = <DataTableWithScrollBar data={aligneResponsePopupData} columns={aligneResponsePopUpColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} />  }


  if (isScrollableTable) {  popupRemediationTable =<div><DataTableWithOnlyVerticalScrollBar data={aligneExportReportPopupData} columns={aligneExportReportPopUpColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} /><FormField id="Notes" label="Notes" >  <TextArea onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} maxLength={4000} size="large" value={notes} onChange={(e)=> setNoteText(e.target.value)}/></FormField></div>}
  else { popupRemediationTable = <div><DataTableWithOnlyVerticalScrollBar data={aligneExportReportPopupData} columns={aligneExportReportPopUpColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} /><FormField id="Notes" label="Notes" > <TextArea onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} maxLength={4000} size="large" value={notes} onChange={(e)=> setNoteText(e.target.value)}/></FormField></div>  }
 
  return (
    <>
      {isProcessing && <Loader />}
      <Styled.InnerContainer>
        <Styled.Sidebar>
          <FilterContainer><FilterStyled onApply={handleFilterCriteria} masterData={aligneExportReportFilter} selectedExportStatusFilter ={selectedExportStatusFilter}></FilterStyled></FilterContainer>
        </Styled.Sidebar>
        <Styled.ContentContainer>
          <Styled.CenteredContainer>
            <Styled.SideGrid>
              <Styled.GridStyled columns={2}>
                <Grid.Cell>
                  <b>Aligne Export Report</b>
                </Grid.Cell>
                <Grid.Cell>
                <Styled.FlexboxStyled gap='24px;'>
                     {/* create will be allowed as per authorization */}
                     <Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={downloadSelectedAligneExportReportData} variant='outlined' icon={<Download />}>Export Data</Styled.ButtonStyled>
                     {permissions.CanWrite_AligneMapping() &&<Styled.ButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' size='small' onClick={addEditReemediationNotes} variant='outlined' >Add/Edit Remediation Notes</Styled.ButtonStyled> }          
                  </Styled.FlexboxStyled>
                </Grid.Cell>
              </Styled.GridStyled>  
              <Grid columns={1}>
                <Grid.Cell>
                  <hr />
                </Grid.Cell>
              </Grid>
              <Styled.GridStyled columns={2} >
                <Grid.Cell >
                  <Styled.TextInputStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' size='small' icon={<Search height={24} />} placeholder="Search" onKeyUp={(e:any) => {
                    // Fire event on enter press
                    if (e.keyCode === 13) {                      
                      resetPageNumber();
                      setPage(1);
                      paginationFilter.PageNumber=1;
                      // Setting the value enetered in search textbox
                      filterCriteria.WildCardSearchText =e.target.value;
                      // Set the filtercriteria with the updated text value
                      // Assign state variable value to new variable to update with new state    
                      let filterCriteriaState:IAligneCurveFilter = filterCriteria;
                      setFilterCriteria(filterCriteriaState);
                      setWildCardSearchText(filterCriteria.WildCardSearchText??"")
                      // Calling the AligneExportReport method for fetching
                      getAligneExportStatusReportData();
                    }
                  }} />
                </Grid.Cell>
                <Grid.Cell ><Styled.ToggleStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder='' label="View Aligne Export Failure" onChange={viewFailedExport} labelPosition="left" size="medium" /></Grid.Cell>
              </Styled.GridStyled>
              <Grid rowGap="24px">
                <DataTableWithScrollBar data={aligneExportReportData} columns={transformedColumns} rowKey={PDSCONSTANT.CURVE_CONFIGURATION_TABLE_ROW_KEY} />
                <DataTablePagination currentPage={paginationFilter.PageNumber} pageSize={paginationFilter.PageSize} total={paginationFilter.TotalRecords} setPage={setPage} onPageChange={handlePaginationFilter} />
              </Grid>
            </Styled.SideGrid>
          </Styled.CenteredContainer>
        </Styled.ContentContainer>
      </Styled.InnerContainer>
      <Styled.ModalStyled size="large"  destroyOnClose={true} onClose={onCloseModal}  onSecondaryActionClick={onCloseModal} title="Aligne Response" open={openModal} >{popupTable}</Styled.ModalStyled>
      <Styled.ModalStyled size="large" destroyOnClose={true}  onClose={onCloseRemediationModal} onPrimaryActionClick={saveNotes} onSecondaryActionClick={onCloseRemediationModal} title="Add Remediation Notes" open={openRemediationModal} ><div>{popupRemediationTable}</div></Styled.ModalStyled>
      <ToastContainer />
    </>);
}