import styled, { css } from 'styled-components';
import {Button, Drawer, Flexbox, Grid, Table, TextInput, Toggle, ButtonGroup,ButtonGroupButton, Modal } from '@sede-x/shell-ds-react-framework';
import {AligneExportReportFilters} from "./AligneExportReportFilters";


// Styling table for alternate row colours
const TableStyled = styled(Table)`
tr:nth-child(odd) td {
  background-color: rgba(0, 0, 0, 0);
}
tr:nth-child(even) td {
  background-color: rgba(0, 0, 0, 0.04);
}
.shell-table-thead .shell-table-cell {
  background-color: rgba(0, 0, 0, 0.04);
}
width: 100%;
border: 1px solid rgba(0, 0, 0, 0.06);
`;

// Set Grid height
const GridStyled = styled(Grid)`

height:50px ! important;
`;

// Set css for SideGrid
const SideGrid = styled(Grid)`

background-color:white;
padding: 20px 20px 20px 20px;
`;

// Set css for SideGrid
const MainGrid = styled(Grid)`

height:100vh;
`;

// Set css for Button 
const ButtonStyled = styled(Button)`
float:right;
`;

// Set css for Button 
const FlexboxStyled = styled(Flexbox)`
float:right;
`;

// Set css for Toggle 
const ToggleStyled = styled(Toggle)`
float:right;
`;

// Set css for Text input
const TextInputStyled = styled(TextInput)`
width:200px;
`
 const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  max-height: calc(100% - 72px);
  flex-direction: row;
`;
 const Sidebar = styled.div`
  display: flex;
  height: 90%;
  background: ${(props) => props.theme.background.surface};
`;
 const CenteredContainer = styled.div`
  
  
`;
 const ContentContainer = styled.div`
  width:100%;
  padding: 24px;
  height: ${()=>window.innerHeight >= 1440?"91vh ! important":"88vh ! important"};
  
  background-color: ${({ theme }) => theme.background.base}; 

`;

const DrawerStyled = styled(Drawer)`
${({ open }) => open && `
       width:100%!important;
`}

`;
// Styling for main container
const FilterContainer = styled.div`  
height: ${()=>window.innerHeight >= 1440?"91vh ! important":"88vh ! important"};
  background-color: ${({ theme }) => theme.background.base};  
`;

// styling for Filter styling
const FilterStyled = styled(AligneExportReportFilters)`
width: 100%;
border: 1px solid rgba(0, 0, 0, 0.06);
`;

// styling for Button Group styling
const ButtonGroupStyled = styled(ButtonGroup)
`background-color: ${({ theme }) => theme.background.base}; 
`;


// styling for Drawer button 
const DrawerButtonStyled = styled(Button)
`float:right;
 margin:10px;
 width:100px;
`;

// styling for Drawer reset button
const DrawerResetButtonStyled = styled(Button)
`float:left;
 margin:10px;
 width:100px;
`;
// styling for Grid View of Aligne Curve Configuration Side Drawer
const SideDrawerGrid = styled(Grid)`
width: 750px;
`;

const SideDrawerNameTextGrid = styled(Grid)`
width: 735px;
`;

const SuccessStatusDiv = styled.div`
color:green;
`;

const ProcessingStatusDiv = styled.div`
color:#FFBF00;
`;

const ErrorStatusDiv = styled.div`
color:red;
`;

// styling for disabling scroll on modal 
const ModalStyled = styled(Modal) `

overflow: hidden;
`;
export {TableStyled, FilterContainer,FilterStyled,GridStyled,ButtonStyled, SideGrid,TextInputStyled,MainGrid,CenteredContainer,InnerContainer,Sidebar,ContentContainer,DrawerStyled,ToggleStyled,FlexboxStyled,ButtonGroupStyled,DrawerButtonStyled,DrawerResetButtonStyled,SideDrawerGrid, SideDrawerNameTextGrid,SuccessStatusDiv,ProcessingStatusDiv,ErrorStatusDiv,ModalStyled}



