import { Checkbox, ColumnsType, RecordType, Tooltip, Text } from "@sede-x/shell-ds-react-framework";
import { PDSCONSTANT } from "../../../common/constants";
import { ICurveConfigurationColumnProps } from "../../../interfaces/ICurveConfigurationColumnProps";

// Columns for Curve Reference Table
export const CurveReferenceTimezoneColumnsHelper = (props: ICurveConfigurationColumnProps): ColumnsType<RecordType> => {
  const curveReferenceColumns: ColumnsType<RecordType> = [
    {
      dataIndex: 'IsChecked',
      key: 'IsChecked',
      render: props.renderCheckbox,
      title: <Checkbox crossOrigin='false' size='small' label="" checked={props.headerCheckbox} onChange={props.handleCheckboxAll} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
    },
    {
      dataIndex: 'Identifier',
      key: 'Identifier',
      title: 'Identifier',
      sorter: {
        compare: (a, b): number => {
          if (a.Identifier !== b.Identifier) {
            return a.Identifier < b.Identifier ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderLinkValue
    },

    {
      dataIndex: 'UpdatedDate',
      key: 'UpdatedDate',

      title: <Tooltip onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" placement="topLeft" overlay={PDSCONSTANT.TOOLTIP_TEXT_PDS_LAST_UPDATED}>
        <span>Last Updated</span>
      </Tooltip>,
      sorter: {
        compare: (a, b): number => {
          if (a.UpdatedDate !== b.UpdatedDate) {
            return a.UpdatedDate < b.UpdatedDate ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      render: props.renderDateAndTimeValue
    },
    {
      dataIndex: 'UpdatedBy',
      key: 'UpdatedBy',
      title: 'UpdatedBy',
      sorter: {
        compare: (a, b): number => {
          if (a.UpdatedBy !== b.UpdatedBy) {
            return a.UpdatedBy < b.UpdatedBy ? -1 : 1;
          }
          return 0;
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: props.renderValue
    }


  ];
  return (curveReferenceColumns);
}