import { AxiosInstance } from "axios";
import {HTTPClient} from "../common/apiservice";
import { IProductCurvePayload } from "../interfaces/IProductCurvePayload";
import { IProductCurveFilter } from "../interfaces/IProductCurveFilter";
import { APIURL } from "../common/api-url";
import { ICurveConfigurationRequest, ICurveExistRequest } from "../interfaces/ICurveConfigurationRequest";
import { HttpHeader } from "../common/HttpHeader";
import { useAuth } from "../common/hooks/auth-provider";
import { IDownloadFilter } from "../interfaces/IDownloadFilter";

// Service class for Product Curves Configuration
export const ProductCurveConfigurations=()=>
{

   const { appUser } = useAuth();
  //setup http header
  var config = HttpHeader().GetHeader(appUser?.user?.access_token, process.env.REACT_APP_HEADER_API_VERSION);
  // Instance of Axios to call get and post method
  const _httpclient: AxiosInstance = HTTPClient(config);
    // Method for fetching GetProductCurvesConfiguration  data based on ProductCurveConfiguration payload
    async function GetProductCurvesConfiguration(productCurveFilter:IProductCurvePayload){
        const result= await _httpclient.post(APIURL.PRODUCT_CURVE_CONFIGURATION_URL,productCurveFilter);
        return result;          
     }

     // Method for fetching CreateProductCurvesConfiguration  data based on ProductCurveConfiguration payload
    async function CreateProductCurvesConfiguration(productCurveConfiguration:ICurveConfigurationRequest){
      const result= await _httpclient.post(APIURL.CREATE_EDIT_PRODUCT_CURVE_CONFIGURATION_URL,productCurveConfiguration);
      return result;          
   }
    // Method for fetching EditProductCurvesConfiguration  data based on ProductCurveConfiguration payload
    async function EditProductCurvesConfiguration(productCurveConfiguration:ICurveConfigurationRequest){
      const result= await _httpclient.put(APIURL.CREATE_EDIT_PRODUCT_CURVE_CONFIGURATION_URL,productCurveConfiguration);
      return result;          
   }
    // Method for downloading Product Curve based on downlaodFiter     
    async function DownloadSelectedProductCurveConfigurationData(downloadFilter:IDownloadFilter){     
         
      return await _httpclient.post(APIURL.CURVE_CONFIGURATION_DOWNLOAD_PRODUCT_DATA_URL,downloadFilter);      
    }

    // Method for checking Product Curve exist or not based on the CurveName
    async function CurveExists(curveName:ICurveExistRequest){
      return await _httpclient.post(APIURL.CURVE_CONFIGURATION_EXIST_DATA_URL,curveName);      
    }
     // Method for checking Product Curve exist or not based on the CurveName
     async function GetProductCurveConfiguration(productCurveFilter:IProductCurveFilter){
      return await _httpclient.post(APIURL.GET_PRODUCT_CURVE_CONFIGURATION,productCurveFilter);      
    }

    // Method for fetching Product Curvename
    async function GetProductCurveName(){
      return await _httpclient.get(APIURL.PRODUCT_CURVE_NAME_URL);      
    }
    
     return {
      GetProductCurvesConfiguration,
      CreateProductCurvesConfiguration,
      EditProductCurvesConfiguration,
      DownloadSelectedProductCurveConfigurationData,
      CurveExists,
      GetProductCurveConfiguration,
      GetProductCurveName 
       };

}