import { ButtonGroupButton, FormField, Grid, SingleDatePicker, SinglePickerValueType, TextInput } from '@sede-x/shell-ds-react-framework';
import { UserPermission } from '../../services/UserPermission';
import * as Styled from './CalendarConfiguration.style';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { PDSCONSTANT } from '../../common/constants';
import { ValidationMessage, ValidationHelper } from "../../common/validationHelper";

import { ICalendarDayConfigurationRequest } from '../../interfaces/ICalendarDayConfigurationRequest';
import { CalendarConfigurations } from '../../services/CalendarConfigurations';
import { ICalendarDay } from '../../interfaces/ICalendarDay';
import { IMasterData } from '../../interfaces/IMasterData';
import { BtnGrpEnableDisableStatus } from '../../common/enum';
import dayjs, { Dayjs } from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
dayjs.extend(weekday);
dayjs.extend(localeData);
// Side Drawer for calendar configuration
export default function CalendarConfigurationSideDrawer(drawerProps: any) {

    //object containing user permissons
    const permissions = UserPermission();
    // Areas array for Curve Configuration Side drawer
    const areas = ['main .', 'subtext .', 'subtext .', 'table table'];
    const isEditable = drawerProps.selectedCalendarDay.Name !== '' ? true : false;
    const [isDisabled, setDisabled] = useState(false);
    const headerText = drawerProps.selectedCalendarDay.Name !== '' ? 'Edit Calendar Day' : 'Create Calendar Day';
    // Mask Style Background Color
    const maskStyle = { backgroundColor: "black" };
    const [NameValidationErrorMessage, setNameValidationErrorMessage] = useState('');
    const [DateValidationErrorMessage, setDateValidationErrorMessage] = useState('');
    const [DescriptionValidationErrorMessage, setDescriptionValidationErrorMessage] = useState('');

    // Footer to set the Buttons 
    let footer: any;
    // Represents Calendar Configuration request
    let calendarDayConfigurationRequest: ICalendarDayConfigurationRequest = { _id: '', Name: '', DisplayName: '', Date: dayjs(), Description: '', IsActive: true };
    const [calendarConfigurationRequest, setCalendarConfigurationRequest] = useState<ICalendarDayConfigurationRequest>(calendarDayConfigurationRequest);
    const { UpsertCalendarDay } = CalendarConfigurations();
    const [statusValue, setStatusValue] = useState<string[] | string>(PDSCONSTANT.ENABLE_DEFAULT_TOGGLE_BTN);
    const [name, setName] = useState<IMasterData[]>([]);
    const [description, setDescription] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<SinglePickerValueType<Dayjs>>(dayjs());

    useEffect(() => {
        //Reset calendar Side Drawer
        ResetCalendarDayRequest();
        // Fetch the calendar data on load and on change of calendar payload
        if (drawerProps) {
            // Set dropdown value for Granularity in Create/Edit curve configuration
            if (drawerProps.masterData) {
                let calendarDataList: IMasterData[] = [];
                for (let i = 0; i < drawerProps.masterData.length; i++) {
                    let calendarData: IMasterData = { text: "", value: "" }
                    calendarData.text = drawerProps.masterData[i].Name;
                    calendarData.value = drawerProps.masterData[i].Name;
                    calendarDataList.push(calendarData);

                }
                setName(calendarDataList);
            }
            setDescription(drawerProps.selectedCalendarDay.Description);
            setSelectedDate(dayjs(drawerProps.selectedCalendarDay.Date));
            setStatusValue(BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable]);
        }
        if (drawerProps.selectedCalendarDay.Name !== '') {


            // Selected calendar day  
            var selectedCalendarDay = drawerProps.selectedCalendarDay;

            // Set IsActive status for Selected curve
            var isActive = drawerProps.selectedCalendarDay.IsActive === true ? BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] : BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Disable];
            setStatusValue(isActive);

            // Set the dropdown value and other textbox value
            setCalendarConfigurationRequest(selectedCalendarDay);

            resetValidation();
        }
        else { ResetCalendarDayRequest(); }
        //on load of side drawer enable the save button
        setDisabled(false);
    }, [drawerProps]);


    // CreateOrEditCalendar on click of save button
    const CreateOrEditCalendar = () => {
        try {
            // if button disabled not serve the request
            if (isDisabled) {
                return;
            }
            var res = calendarConfigurationRequest;
            resetValidationOnChange(res);
            const isValid = setValidation(res);
            if (isValid) {
                setDisabled(true);
                CreateOrEditCalendarRequest(res);
            }
        }
        catch (error) {
            // In case of error in Create or edit request enable the save button
            setDisabled(false);
        }
    }
    // API call for creating the curve or editing the curve 
    const CreateOrEditCalendarRequest = (res: ICalendarDayConfigurationRequest) => {

        //Trimming the End spaces and special charectors for all the input fields.
        res.Name = res.Name?.trimEnd().replace(/​/g, "");
        if (res._id === '' || res._id === undefined) {
            // Checking if curve Exist or not      
            UpsertCalendarDay(res).then(result => {
                ResetCalendarDayRequest();
                drawerProps.onClose(true, PDSCONSTANT.CALENDAR_CONFIG_CREATE_SUCCESS);
                setDisabled(false);
            }).catch((err) => {
                toast.error(err.response.data.ErrorMessage, { position: toast.POSITION.TOP_CENTER });
                setDisabled(false);
            })
        }
        else {

            // Edit the Product Curve
            UpsertCalendarDay(res).then(result => {
                drawerProps.onClose(true, PDSCONSTANT.CALENDAR_CONFIG_UPDATE_SUCCESS);
            }).catch((err) => {
                if (err.response.data.ErrorMessage) {
                    toast.error(err.response.data.ErrorMessage, { position: toast.POSITION.TOP_CENTER });
                    setDisabled(false);
                }
                else {
                    toast.error(err.response.data.title, { position: toast.POSITION.TOP_CENTER });
                    setDisabled(false);
                }
            })
        }
    }
    // On Drawer close
    const onClose = (): void => {
        drawerProps.onClose();
    };
    // For reset validation in case of on click of Create calendar
    const resetValidation = () => {
        setNameValidationErrorMessage("");
        setDateValidationErrorMessage("");
        setDescriptionValidationErrorMessage("");
    }

    // Reset Validation on Change of input and dropdown
    const resetValidationOnChange = (res: ICalendarDayConfigurationRequest) => {
        // Resetting validation for curve name if there is some input
        if (res.Name !== '') { setNameValidationErrorMessage(""); }
        // Resetting validation for Granularity if there is some input
        if (res.Date !== null) { setDateValidationErrorMessage(""); }
        // Resetting validation for Granularity if there is some input
        if (res.Description !== null) { setDescriptionValidationErrorMessage(""); }
    }
    const setValidation = (res: ICalendarDayConfigurationRequest): boolean => {
        let isValid: boolean = true;
        // Validating the curvename 
        if (IsNotValid(res.Name)) { setNameValidationErrorMessage(ValidationMessage.CALENDAR_NAME_REQUIRED); isValid = false; }
        // Validating the Description
        if (IsNotValid(res.Description)) { setDescriptionValidationErrorMessage(ValidationMessage.CALENDAR_DESC_REQUIRED); isValid = false; }
        //validate date
        if (res.Date == null || res.Date == undefined) { setDateValidationErrorMessage(ValidationMessage.CALENDAR_DATE_REQUIRED); isValid = false; }
        return isValid;
    }

    const IsNotValid = (data: string | undefined): boolean => {
        return (data === undefined || data == null || data.length <= 0) ? true : false;
    }

    // Reset Calendar request on click of Reset button
    const ResetCalendarDayRequest = () => {
        //Resetting Calendar 
        calendarDayConfigurationRequest = { _id: '', Name: '', DisplayName: '', Date: dayjs(), Description: '', IsActive: true };

        resetValidation();
        setCalendarConfigurationRequest(calendarDayConfigurationRequest);
    }

    if (!isEditable) {
        footer = <Grid columns={2}>
            <Grid.Cell><Styled.DrawerResetButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size='small' placeholder="" onClick={ResetCalendarDayRequest} variant="outlined">Reset</Styled.DrawerResetButtonStyled > </Grid.Cell>
            <Grid.Cell><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size='small' placeholder="" disabled={isDisabled} onClick={CreateOrEditCalendar}>Create</Styled.DrawerButtonStyled><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size='small' placeholder="" variant="outlined" onClick={onClose}>Cancel</Styled.DrawerButtonStyled></Grid.Cell>
        </Grid>;
    } else {
        footer = <Grid columns={2}>
            <Grid.Cell> </Grid.Cell>
            <Grid.Cell><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size='small' placeholder="" disabled={isDisabled} onClick={CreateOrEditCalendar} >Save</Styled.DrawerButtonStyled><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size='small' placeholder="" variant="outlined" onClick={onClose}>Cancel</Styled.DrawerButtonStyled></Grid.Cell>
        </Grid>;
    }
    // On Change of textbox input calls this method, this will remove the first special charecter 
    const onTextBoxInputChange = (fieldName: string, value: any) => {
        if (value !== '') {
            value = ValidationHelper().RecursiveRemoveFirstSpecialChar(value.trimLeft());
        }
        onInputChange(fieldName, value);
    };
    // On Change of input/dropdown call this method
    const onInputChange = (fieldName: string, value: any) => {

        calendarConfigurationRequest[fieldName as keyof ICalendarDayConfigurationRequest] = value;

        setCalendarConfigurationRequest({ ...calendarConfigurationRequest });
        resetValidationOnChange(calendarConfigurationRequest);
    };
    // On change of status 
    const handleStatusChange = (selectedValues: string[] | string): void => {
        calendarConfigurationRequest.IsActive = (selectedValues === BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] ? true : false);
        setStatusValue(selectedValues);
    };
    //handle date changes for Setting holiday Date
    const HandleOnDateChange = (e: any) => {
        if (e == null) {
            setSelectedDate(dayjs());
            onInputChange("Date", dayjs());
        }
        else {
            setSelectedDate(dayjs(e));
            onInputChange("Date", dayjs(e));
        }
    }
    return (
        <><Styled.DrawerStyled maskClosable={false} maskStyle={maskStyle} placement="right" open={drawerProps.open} onClose={onClose} header={headerText}>

            <Styled.SideDrawerNameTextGrid columns={1}>
                <Grid.Cell><FormField id="Name" label="Name" mandatory state="error" message={NameValidationErrorMessage}>
                    <TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' value={calendarConfigurationRequest.Name} placeholder="" onChange={(e: any) => onTextBoxInputChange("Name", e.target.value)} /><span><small>{PDSCONSTANT.SUB_TEXT_CALENDAR_NAME}</small></span>
                </FormField></Grid.Cell>
            </Styled.SideDrawerNameTextGrid>
            <Styled.SideDrawerGrid columns={2}>
                <Grid.Cell>
                    <FormField id="Description" label="Description" state="error" message={DescriptionValidationErrorMessage} mandatory>
                        <TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' id="Description" placeholder="" onChange={(e: any) => onTextBoxInputChange("Description", e.target.value)} value={calendarConfigurationRequest.Description} />
                        <span><small>{PDSCONSTANT.SUB_TEXT_CALENDAR_DESCRIPTION}</small></span>
                    </FormField>
                </Grid.Cell>
                <Grid.Cell>
                    <FormField id="Date" label="Date" state="error" mandatory message={DateValidationErrorMessage}>
                        <SingleDatePicker onChange={HandleOnDateChange} picker='date' showToday={false} format={"DD/MM/YY"} value={selectedDate} ></SingleDatePicker>
                    </FormField>
                </Grid.Cell>
                <Grid.Cell><FormField id="Status" label="Status" mandatory><Styled.ButtonGroupStyled id="status" size="medium" value={statusValue} onChange={handleStatusChange}>
                    <ButtonGroupButton name="Enable" />
                    <ButtonGroupButton name="Disable" />
                </Styled.ButtonGroupStyled ></FormField></Grid.Cell>
            </Styled.SideDrawerGrid>
            <hr />
            {/*render footer section -show create/save button, based on permission */}
            {permissions.CanWrite_CalendarData() && footer}
            <ToastContainer />
        </Styled.DrawerStyled></>
    );
}