import { FormField, Grid, TextInput, ButtonGroupButton } from "@sede-x/shell-ds-react-framework";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import * as Styled from './AligneMapping.style';
import Dropdown from "../../library/DropDown/Dropdown";
import { IAligneCurveConfigurationRequest } from "../../interfaces/IAligneCurveConfigurationRequest";
import { AligneCurveConfigurations, } from "../../services/AligneCurveConfigurations";
import { BtnGrpEnableDisableStatus, BtnGrpPublicPrivateStatus } from "../../common/enum";
import { ValidationMessage, ValidationHelper } from "../../common/validationHelper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PDSCONSTANT } from "../../common/constants";
import { UserPermission } from '../../services/UserPermission';
import { IMasterData } from '../../interfaces/IMasterData';
import { Select, Option } from "@sede-x/shell-ds-react-framework";
// Areas array for Curve Configuration Side drawer
const areas = ['main .', 'subtext .', 'subtext .', 'table table'];
// Drop Down value for Put/Call 
const putCallData = ['Put', 'Call'];
// Mask Style Background Color
const maskStyle = { backgroundColor: "black" };
// Footer to set the Buttons 
let footer: any;
// Represents Curve Configuration request
const curveConfigurationRequest: IAligneCurveConfigurationRequest = { _id: '', CurveName: '', Market: '', Component: '', SecondaryMarket: '', SecondaryComponent: '', ZeroCal: '', ContractOption: '', PSET: '', ExportSchedule: '', AligneImportType: '', Unit: '', AddToMarket: '', IsActive: true };

// Side Drawer for aligne curve configuration
export default function AligneCurveConfigurationDrawer(drawerProps: any) {

  //object containing user permissons
  const permissions = UserPermission();

  // Set state Variables    
  const isEditable = drawerProps.selectedCurve.CurveName !== '' ? true : false;
  const headerText = drawerProps.selectedCurve.CurveName !== '' ? 'Edit Mapping' : 'Create Mapping';
  const zeroCalDropDownData = [{ value: "FALSE", text: "FALSE" },
  { value: "TRUE", text: "TRUE" }];
  const [curveName, setCurveName] = useState(['']);
  const [aligneImportType, setAligneImportType] = useState(['']);
  const [contractOptions, setContractOptions] = useState(['']);
  const [exportSchedule, setExportSchedule] = useState(['']);
  const [statusValue, setStatusValue] = useState<string[] | string>(PDSCONSTANT.ENABLE_DEFAULT_TOGGLE_BTN);
  const [curveNameValidationErrorMessage, setcurveNameValidationErrorMessage] = useState('');
  const [marketValidationErrorMessage, setmarketValidationErrorMessage] = useState('');
  const [componentValidationErrorMessage, setcomponentValidationErrorMessage] = useState('');
  const [psetValidationErrorMessage, setpsetValidationErrorMessage] = useState('');
  const [aligneImportTypeValidationErrorMessage, setaligneImportTypeValidationErrorMessage] = useState('');
  const [exportScheduleErrorMessage, setexportScheduleErrorMessage] = useState('');
  const [settleErrorMessage, setsettleErrorMessage] = useState('');
  const [isDisabled, setDisabled] = useState(false);
  const [settleRequired, setsettleRequired] = useState(false);

  const [configurationRequest, setconfigurationRequest] = useState<IAligneCurveConfigurationRequest>(curveConfigurationRequest);
  const { CreateAligneCurvesConfiguration, EditAligneCurvesConfiguration } = AligneCurveConfigurations();
  useEffect(() => {
    //Reset Aligne Curve Configuration Side Drawer
    ResetCurveRequest();
    // Fetch the Aligne Curve COnfiguration data on load and on change of Aligne curve payload    
    // Set dropdown value for Curve Name in Create/Edit aligne curve configuration
    if (drawerProps.curveList.CurveName) {
      setCurveName(drawerProps.curveList.CurveName.map((str: string, _index: number) => ({ value: str, text: str })));
    }
    // for PutCall
    if (drawerProps.ContractOptions) {
      setContractOptions(drawerProps.ContractOptions.map((str: string, _index: number) => ({ value: str, text: str })));
    }
    // Set dropdown value for Aligne Import Type in Create/Edit  aligne curve configuration
    if (drawerProps.AligneImportType) {
      setAligneImportType(drawerProps.AligneImportType.map((str: string, _index: number) => ({ value: str, text: str })));
    }
    // Set dropdown value for Export Schedule in Create/Edit aligne curve configuration
    if (drawerProps.ExportSchedule) {
      setExportSchedule(drawerProps.ExportSchedule.map((str: string, _index: number) => ({ value: str, text: str })));
    }

    configurationRequest.ZeroCal = 'FALSE';
    configurationRequest.AddToMarket = 'FALSE';

    if (drawerProps.selectedCurve.CurveName !== '') {
      // Selected curve  
      var selectedCurve = drawerProps.selectedCurve;
      // Set IsActive status for Selected curve
      var isActive = drawerProps.selectedCurve.IsActive === true ? BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] : BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Disable];
      setStatusValue(isActive);
      // Setting empty string to Null Value
      Object.keys(selectedCurve).forEach(k => selectedCurve[k] = selectedCurve[k] === null ? '' : selectedCurve[k])
      // Set the dropdown value and other textbox value
      setconfigurationRequest(selectedCurve);
      if (CheckSettleRequired(selectedCurve)) {
        setsettleRequired(true);
      }
      else {
        setsettleRequired(false);
      }
      resetValidation();
    }
    else { ResetCurveRequest(); }
    // On load of side drawer enable the save button
    setDisabled(false);
  }, [drawerProps]);
  // For reset validation in case of on click of Create Aligne curve configuration
  const resetValidation = () => {
    setcurveNameValidationErrorMessage("");
    setmarketValidationErrorMessage("");
    setcomponentValidationErrorMessage("");
    setpsetValidationErrorMessage("");
    setaligneImportTypeValidationErrorMessage("");
    setexportScheduleErrorMessage("");
    setsettleErrorMessage("");

  }
  // Reset Curve request on click of Reset button
  const ResetCurveRequest = () => {
    //Resetting Curve Name
    curveConfigurationRequest.CurveName = '';
    // Resetting Enabled Flag
    setStatusValue(BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable]);
    resetValidation();
    setsettleRequired(false);
    setconfigurationRequest({ _id: '', CurveName: '', Market: '', Component: '', SecondaryMarket: '', SecondaryComponent: '', ZeroCal: '', AddToMarket: '', ContractOption: '', PSET: '', ExportSchedule: '', AligneImportType: '', Unit: '', IsActive: true });
  }
  // Reset Validation on Change of input and dropdown
  const resetValidationOnChange = (res: IAligneCurveConfigurationRequest) => {
    // Resetting validation for curve name if there is some input
    if (res.CurveName !== '') { setcurveNameValidationErrorMessage(""); }
    // Resetting validation for Market if there is some input
    if (res.Market !== '') { setmarketValidationErrorMessage(""); }
    // Resetting validation for Component if there is some input
    if (res.Component !== '') { setcomponentValidationErrorMessage(""); }
    // Resetting validation for PSET if there is some input
    if (res.PSET !== '') { setpsetValidationErrorMessage(""); }
    // Resetting validation for Aligne Import type if there is some input
    if (res.AligneImportType !== '') { setaligneImportTypeValidationErrorMessage(""); }
    // Resetting validation for Export Schedule if there is some input
    if (res.ExportSchedule !== '') { setexportScheduleErrorMessage(""); }
    // Resetting validation for Settle if there is some input
    if ((res.Settle !== '') || !(CheckSettleRequired(res))) { setsettleErrorMessage(""); }

  }
  const setValidation = (res: IAligneCurveConfigurationRequest): boolean => {
    let isValid: boolean = true;
    // Validating the curvename 
    if (IsNotValid(res.CurveName)) { setcurveNameValidationErrorMessage(ValidationMessage.CURVE_NAME_REQUIRED); isValid = false; }
    // Validating the market
    if (IsNotValid(res.Market)) { setmarketValidationErrorMessage(ValidationMessage.MARKET_REQUIRED); isValid = false; }
    // Validating the Component
    if (IsNotValid(res.Component)) { setcomponentValidationErrorMessage(ValidationMessage.COMPONENT_REQUIRED); isValid = false; }
    // Validating the PSET
    if (IsNotValid(res.PSET)) { setpsetValidationErrorMessage(ValidationMessage.PSET_REQUIRED); isValid = false; }
    // Validating the Aligne import type
    if (IsNotValid(res.AligneImportType)) { setaligneImportTypeValidationErrorMessage(ValidationMessage.ALIGNE_IMPORT_TYPE_REQUIRED); isValid = false; }
    // Validating the export schedule
    if (IsNotValid(res.ExportSchedule)) { setexportScheduleErrorMessage(ValidationMessage.EXPORT_SCHEDULE_REQUIRED); isValid = false; }
    // Validating the export schedule

    if ((CheckSettleRequired(res)) && IsNotValid(res.Settle)) { setsettleErrorMessage(ValidationMessage.SETTLE_REQUIRED); isValid = false; }
    return isValid;
  }

  const IsNotValid = (data: string | undefined): boolean => {
    return (data === undefined || data == null || data.length <= 0) ? true : false;
  }
  // CreateOreEdit Aligne Curve Configuration on click of Save button
  const CreateOrEditCurve = () => {
    try {
      // if button disabled not serve the request
      if (isDisabled) {
        return;
      }

      //Trimming the End spaces for all the input feilds.
      configurationRequest.Market = configurationRequest.Market?.trimEnd();
      configurationRequest.Component = configurationRequest.Component?.trimEnd();
      configurationRequest.PSET = configurationRequest.PSET?.trimEnd();
      configurationRequest.Settle = configurationRequest.Settle?.trimEnd();
      configurationRequest.SecondaryMarket = configurationRequest.SecondaryMarket?.trimEnd();
      configurationRequest.SecondaryComponent = configurationRequest.SecondaryComponent?.trimEnd();

      var res = configurationRequest;
      resetValidationOnChange(res);
      const isValid = setValidation(res);
      if (isValid) {
        setDisabled(true);
        CreateOrEditCurveRequest(res);
      }
    }
    catch (error) {
      // In case of error in Create or edit request enable the save button
      setDisabled(false);
    }
  }
  // API call for creating the curve or editing the curve 
  const CreateOrEditCurveRequest = (res: IAligneCurveConfigurationRequest) => {
    if (res._id === '' || res._id === undefined) {
      // Create the Aligne Curve
      CreateAligneCurvesConfiguration(res).then(result => {
        ResetCurveRequest();
        drawerProps.onClose(true, PDSCONSTANT.ALIGNE_CURVE_CONFIG_CREATE_SUCCESS);
        setDisabled(false);
      }).catch((err) => {
        toast.error(err.response.data.ErrorMessage, { position: toast.POSITION.TOP_CENTER });
        setDisabled(false);
      })
    }
    else {
      // Edit the Aligne Curve
      EditAligneCurvesConfiguration(res).then(result => {
        drawerProps.onClose(true, PDSCONSTANT.ALIGNE_CURVE_CONFIG_UPDATE_SUCCESS);
        setDisabled(false);
      }).catch((err) => {
        if (err.response.data.ErrorMessage) {
          toast.error(err.response.data.ErrorMessage, { position: toast.POSITION.TOP_CENTER });
          setDisabled(false);
        }
        else {
          toast.error(err.response.data.title, { position: toast.POSITION.TOP_CENTER });
          setDisabled(false);
        }
      })
    }
  }
  // On Drawer close
  const onClose = (): void => {
    drawerProps.onClose();
  };
  // On change of status 
  const handleStatusChange = (selectedValues: string[] | string): void => {
    configurationRequest.IsActive = (selectedValues === BtnGrpEnableDisableStatus[BtnGrpEnableDisableStatus.Enable] ? true : false);
    setStatusValue(selectedValues);
  };


  // On Change of input text field calls this method and removes the first Special Charector.
  const onTextBoxInputChange = (fieldName: string, value: any) => {
    if (value !== '') {
      value = ValidationHelper().RecursiveRemoveFirstSpecialChar(value.trimLeft().toUpperCase());
    }
    onInputChange(fieldName, value);
  };


  // On Change of input/dropdown call this method
  const onInputChange = (fieldName: string, value: any) => {
    configurationRequest[fieldName as keyof IAligneCurveConfigurationRequest] = value;
    // Resetting the dropdown value in case of import type other than TradeVol2
    if (configurationRequest.AligneImportType !== PDSCONSTANT.VOL_IMPORT_TYPE) {
      configurationRequest.ContractOption = '';
      configurationRequest.SecondaryMarket = '';
      configurationRequest.SecondaryComponent = '';
      configurationRequest.Unit = '';
    }

    if (fieldName === PDSCONSTANT.ALIGNE_ALIGNEIMPORTTYPE && configurationRequest.AligneImportType !== PDSCONSTANT.PRCFWD_IMPORT_TYPE) {
      configurationRequest.ZeroCal = PDSCONSTANT.ZEROCAL;
      configurationRequest.AddToMarket = PDSCONSTANT.ADDTOMARKET;
    }
    // If AligneImportType is PRCFWD then set ZeroCal and AddToMarket to False as default
    if (configurationRequest.AligneImportType === PDSCONSTANT.PRCFWD_IMPORT_TYPE) {

      if (configurationRequest.ZeroCal === '') {
        configurationRequest.ZeroCal = PDSCONSTANT.ZEROCAL;
      }

      if (configurationRequest.AddToMarket === '') {
        configurationRequest.AddToMarket = PDSCONSTANT.ADDTOMARKET;
      }

    }
    if (CheckSettleRequired(configurationRequest)) {
      setsettleRequired(true);
    }
    else {
      setsettleRequired(false);
    }
    setconfigurationRequest({ ...configurationRequest });
    resetValidationOnChange(configurationRequest);
  };

  const IsPRCFWDSelected = useCallback(() => {
    if (configurationRequest.AligneImportType === PDSCONSTANT.PRCFWD_IMPORT_TYPE) {
      return true;
    }
    return false;
  }
    , [configurationRequest]);

  const callBackddl = useCallback((fieldName: string, value: string) => {
    onInputChange(fieldName, value)
  }
    , [configurationRequest]);
  const callBackTextBox = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onTextBoxInputChange(e.target.name, e.target.value);
  }, [configurationRequest]);

  const CheckSettleRequired = (configurationRequest: IAligneCurveConfigurationRequest): boolean => {
    if (configurationRequest.AligneImportType === PDSCONSTANT.PRCFWD_IMPORT_TYPE || configurationRequest.AligneImportType == PDSCONSTANT.PRCPOW_FWD_IMPORT_TYPE
      || configurationRequest.AligneImportType === PDSCONSTANT.PRCPOW_IMB_IMPORT_TYPE || configurationRequest.AligneImportType === PDSCONSTANT.PRCPOW_SPOT_IMPORT_TYPE) {
      return true;
    }
    return false;
  };

  if (!isEditable) {
    footer = <Grid columns={2}>
      <Grid.Cell><Styled.DrawerResetButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' onClick={ResetCurveRequest} variant="outlined">Reset</Styled.DrawerResetButtonStyled > </Grid.Cell>
      <Grid.Cell><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' disabled={isDisabled} onClick={CreateOrEditCurve}>Create</Styled.DrawerButtonStyled><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder=""   size='small' variant="outlined" onClick={onClose}>Cancel</Styled.DrawerButtonStyled></Grid.Cell>
    </Grid>;
  } else {
    footer = <Grid columns={2}>
      <Grid.Cell> </Grid.Cell>
      <Grid.Cell><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' disabled={isDisabled} onClick={CreateOrEditCurve} >Save</Styled.DrawerButtonStyled><Styled.DrawerButtonStyled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} placeholder="" size='small' variant="outlined" onClick={onClose}>Cancel</Styled.DrawerButtonStyled></Grid.Cell>
    </Grid>;
  }
  return (
    <><Styled.DrawerStyled maskClosable={false} maskStyle={maskStyle} placement="right" open={drawerProps.open} onClose={onClose} header={headerText}>

      <Styled.SideDrawerNameTextGrid columns={1}>
        <Grid.Cell>
          <FormField id="CurveName" label="Name" state="error" message={curveNameValidationErrorMessage} mandatory><Dropdown masterData={curveName} id="CurveName" onDropDownChange={onInputChange} selectedData={configurationRequest.CurveName} /></FormField>
        </Grid.Cell>
      </Styled.SideDrawerNameTextGrid>

      <Styled.SideDrawerGrid areas={areas} columns={2}>
        <Grid.Cell><FormField id="Market" label="MARKET" mandatory state="error" message={marketValidationErrorMessage}><TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' value={configurationRequest.Market} placeholder="" onChange={(e) => onTextBoxInputChange("Market", e.target.value)} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Component" label="COMPONENT" mandatory state="error" message={componentValidationErrorMessage}><TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' value={configurationRequest.Component} placeholder="" onChange={(e) => onTextBoxInputChange("Component", e.target.value)} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Pset" label="PSET" mandatory state="error" message={psetValidationErrorMessage}><TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' value={configurationRequest.PSET} placeholder="" onChange={(e) => onTextBoxInputChange("PSET", e.target.value)} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="AligneImportType" label="Aligne Import Type" state="error" message={aligneImportTypeValidationErrorMessage} mandatory><Dropdown masterData={aligneImportType} id="AligneImportType" onDropDownChange={onInputChange} selectedData={configurationRequest.AligneImportType} /></FormField></Grid.Cell>

        <Grid.Cell><FormField id="Settle" label="SETTLE" state="error" message={settleErrorMessage} mandatory={settleRequired} ><TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' disabled={configurationRequest.AligneImportType === PDSCONSTANT.VOL_IMPORT_TYPE ? true : false} value={configurationRequest.Settle} placeholder="" name="Settle" onChange={callBackTextBox} /><span><small>{PDSCONSTANT.SUB_TEXT_ALIGNE_SETTLE}</small></span></FormField></Grid.Cell>
        <Grid.Cell><FormField id="ZeroCal" label="0CAL" ><Dropdown disabledDrpDwn={!IsPRCFWDSelected()} masterData={zeroCalDropDownData} id="ZeroCal" onDropDownChange={callBackddl} selectedData={configurationRequest.ZeroCal} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="AddToMarket" label="Add To Market?" ><Dropdown disabledDrpDwn={!IsPRCFWDSelected()} masterData={zeroCalDropDownData} id="AddToMarket" onDropDownChange={callBackddl} selectedData={configurationRequest.AddToMarket} /></FormField></Grid.Cell>

        <Grid.Cell><FormField id="ContractOption" label="PUTCALL" ><Dropdown disabledDrpDwn={configurationRequest.AligneImportType === PDSCONSTANT.VOL_IMPORT_TYPE ? false : true} masterData={contractOptions} id="ContractOption" onDropDownChange={onInputChange} selectedData={configurationRequest.ContractOption} /><span><small>{PDSCONSTANT.SUB_TEXT_IMPORT_TYPE_OPTION}</small></span></FormField></Grid.Cell>
        <Grid.Cell><FormField id="SecondaryMarket" label="MARKET2" ><TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' disabled={configurationRequest.AligneImportType === PDSCONSTANT.VOL_IMPORT_TYPE ? false : true} value={configurationRequest.SecondaryMarket} placeholder="" onChange={(e) => onTextBoxInputChange("SecondaryMarket", e.target.value)} /><span><small>{PDSCONSTANT.SUB_TEXT_IMPORT_TYPE_OPTION}</small></span></FormField></Grid.Cell>
        <Grid.Cell><FormField id="SecondaryComponent" label="COMPONENT2" ><TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' disabled={configurationRequest.AligneImportType === PDSCONSTANT.VOL_IMPORT_TYPE ? false : true} value={configurationRequest.SecondaryComponent} placeholder="" onChange={(e) => onTextBoxInputChange("SecondaryComponent", e.target.value)} /><span><small>{PDSCONSTANT.SUB_TEXT_IMPORT_TYPE_OPTION}</small></span></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Unit" label="UNIT" ><TextInput onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin='false' disabled={configurationRequest.AligneImportType === PDSCONSTANT.VOL_IMPORT_TYPE ? false : true} value={configurationRequest.Unit} placeholder="" onChange={(e) => onTextBoxInputChange("Unit", e.target.value)} /><span><small>{PDSCONSTANT.SUB_TEXT_IMPORT_TYPE_OPTION}</small></span></FormField></Grid.Cell>
        <Grid.Cell><FormField id="ExportSchedule" label="Export Schedule" state="error" message={exportScheduleErrorMessage} mandatory><Dropdown masterData={exportSchedule} id="ExportSchedule" onDropDownChange={onInputChange} selectedData={configurationRequest.ExportSchedule} /></FormField></Grid.Cell>
        <Grid.Cell><FormField id="Status" label="Status" mandatory><Styled.ButtonGroupStyled id="status" size="medium" value={statusValue} onChange={handleStatusChange}>
          <ButtonGroupButton name="Enable" />
          <ButtonGroupButton name="Disable" />
        </Styled.ButtonGroupStyled ></FormField></Grid.Cell>

      </Styled.SideDrawerGrid>
      <hr />
      {/*render footer section -show create/save button, based on permission */}
      {permissions.CanWrite_AligneMapping() && footer}
      <ToastContainer />
    </Styled.DrawerStyled></>
  );
}
