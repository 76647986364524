import { Filters } from '@sede-x/glass-design-library';
import { useEffect, useState } from 'react';
import { PDSCONSTANT } from '../../common/constants';
import { AligneExportFiltersName, ZeroCalEnum } from '../../common/enum';
import { ICurveConfigurationFilterProps } from '../../interfaces/ICurveConfigurationFilterProps';
import {IMasterData} from '../../interfaces/IMasterData';
import { UserPermission } from '../../services/UserPermission';
import { MasterData } from '../../services/MasterData';

//Using @sede-x/glass-design-library Filter component for filter component
export const  AligneMappingFilters =(props:ICurveConfigurationFilterProps) => {
  // Initialise the state variable
  const { GetBooleanValueFilters } = MasterData();
  const [market, setMarket] = useState<IMasterData[]>([]);
  const [component, setComponent] = useState<IMasterData[]>([]);
  const [pset, setPSet] = useState<IMasterData[]>([]);
  const [zerocal, setZeroCal] = useState<IMasterData[]>([]);
  const [addToMarket, setAddToMarket] = useState<IMasterData[]>([]);
  const [aligneimporttype, setAligneImportType] = useState<IMasterData[]>([]);
  const [contractoptions, setContractOptions] = useState<IMasterData[]>([]);
  const [secondarymarket, setSecondaryMarket] = useState<IMasterData[]>([]);
  const [secondarycomponent, setSecondaryComponent] = useState<IMasterData[]>([]);
  const [exportschedule, setExportSchedule] = useState<IMasterData[]>([]);
  const [unit, setUnit] = useState<IMasterData[]>([]);
  const dropdownPlaceHolder ='Please select';
  const dropdownType = 'MULTISELECTION';    

  // Api calls for Filter dropdowns
  useEffect(() => {   

          if(props.masterData)
          {          
          
            setMarket(props.masterData[PDSCONSTANT.ALIGNE_MARKET].map((str:string, _index:number) => ({ value: str, text: str})));
            setComponent(props.masterData[PDSCONSTANT.ALIGNE_COMPONENT].map((str:string, _index:number) => ({ value: str, text: str })));
            setPSet(props.masterData[PDSCONSTANT.ALIGNE_PSET].map((str:string, _index:number) => ({ value: str, text: str })));
            setZeroCal(GetBooleanValueFilters());
            setAddToMarket(GetBooleanValueFilters());
            setUnit(props.masterData[PDSCONSTANT.ALIGNE_UNIT].map((str:string, _index:number) => ({ value: str, text: str })));
            setAligneImportType(props.masterData[PDSCONSTANT.ALIGNE_ALIGNEIMPORTTYPE].map((str:string, _index:number) => ({ value: str, text: str })));
            setContractOptions(props.masterData[PDSCONSTANT.ALIGNE_CONTRACTOPTIONS].map((str:string, _index:number) => ({ value: str, text: str })));
            setSecondaryComponent(props.masterData[PDSCONSTANT.ALIGNE_SECONDARYCOMPONENT].map((str:string, _index:number) => ({ value: str, text: str })));
            setSecondaryMarket(props.masterData[PDSCONSTANT.ALIGNE_SECONDARYMARKET].map((str:string, _index:number) => ({ value: str, text: str})));
            setExportSchedule(props.masterData[PDSCONSTANT.ALIGNE_EXPORTSCHEDULE].map((str:string, _index:number) => ({ value: str, text: str }))); 
            
          }       
          
        }  
  ,[props.masterData]);    
  return (  
    <Filters collapsable 
    fields={
      [        
         {
            label: 'Name',
            name: 'name',
            placeholder: '',
            type: 'SEARCH'
          },
          {
            label: 'Market',
            name: 'market',
            options: market,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Component',
            name: 'component',
            options: component,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'PSET',
            name: 'pset',
            options: pset,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: '0CAL',
            name: 'zerocal',
            options: zerocal,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Add To Market',
            name: 'addToMarket',
            options: addToMarket,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Aligne Import Type',
            name: 'aligneimporttype',
            options: aligneimporttype,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Settle',
            name: 'Settle',
            placeholder: '',
            type: 'SEARCH'
          },
          {
            label: 'PUTCALL',
            name: 'contractoptions',
            options: contractoptions,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Market2',
            name: 'secondarymarket',
            options: secondarymarket,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Component2',
            name: 'secondarycomponent',
            options: secondarycomponent,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },
          {
            label: 'Unit',
            name: 'unit',
            options: unit,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          }, 
          {
            label: 'Export Schedule',
            name: 'exportschedule',
            options: exportschedule,
            placeholder: dropdownPlaceHolder,
            type: dropdownType
          },      

    ]}    
    onApply={function onApply(values:any){
         props.onApply(values);

    }} />   
  );
}
